import faqs from "./faq";
import screens from "./screens";
import alert from "./alert";
import error from "./error";
import buttons from "./buttons";
import placeholders from "./placeholders";
import box from "./box";
import form from "./form";
import input from "./input";
import item from "./item";
import list from "./list";
import modal from "./modal";
import view from "./view";
import footer from "./footer";
import landing from "./landing";
import snackbar from "./snackbar";
import graph from "./graph";
import file from "./v3-translate/file";
import folder from "./v3-translate/folder";
import note from "./v3-translate/note";
import dialog from "./v3-translate/dialog";
import tutorial from "./tutorial";

export const DEFAULT_LANG = "ko" as const;
import { mkTranslateExport } from "@redwit-react-commons/hooks/translate";

const translate = {
  ko: {
    ...faqs.ko,
    ...screens.ko,
    ...alert.ko,
    ...error.ko,
    ...buttons.ko,
    ...placeholders.ko,
    ...box.ko,
    ...form.ko,
    ...input.ko,
    ...item.ko,
    ...list.ko,
    ...modal.ko,
    ...view.ko,
    ...footer.ko,
    ...landing.ko,
    ...snackbar.ko,
    ...graph.ko,
    ...file.ko,
    ...folder.ko,
    ...note.ko,
    ...dialog.ko,
    ...tutorial.ko,
    service_side_bar_tooltip: {
      open: "클릭 시 메뉴가 열립니다.",
      close: "클릭 시 메뉴가 닫힙니다.",
    },
    unit: {
      person: `명`,
      extension: `건`,
      note: `건`,
    },
    won: "원",
    count: "개",
    help_center: "Goono 헬프센터",
  },
  en: {
    ...faqs.en,
    ...screens.en,
    ...alert.en,
    ...error.en,
    ...buttons.en,
    ...placeholders.en,
    ...box.en,
    ...form.en,
    ...input.en,
    ...item.en,
    ...list.en,
    ...modal.en,
    ...view.en,
    ...footer.en,
    ...landing.en,
    ...snackbar.en,
    ...graph.en,
    ...file.en,
    ...folder.en,
    ...note.en,
    ...dialog.en,
    ...tutorial.en,
    service_side_bar_tooltip: {
      open: "Click to open the menu.",
      close: "Click the menu closes.",
    },
    unit: {
      person: ``,
      extension: ``,
      note: ``,
    },
    won: "won",
    count: "",
    team_plan: "Team Plan",
    help_center: "Help center",
  },
};

export default mkTranslateExport(translate, "ko", "goono");
