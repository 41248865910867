import React from "react";
import { Box, Container, ButtonBase } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useLandingHomeStyles from "../styles";
import { ScreenURL } from "src/routes/route_list";
import { useHistory } from "react-router-dom";
import translate from "@translate";
import clsx from "clsx";
import MainVisual from "@assets/images/public/screen/landingHomeScreen/main-visual/main-img.png";
import MainVisualLazy from "@assets/images/public/screen/landingHomeScreen/main-visual/main-img-lazy.jpeg";
import MainBg from "@assets/images/public/screen/landingHomeScreen/main-visual/main-bg.png";
import { ReactComponent as LogoIcon } from "@assets/images/public/logo/logo-goono_sm.svg";
import EventCreator from "src/services/GoogleTagManger/EventCreator";
import PreloadImage from "src/components/public/images/PreloadImage";
import CustomSlideAction from "../Commons/CustomSlideAction";

type MainIntroduceSectionProps = {
  isMobile: boolean;
  goToFreeTrial: () => void;
};
export default function MainIntroduceSection({
  isMobile,
  goToFreeTrial,
}: MainIntroduceSectionProps) {
  const classes = useLandingHomeStyles();

  return (
    <Box
      component="section"
      className={clsx(classes.introduce_section, classes.visual_intro_section)}
    >
      <Container className={classes.container}>
        <Flex container className="main-content-wrap">
          <MainContentSection
            isMobile={isMobile}
            goToFreeTrial={goToFreeTrial}
          />
          <MainVisualSection />
        </Flex>
      </Container>
    </Box>
  );
}

function MainContentSection(props: {
  isMobile: boolean;
  goToFreeTrial: () => void;
}) {
  const history = useHistory();

  const renderTitleGroup = () => {
    return (
      <>
        <GoonoTypography component="h3" className="main-subtitle" keepAll>
          {translate.landing_home.introduce.sub_title}
        </GoonoTypography>
        <GoonoTypography className="main-title">
          <span> {translate.landing_home.introduce.title_01} </span>
          {translate.landing_home.introduce.title_02}
        </GoonoTypography>
      </>
    );
  };

  const renderDescriptoin = () => {
    return (
      <GoonoTypography keepAll className="main-description">
        {translate.landing_home.introduce.desc_01} <br />
        {translate.landing_home.introduce.desc_02}
      </GoonoTypography>
    );
  };

  const renderButtonGroup = () => {
    return (
      <Flex container className="main-button-group">
        <ButtonBase
          className={clsx(
            props.isMobile ? "line-button-mobile" : "line-button-pc"
          )}
          onClick={props.goToFreeTrial}
        >
          <LogoIcon className="button-logo-icon" />
          {translate.landing_home.introduce.trial_btn}
        </ButtonBase>
        <ButtonBase
          className={clsx(
            props.isMobile
              ? "solid-button-contact-mobile"
              : "solid-button-contact-pc"
          )}
          onClick={() => {
            EventCreator({
              event: "click_cta",
              button: props.isMobile ? "mobile_home_ask" : "home_ask",
            });
            history.push(ScreenURL.LANDING_CONTACT);
          }}
        >
          {translate.landing_home.introduce.contact_btn}
        </ButtonBase>
      </Flex>
    );
  };
  return (
    <Box className="left-content">
      {renderTitleGroup()}
      {renderDescriptoin()}
      {renderButtonGroup()}
      <CustomSlideAction />
    </Box>
  );
}

function MainVisualSection() {
  return (
    <Box className="right-content">
      <Box className="main-visual">
        <PreloadImage
          preloadsrc={MainVisualLazy}
          src={MainVisual}
          alt="구노 연구노트 보드 이미지"
          className="main-img"
        />
        <PreloadImage preloadsrc="" src={MainBg} alt="" className="main-bg" />
      </Box>
    </Box>
  );
}
