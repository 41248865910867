import React from "react";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import { RootState } from "src/redux/store/reducers";
import { ModalInfo, ModalType } from "src/redux/store/reducers/modal";

const DeleteModal = loadable(() => import("src/modals/public/DeleteModal"));

const DownloadModal = loadable(
  () => import("src/modals/research-note/DownloadModal")
);

const EditHashtagModal = loadable(
  () => import("src/modals/research-note/EditHashtagModal")
);

const GithubLinkageListModal = loadable(
  () => import("src/modals/research-note/GithubLinkageListModal")
);

const GithubLinkModal = loadable(
  () => import("src/modals/research-note/GithubLinkModal")
);

const MemberManageModal = loadable(
  () => import("src/modals/research-note/ManageProjectMemberModal")
);

const MoveFilesModal = loadable(
  () => import("src/modals/research-note/MoveFilesModal")
);

const PrivacyModal = loadable(() => import("src/modals/public/PrivacyModal"));

const ProjectInfoModal = loadable(
  () => import("src/modals/research-note/ProjectInfoModal")
);

const ProjectPreviewModal = loadable(
  () => import("src/modals/research-note/ProjectPreviewModal")
);

const QuickUploadFilesModal = loadable(
  () => import("src/modals/research-note/QuickUploadFilesModal")
);

const ReconfirmModal = loadable(
  () => import("src/modals/public/ReconfirmModal")
);

const SignModal = loadable(() => import("src/modals/public/SignModal"));

const TextInputModal = loadable(
  () => import("src/modals/public/TextInputModal")
);

const UpdateProjectAdminModal = loadable(
  () => import("src/modals/research-note/UpdateProjectAdminModal")
);

const ViewHashtagModal = loadable(
  () => import("src/modals/research-note/ViewHashtagModal")
);
const LinkageGuideModal = loadable(
  () => import("src/modals/research-note/LinkageGuideModal")
);

const BlockInfoModal = loadable(
  () => import("src/modals/research-note/BlockInfoModal")
);

const PasswordModifyModal = loadable(
  () => import("src/modals/public/PasswordModifyModal")
);

const ResearcherListModal = loadable(
  () => import("src/modals/research-note/ResearcherListModal")
);

const HelpModal = loadable(() => import("src/modals/public/HelpCenterModal"));

const UpdateOnboardingModal = loadable(
  () => import("src/modals/public/UpdateOnboardingModal")
);

const DocumentBoxMemberManageModal = loadable(
  () => import("src/modals/data-room/DocumentBoxMemberManageModal")
);

const CreateDocumentShareLinkModal = loadable(
  () => import("src/modals/data-room/CreateDocumentShareLinkModal")
);

const TransferDocumentBoxOwnerModal = loadable(
  () => import("src/modals/data-room/TransferDocumentBoxOwnerModal")
);
const NDATemplateSelectModal = loadable(
  () => import("src/modals/data-room/NDATemplateSelectModal")
);

const CreateDocumentShareLinkCompleteModal = loadable(
  () => import("src/modals/data-room/CreateDocumentShareLinkCompleteModal")
);

const MoveDocumentModal = loadable(
  () => import("src/modals/data-room/MoveDocumentModal")
);

const EditNDATemplateModal = loadable(
  () => import("src/modals/data-room/EditNDATemplateModal")
);

const CreateNDATemplateModal = loadable(
  () => import("src/modals/data-room/CreateNDATemplateModal")
);

const FreePlanFormModal = loadable(
  () => import("src/modals/public/FreePlanFormModal")
);

const DataRoomPriceModal = loadable(
  () => import("src/modals/data-room/DataRoomPriceModal")
);

const SelectDocumentModal = loadable(
  () => import("src/modals/data-room/SelectDocumentModal")
);

const DataRoomGuideModal = loadable(
  () => import("src/modals/data-room/DataRoomGuideModal")
);

const DownloadFailFileModal = loadable(
  () => import("src/modals/research-note/DownloadFailFileModal")
);

export default function GoonoModalManager() {
  const modalState = useSelector((state: RootState) => state.modal).state;

  const renderModalContent = (modalInfo: ModalInfo) => {
    switch (modalInfo.type) {
      case ModalType.CREATE_NDA_TEMPLATE:
        return <CreateNDATemplateModal {...modalInfo} />;

      case ModalType.TRANSFER_DOCUMENT_BOX_OWNER:
        return <TransferDocumentBoxOwnerModal {...modalInfo} />;

      case ModalType.PROJECT_INFO:
        return <ProjectInfoModal {...modalInfo} />;

      case ModalType.MEMBER_MANAGE:
        return <MemberManageModal {...modalInfo} />;

      case ModalType.DOWNLOAD_PDF:
        return <DownloadModal {...modalInfo} />;

      case ModalType.DELETE:
        return <DeleteModal {...modalInfo} />;

      case ModalType.TEXT_INPUT:
        return <TextInputModal {...modalInfo} />;

      case ModalType.USER_SIGN:
        return <SignModal {...modalInfo} />;

      case ModalType.EDIT_TAG:
        return <EditHashtagModal {...modalInfo} />;

      case ModalType.VIEW_TAG:
        return <ViewHashtagModal {...modalInfo} />;

      case ModalType.GITHUB_LINKAGE_LIST:
        return <GithubLinkageListModal {...modalInfo} />;

      case ModalType.GITHUB_LINK:
        return <GithubLinkModal {...modalInfo} />;

      case ModalType.PRIVACY:
        return <PrivacyModal />;

      case ModalType.MOVE_FILE:
        return <MoveFilesModal {...modalInfo} />;

      case ModalType.UPDATE_PROJECT_ADMIN:
        return <UpdateProjectAdminModal {...modalInfo} />;

      case ModalType.RECONFIRM:
        return <ReconfirmModal {...modalInfo} />;

      case ModalType.QUICK_UPLOAD_FILE:
        return <QuickUploadFilesModal {...modalInfo} />;

      case ModalType.PROJECT_PREVIEW:
        return <ProjectPreviewModal {...modalInfo} />;

      case ModalType.LINKAGE_GUIDE:
        return <LinkageGuideModal />;

      case ModalType.BLOCK_INFO:
        return <BlockInfoModal {...modalInfo} />;

      case ModalType.PASSWORD_MODIFY:
        return <PasswordModifyModal {...modalInfo} />;

      case ModalType.RESEARCHER_LIST:
        return <ResearcherListModal {...modalInfo} />;

      case ModalType.HELP_CENTER:
        return <HelpModal />;

      case ModalType.UPDATE_ONBOARDING:
        return <UpdateOnboardingModal />;

      case ModalType.DOCUMENT_BOX_MEMBER_MANAGE:
        return <DocumentBoxMemberManageModal {...modalInfo} />;

      case ModalType.CREATE_SHARE_LINK:
        return <CreateDocumentShareLinkModal {...modalInfo} />;

      case ModalType.NDA_TEMPLATE:
        return <NDATemplateSelectModal {...modalInfo} />;

      case ModalType.CREATE_SHARE_LINK_COMPLETE:
        return <CreateDocumentShareLinkCompleteModal {...modalInfo} />;

      case ModalType.MOVE_DOCUMENT:
        return <MoveDocumentModal {...modalInfo} />;

      case ModalType.EDIT_NDA_TEMPLATE:
        return <EditNDATemplateModal {...modalInfo} />;

      case ModalType.FREE_PLAN_FORM:
        return <FreePlanFormModal />;

      case ModalType.DATA_ROOM_PRICE:
        return <DataRoomPriceModal />;

      case ModalType.SELECT_DOCUMENT:
        return <SelectDocumentModal />;

      case ModalType.DATA_ROOM_GUIDE:
        return <DataRoomGuideModal />;
      case ModalType.DOWNLOAD_FAIL_FILE:
        return <DownloadFailFileModal {...modalInfo} />;
    }
  };

  return (
    <React.Fragment>
      {modalState.openedModalList.map((modalInfo) => (
        <React.Fragment key={modalInfo.type}>
          {renderModalContent(modalInfo)}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
