import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export type MainSlideController = {
  page: number;
  slideSettings: Settings;
  onNext: () => void;
  onPrev: () => void;
};

type Props = {
  slideRef: React.RefObject<Slider>;
};
export const useMainSlideController = (props: Props): MainSlideController => {
  const [currentVisual, setCurrentVisual] = React.useState<number>(0);

  const onNext = () => {
    props.slideRef.current?.slickNext();
  };
  const onPrev = () => {
    props.slideRef.current?.slickPrev();
  };

  const page = React.useMemo(() => currentVisual + 1, [currentVisual]);

  const slideSettings: Settings = React.useMemo(
    () => ({
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      speed: 500,
      beforeChange: (_oldIndex: number, newIndex: number) => {
        setCurrentVisual(newIndex);
      },
    }),
    []
  );
  return { page, slideSettings, onNext, onPrev };
};
