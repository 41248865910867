import React, { ElementType } from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./styles/GoonoTypography";
import clsx from "clsx";

export type typoType =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "b1_r"
  | "b1_m"
  | "b1_b"
  | "b2_r"
  | "b2_m"
  | "b2_b"
  | "b3_r"
  | "b3_m"
  | "b3_b"
  | "b4_r"
  | "b4_m"
  | "b4_b"
  | "ele1_m"
  | "ele1_b"
  | "ele2_m"
  | "ele2_b"
  | "ele3_m"
  | "ele3_b"
  | "caption1_r"
  | "caption1_m"
  | "caption1_b"
  | "caption2_r"
  | "caption2_m"
  | "caption2_b";
export type textColor =
  | "primary"
  | "secondary"
  | "text"
  | "cool_text"
  | "title"
  | "error"
  | "disabled"
  | "white"
  | "primary_light";

// textColor 타입
export const textColorValues: textColor[] = [
  "primary",
  "secondary",
  "text",
  "cool_text",
  "title",
  "error",
  "disabled",
  "white",
  "primary_light",
];

export type GoonoTypographyProps = {
  /** 타이포 타입 */
  type?: typoType;
  textcolor?: string;
  style?: React.CSSProperties;
  className?: string;
  dataTestid?: string;
  id?: string;
  /** overflow ellipsis : maxWidth 등의 고정값 필요 */
  noWrap?: boolean;
  /** 텍스트 여러줄 표시 + overflow ellipsis */
  handlelines?: number;
  /** word-break: keep-all 경우, 단어 단위로 줄 나뉨 */
  keepAll?: boolean;
  /** white-space: pre-line */
  preLine?: boolean;
  component?: ElementType<any>;
  children?: React.ReactNode;
};

/**
 * - 구노 서비스에 사용하는 Typograyph type
 * - zeplin & figma에 지정된 name을 type에 입력
 */

export default function GoonoTypography(props: GoonoTypographyProps) {
  const classes = useStyles();

  const {
    type,
    textcolor,
    style,
    className,
    dataTestid,
    id,
    noWrap,
    handlelines,
    keepAll,
    preLine,
    component,
    children,
  } = props;

  const getTypeClass = () => {
    switch (type) {
      case "h1":
        return [classes.h1];
      case "h2":
        return [classes.h2];
      case "h3":
        return [classes.h3];
      case "h4":
        return [classes.h4];
      case "h5":
        return [classes.h5];
      case "b1_r":
        return [classes.b1_r];
      case "b1_m":
        return [classes.b1_m];
      case "b1_b":
        return [classes.b1_b];
      case "b2_r":
        return [classes.b2_r];
      case "b2_m":
        return [classes.b2_m];
      case "b2_b":
        return [classes.b2_b];
      case "b3_r":
        return [classes.b3_r];
      case "b3_m":
        return [classes.b3_m];
      case "b3_b":
        return [classes.b3_b];
      case "b4_r":
        return [classes.b4_r];
      case "b4_m":
        return [classes.b4_m];
      case "b4_b":
        return [classes.b4_b];
      case "ele1_m":
        return [classes.ele1_m];
      case "ele1_b":
        return [classes.ele1_b];
      case "ele2_m":
        return [classes.ele2_m];
      case "ele2_b":
        return [classes.ele2_b];
      case "ele3_m":
        return [classes.ele3_m];
      case "ele3_b":
        return [classes.ele3_b];
      case "caption1_r":
        return [classes.caption1_r];
      case "caption1_m":
        return [classes.caption1_m];
      case "caption1_b":
        return [classes.caption1_b];
      case "caption2_r":
        return [classes.caption2_r];
      case "caption2_m":
        return [classes.caption2_m];
      case "caption2_b":
        return [classes.caption2_b];
      default:
        return [classes.b2_r];
    }
  };

  const getColorClass = () => {
    if (
      typeof textcolor === "string" &&
      !textColorValues.includes(textcolor as textColor)
    ) {
      return null; // string이면서 textColor 타입에 없는 경우 null 반환
    }

    switch (textcolor) {
      case "primary":
        return [classes.primary];

      case "secondary":
        return [classes.secondary];

      case "text":
        return [classes.text];

      case "title":
        return [classes.title];

      case "error":
        return [classes.error];

      case "disabled":
        return [classes.disabled];

      case "white":
        return [classes.white];

      case "primary_light":
        return [classes.primary_light];

      default:
        return;
    }
  };
  const handlerLinesStyle = () => {
    if (handlelines != undefined && handlelines && handlelines > 1)
      return {
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitLineClamp: handlelines,
        overflow: "hidden",
      };
    else return {};
  };

  const setHeadingComponent = () => {
    switch (type) {
      case "h1":
        return "h1";
      case "h2":
        return "h2";
      case "h3":
        return "h3";
      case "h4":
        return "h4";
      case "h5":
        return "h5";
      default:
        return "p";
    }
  };
  const headingTag = setHeadingComponent();
  const componentType = component != undefined ? component : headingTag;

  return (
    <Typography
      component={componentType}
      noWrap={noWrap}
      id={id}
      data-testid={dataTestid}
      style={{
        ...handlerLinesStyle(),
        whiteSpace:
          handlelines != undefined && handlelines && handlelines > 1
            ? "pre-wrap"
            : preLine !== undefined && preLine
            ? "pre-line"
            : undefined,
        WebkitBoxOrient:
          handlelines != undefined && handlelines && handlelines > 1
            ? "vertical"
            : undefined,
        wordBreak: keepAll != undefined && keepAll ? "keep-all" : "break-all",
        color: getColorClass() === null ? textcolor : undefined,

        ...style,
      }}
      className={clsx(classes.root, getTypeClass(), getColorClass(), className)}
    >
      {children}
    </Typography>
  );
}

GoonoTypography.defaultProps = {
  noWrap: false,
  handlelines: 1,
};
