export default {
  ko: {
    file: {
      file_log: {
        msg_1: "님이",
        msg_2: "에 연구파일을 추가하였습니다.",
        note: "연구노트",
        btn: "파일 확인하기",
        all_notes: "전체 연구노트 보기",
        joined_members: "소속된 워크스페이스 멤버",
        set_opened: "전체 펼쳐 보기",
        set_closed: "전체 접어 보기",
        select_all_member: "전체 사용자 선택",
      },
      overview: {
        title_1: "연구파일 추가 활동 요약",
        description_1: "* 연구파일 추가 현황의 그래프는 작성 날짜가 아닌 ",
        description_2: "연구파일의 시점 인증 날짜",
        description_3: "를 기준으로 그래프가 활성화됩니다.",
        graph_1: "연구파일 추가 현황",
        graph_3: "기간 내 추가 된 연구파일 개수",
        graph_4: "평균 연구파일 추가 개수",
      },
      delete: {
        title: "연구파일 삭제",
        body_title: "연구파일을 삭제할까요?",
        msg: (file: number) =>
          `삭제하시면 작성된 연구파일 ${file}개 복구가 불가능합니다.\n정말 삭제하시겠어요?`,
        msg_highlight: (file: number) => `연구파일 ${file}개 복구가 불가능`,
        confirm: "연구파일 삭제",
      },
    },
  },
  en: {
    file: {
      file_log: {
        msg_1: " uploaded to",
        msg_2: " research note.",
        note: "",
        btn: "View",
        all_notes: "All Notes",
        joined_members: "Members on Workspace",
        set_opened: "Open All",
        set_closed: "Close All",
        select_all_member: "Select All",
      },
      overview: {
        title_1: "Research File Addition Summary",
        description_1: "* These diagram is based on a ",
        description_2: "timestamp",
        description_3: " from blockchain netwwork.",
        graph_1: "New Files Uploaded",
        graph_3: "Number of New Research Files",
        graph_4: "Average Number of New Files per Week",
      },
      delete: {
        title: "Delete Research File",
        body_title: "Are you sure to delete research file?",
        msg: (file: number) =>
          `If you delete it, you can't restore ${file} created research files.\nAre you sure to delete?`,
        msg_highlight: (file: number) =>
          `can't restore ${file} created research files.`,
        confirm: "Delete",
      },
    },
  },
};
