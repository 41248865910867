import { Workspace } from "@goono-commons/v3/workspace";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  StateMachineAction,
  mkReducer,
  transition,
} from "@redwit-react-commons/reducers/state3";
import { Dispatch } from "react";

export enum WorkspaceMenu {
  RESEARCH_NOTE = "WorkspaceMenu::RESEARCH_NOTE",
  DATA_ROOM = "WorkspaceMenu::DATA_ROOM",
}

export const INITIAL_WORKSPACE_MENU = WorkspaceMenu.RESEARCH_NOTE;

export enum WorkspaceStateStatus {
  INIT = "WorkspaceStateStatus::INIT",
  SUCCESS = "WorkspaceStateStatus::SUCESS",
}

export enum WorkspaceActionKind {
  TRY_INIT_WORKSPACE = "WorkspaceActionKind::TRY_INIT_WORKSPACE",
  TRY_UPDATE_CURRENT_WORKSPACE = "WorkspaceActionKind::TRY_UPDATE_CURRENT_WORKSPACE",
  TRY_UPDATE_CURRENT_WORKSPACE_SERVICE = "WorkspaceActionKind::TRY_UPDATE_CURRENT_WORKSPACE_SERVICE",
}

type LastAcessedWorkspaceLog = {
  userId: string;
  workspaceId: string;
};

export type WorkspaceStateCore = {
  readonly last_acessed_workspace_logs: LastAcessedWorkspaceLog[];
  readonly currentMenu: WorkspaceMenu;
};

export type WorkspaceState =
  | ({
      readonly status: WorkspaceStateStatus.INIT;
    } & WorkspaceStateCore)
  | ({
      readonly status: WorkspaceStateStatus.SUCCESS;
      readonly currentWorkspace: Workspace;
    } & WorkspaceStateCore);

export type WorkspaceAction =
  | {
      readonly kind: WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE_SERVICE;
      readonly service: WorkspaceMenu;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE;
      readonly targetWorkspace: Workspace;
      readonly userId: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_INIT_WORKSPACE;
    };

export type WorkspaceError = never;

const smid = "WORKSPACE_STATE_MACINE3";

export type WorkspaceStateMachineType = StateMachine3<
  WorkspaceStateStatus,
  WorkspaceState,
  WorkspaceActionKind,
  WorkspaceAction,
  WorkspaceError
>;

export const workspaceStateMachine: WorkspaceStateMachineType =
  new StateMachine3<
    WorkspaceStateStatus,
    WorkspaceState,
    WorkspaceActionKind,
    WorkspaceAction,
    WorkspaceError
  >(
    smid,
    {
      status: WorkspaceStateStatus.INIT,
      last_acessed_workspace_logs: [],
      currentMenu: INITIAL_WORKSPACE_MENU,
    },
    [
      transition(
        WorkspaceStateStatus.SUCCESS,
        WorkspaceStateStatus.SUCCESS,
        WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE_SERVICE
      ),
      transition(
        WorkspaceStateStatus.INIT,
        WorkspaceStateStatus.SUCCESS,
        WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE
      ),
      transition(
        WorkspaceStateStatus.SUCCESS,
        WorkspaceStateStatus.SUCCESS,
        WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE
      ),
      transition(
        WorkspaceStateStatus.INIT,
        WorkspaceStateStatus.INIT,
        WorkspaceActionKind.TRY_INIT_WORKSPACE
      ),
      transition(
        WorkspaceStateStatus.SUCCESS,
        WorkspaceStateStatus.INIT,
        WorkspaceActionKind.TRY_INIT_WORKSPACE
      ),
    ]
  );

export type DispatchFileAction = Dispatch<
  StateMachineAction<
    WorkspaceStateStatus,
    WorkspaceState,
    WorkspaceActionKind,
    WorkspaceAction,
    WorkspaceError
  >
>;

export default mkReducer<
  WorkspaceStateStatus,
  WorkspaceState,
  WorkspaceActionKind,
  WorkspaceAction,
  WorkspaceError
>(workspaceStateMachine);

export const doWorkspaceAction = (
  dispatch: DispatchFileAction,
  nextAction: WorkspaceAction,
  onResolve: () => void = () => {},
  onReject: (err: WorkspaceError | InternalError) => void = () => {}
) => {
  dispatch(workspaceStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doWorkspaceActionAsync = (
  dispatch: DispatchFileAction,
  nextAction: WorkspaceAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(workspaceStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetWorkspace = (dispatch: DispatchFileAction) => {
  dispatch(workspaceStateMachine.newResetAction());
};
