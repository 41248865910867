import React from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/reducers";
import { TokenStateStatus } from "../../redux/store/reducers/token";
import GoonoBaseGrid from "@utils/templates/GoonoBaseGrid";
import {
  WorkspaceStateStatus,
  WorkspaceMenu,
} from "src/redux/store/reducers/workspace";
import { ScreenURL } from "../route_list";

export const LandingRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const tokenState = useSelector((state: RootState) => state.token).state;
  const history = useHistory();
  const workspaceState = useSelector(
    (state: RootState) => state.workspace
  ).state;

  React.useEffect(() => {
    if (tokenState.status === TokenStateStatus.SUCCESS) {
      if (workspaceState.status === WorkspaceStateStatus.SUCCESS) {
        switch (workspaceState.currentMenu) {
          case WorkspaceMenu.RESEARCH_NOTE:
            return history.push(ScreenURL.RESEARCH_NOTE_DASHBOARD);
          case WorkspaceMenu.DATA_ROOM:
            return history.push(ScreenURL.DATAROOM_DASHBOARD);
        }
      } else history.push(ScreenURL.RESEARCH_NOTE_DASHBOARD);
    }
  }, []);

  return (
    <React.Suspense
      fallback={<GoonoBaseGrid viewerOption={{ type: "landing_page" }} />}
    >
      <Route {...rest} />
    </React.Suspense>
  );
};
