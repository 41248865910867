export default {
  ko: {
    select: {
      invite: {
        read_only: "읽기권한",
        write_and_add: "작성권한",
      },
    },
  },
  en: {
    select: {
      invite: {
        read_only: "Read only",
        write_and_add: "Write only",
      },
    },
  },
};
