export default {
  ko: {
    error: {
      is_not_email_account: "이메일로 가입된 계정이 아닙니다.",
      unregistered_email: "가입되지 않은 계정 입니다",
      diffrent_platform_account: "다른 플랫폼으로 가입된 계정입니다.",
      err: "문제가 발생하였습니다.",
      failed_signin: "계정정보가 일치하지 않습니다.",
      failed_pw: "비밀번호가 틀렸습니다.",
      one_more_letter: "한글자 이상 작성해주세요.",
      term_err: "약관을 동의해야합니다.",
      name_err: "이름을 입력하세요.",
      invalid_text: "유효하지 않은 형식입니다.",
      invalid_email: "이메일 형식을 올바르게 입력해주세요.",
      valid_emoji: "이모지를 사용할 수 없습니다.",
      valid_special_char: "특수기호는 ~_-/ 만 사용하실 수 있습니다.",
      min_text_lenght: (min_length: number) =>
        `최소 ${min_length}자 이상 입력해 주세요.`,
      maximun_text_length: (max_length: number) =>
        `${max_length}자 이상 입력하실 수 없습니다.`,
      valide_pw_length: "8자 이상 입력해주세요.",
      not_valid_pw: "영문,숫자,특수문자의 조합 8자리 이상만 가능합니다.",
      not_valid_login_pw: "영문,숫자의 조합 8자리 이상만 가능합니다.",
      not_verfied_email: "이메일 인증을 진행해주세요.",
      email_err: "이메일을 입력해주세요.",
      invite_code_err: "코드를 입력해주세요.",
      invalid_invite_code: "유효하지 않은 초대코드입니다.",
      fail_join_invite_code: "공유에 실패하였습니다. 초대코드를 확인해주세요.",
      empty_input: "값을 입력해주세요.",
      fail_update_porfile: "에러가 발생하여 저장되지 않습니다.",
      fail_get_payment_info: "결제정보를 가져오는 데 실패하였습니다.",
      folder_auth_change_fail: "폴더 공유 권한 수정에 실패했습니다.",
      pin_folder_err: "즐겨찾기는 최대 8개까지만 가능합니다.",
      edit_folder_name_fail: "폴더 이름 변경에 실패하였습니다.",
      delete_folder_fail: "폴더 삭제에 실패하였습니다.",
      create_folder_fail: "폴더 생성에 실패하였습니다.",
      delete_file_failed: "파일 삭제에 실패했습니다.",
      download_note_fail: "노트 다운로드에 실패하였습니다.",
      registered_email: "이미 가입된 이메일입니다.",
      register_fail: "가입 시도를 실패하였습니다.",
      already_registered_platform: "다른 플랫폼으로 이미 가입된 계정입니다.",
      check_auth_email:
        "이메일 인증을 하지 않으셨습니다. 메일함을 확인해주세요. (이메일이 보이지 않으시다면 스팸함을 확인해주세요)",
      cancel_join_project:
        "폴더 공유 받은 이메일 계정으로 로그인 하지 않아서 폴더 공유받기를 취소합니다. 다시 로그인 해주세요.",
      check_pw_err: "비밀번호가 일치하지 않습니다.",
      pw_token_err:
        "토큰 유효 기간이 만료했습니다. 인증 메일을 다시 신청하세요.",
      change_pw_fail: "비밀번호 변경에 실패했습니다",
      login_fail: "로그인에 실패하였습니다. 새로고침 후 다시 이용해주세요.",
      email_check_err: "이메일 중복확인이 필요합니다.",
      edit_hash_tag_err: "태그는 30개까지 입력 가능합니다.",
      google_secret_tab:
        "시크릿 모드 혹은 쿠키 차단 시 구글 로그인은 불가능합니다. (쿠키를 허용해주세요)",
      invalid_number: "숫자만 입력해주세요.",
      expired_token: "유효하지 않은 링크입니다.",
      invalid_research_name_length: "100자 이내로 작성해주세요.",
      invalid_20: "20자 이내로 작성해주세요.",
      invalid_input: "형식에 맞게 작성해주세요.",
      invalid_format: "유효하지 않은 형식입니다.",
      move_file_error: "파일 이동에 실패하였습니다.",
      no_auth: "권한이 없습니다.",
      dont_use_special_search_keyword: `검색어에 특수문자는 사용할 수 없습니다.`,
      session_expired_title: "보안을 위해 로그아웃 되었습니다.",
      session_expired:
        "장시간 접속으로 취약해지는 보안 강화를 위해 로그인 한 시간후 자동 로그아웃됩니다.",
      exceeded_text: `글자 수를 초과하였습니다.`,
    },
  },
  en: {
    error: {
      is_not_email_account: "This account is not registered by email.",
      unregistered_email: "This is an unregistered account.",
      diffrent_platform_account:
        "This is an account signed up for another platform.",
      err: "A problem has occurred.",
      failed_signin: "Account information does not match.",
      failed_pw: "Wrong password",
      one_more_letter: "Please write at least one letter.",
      term_err: "Please accept the terms!",
      name_err: "Please enter your name.",
      invalid_text: "Invalid format.",
      invalid_email: "Please enter the correct email format.",
      valid_emoji: "You cannot user emoji.",
      valid_special_char: "Special character is only allowed ~_-/.",
      min_text_lenght: (min_length: number) =>
        `Please enter at least ${min_length} character`,
      maximun_text_length: (max_length: number) =>
        `You cannot write more than ${max_length} characters.`,
      valide_pw_length: "Please write it with 8 or more characters.",
      not_valid_pw:
        "Please write at least 8 digits of the combination of English, numbers, and special characters.",
      not_valid_login_pw:
        "Please write at least 8 digits of the combination of English and numbers.",
      not_verfied_email: "Please verify your email account.",
      email_err: "Please enter your e-mail.",
      invite_code_err: "Please enter the code.",
      invalid_invite_code: "The invitation code is invalid.",
      fail_join_invite_code: "Fail to join. Please check your code.",
      empty_input: "Please enter the value.",
      fail_update_porfile: "Error occurred and will not be saved.",
      fail_get_payment_info: "Failed to get payment information",
      folder_auth_change_fail: "Project share permission modification failed.",
      pin_folder_err: "Folder can be pinned maximun 8.",
      edit_folder_name_fail: "Failed to change",
      delete_folder_fail: "Failed to delete",
      create_folder_fail: "Failed to create",
      download_note_fail: "Failed to download",
      registered_email: "This account is already registered.",
      register_fail: "Failed to registeration",
      delete_file_failed: "Failed to delete files",
      check_auth_email:
        "You have not authenticated your email. Please check your mail box. (also spam mail-box)",
      already_registered_platform:
        "This account is already registered to another platform.",
      cancel_join_project: "Please retry.",
      check_pw_err: "Passwords do not match.",
      pw_token_err:
        "Expiration date has expired. Please re-apply for the authentication mail.",
      change_pw_fail: "Fail to change the password",
      login_fail: "Fail to sign in. please retry",
      email_check_err: "Email duplicate verification is required.",
      edit_hash_tag_err: "You can enter up to 30 tags.",
      google_secret_tab:
        "You cannot log in with Google in Secret tab mode or disable Cookies.",
      invalid_number: "Not a number",
      expired_token: "Expiration date has expired.",
      invalid_research_name_length:
        "Please fill it out in less than 100 characters.",
      invalid_20: "Please fill it out in less than 20 characters.",
      invalid_input: "Please fill it out according to the format.",
      invalid_format: "Invalid Format",
      move_file_error: "Failed to move files",
      no_auth: "You don't have enough authentication.",
      dont_use_special_search_keyword: `Special characters cannot be used in search terms.`,
      session_expired_title: "You have been logged out for security.",
      session_expired:
        "You will be automatically logged out after an hour of login to increase security that is vulnerable to long connections.",
      exceeded_text: "The number of characters has been exceeded.",
    },
  },
};
