import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { WorkspaceMenu } from "src/redux/store/reducers/workspace";

export const useCurrentWorkspaceMenu = (): WorkspaceMenu => {
  const workspaceState = useSelector(
    (state: RootState) => state.workspace
  ).state;

  return workspaceState.currentMenu;
};
