import { IpfsData } from "@goono-commons/api/object/ipfs";
import { IPFSQueryKey } from "@react-query/key/ipfs";
import { getIPFSUrlData, IpfsUrlData } from "@react-query/utils/getIPFSUrlData";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { TokenStateStatus } from "src/redux/store/reducers/token";

export const useGetIpfsUrlDataQuery = (ipfs_src: IpfsData | undefined) => {
  /** useLoginUserInfo 지원 x */
  const tokenState = useSelector((state: RootState) => state.token).state;

  const token =
    tokenState.status === TokenStateStatus.SUCCESS ? tokenState.token : ``;

  return useQuery<IpfsUrlData>(
    [IPFSQueryKey.getIpfsUrlData, ipfs_src?.cid ?? `none`],
    async () => {
      return await getIPFSUrlData(ipfs_src, token);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};
