import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  SidebarAction,
  SidebarActionKind,
  SidebarState,
  sidebarStateMachine,
  SidebarStateMachineType,
} from "../store/reducers/sidebar";

const mapStateToProps = (state: RootState) => {
  return { reduxState: state.sidebar };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SidebarContainerProps = PropsFromRedux & {
  stateMachine: SidebarStateMachineType;
};

export const handleSidebarAction = (
  prevState: SidebarState,
  action: SidebarAction
): SidebarState => {
  switch (action.kind) {
    case SidebarActionKind.UPDATE_DATAROOM_SIDEBAR_STATE: {
      return {
        ...prevState,
        dataRoomSidebar: {
          ...prevState.dataRoomSidebar,
          ...action.newState,
        },
      };
    }
  }
};

class SidebarContainer extends ReduxStateComponent3<SidebarContainerProps> {
  static defaultProps = { stateMachine: sidebarStateMachine };

  constructor(props: SidebarContainerProps) {
    super(props);
  }

  protected onAction = handleSidebarAction;
}

export default connector(SidebarContainer);
