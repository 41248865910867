import {
  heicExtensionList,
  hwpExtensionList,
  imageExtensionList,
  officeExtensionList,
  pdfExtensionList,
} from "@goono-commons/api/request/note";
import { validateIpfsFile } from "@goono-commons/api/object/ipfs";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { mkAuthHeader, mkGetURL } from "@redwit-commons/utils/request";
import { ENV } from "./api";
import { getLoggers } from "@redwit-commons/utils/log";
import { delay } from "@redwit-commons/utils/function";

const { INFO, DEBUG } = getLoggers("ipfs-service");

const MIN_IPFS_MIRROR_UPDATE = 60 * 1000; // 60 seconds
let last_ipfs_mirror_updated = 0;
let current_ipfs_mirror = "ipfs.redwit.io";

/**
 * 파일을 서버에 업로드한다. 서버가 암호화를 수행한다.
 *
 * @param token
 * @param file
 * @param extension
 * @param cipher
 * @returns
 */
export const uploadFileIPFSWithEncryption = async (
  token: string,
  file: File,
  extension: string,
  cipher?: string
) => {
  const data = new FormData();
  data.append("file", file);
  const allowList = [
    ...imageExtensionList,
    ...heicExtensionList,
    ...officeExtensionList,
    ...pdfExtensionList,
    ...hwpExtensionList,
  ];
  if (!allowList.includes(extension)) {
    throw mkErr({
      kind: InternalErrorKind.Abort,
      loc: "uploadFileIPFSWithEncryption",
      msg: "invalid file type",
    });
  }
  data.append("use_enc", "true");
  if (cipher) {
    data.append("enc_uuid", cipher);
  }
  const response = await fetch(mkGetURL(ENV.IPFS_API_SERVER, `ipfs/upload`), {
    headers: { ...mkAuthHeader(token) },
    method: "POST",
    body: data,
  });
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "uploadFileIPFSWithEncryption",
      code: response.status,
      text: await response.text(),
    });
  }
  const res: {
    cid: string;
    CipherId: string;
    extension: string;
  } = await response.json();

  return res;
};

export const getIPFSUrl = (cid: string, extension?: string) => {
  const extension_str = extension === undefined ? "" : `.${extension}`;
  return `https://${current_ipfs_mirror}/ipfs/${cid}${extension_str}`;
};

/**
 * CID 가 암호화 된 내용일 경우 서버에 Cipher ID 를 제시하면 decrypt 해서 리턴해준다.
 * 단, 서버의 공개키로 암호화 된 경우 한정.
 *
 * @param token
 * @param cid
 * @param extension
 * @param CipherId
 * @returns
 */
export const getIPFSUrlDecrypted = (
  token: string,
  cid: string,
  extension?: string,
  CipherId?: string
) => {
  const extension_str = extension ? `.${extension}` : "";
  if (CipherId !== undefined) {
    return {
      uri: mkGetURL(ENV.IPFS_API_SERVER, `ipfs/decrypt/${cid}`, {
        enc_uuid: CipherId,
      }),
      init: {
        headers: {
          ...mkAuthHeader(token),
        },
        method: "GET",
      },
    };
  } else {
    return {
      uri: `https://${current_ipfs_mirror}/ipfs/${cid}${extension_str}`,
      init: {
        method: "GET",
      },
    };
  }
};

export const getIPFSMirror = () => {
  return current_ipfs_mirror;
};

const ipfs_site_list = ["ipfs.redwit.io"];

export const selectFastestIPFSServer = async () => {
  const now = Date.now();
  if (now - last_ipfs_mirror_updated < MIN_IPFS_MIRROR_UPDATE) {
    DEBUG("IPFS mirror has been recently updated, skip updating.");
    return;
  }
  last_ipfs_mirror_updated = now;
  const resolved: Array<{
    addr: string;
    latency: number;
  }> = [];
  const tasks = ipfs_site_list
    .map(async (addr) => {
      try {
        const start = Date.now();
        const ret = await fetch(
          `https://${addr}/ipfs/QmPw2MM54ErHmBSRT7AhTzuijRb7FA1BonfkJPbJitBVmQ`,
          { cache: "no-store" }
        );
        if (!ret.ok) {
          throw mkErr({
            kind: InternalErrorKind.Network,
            loc: "selectFastestIPFSServer",
            msg: `Connection error to ${addr}`,
          });
        }
        const txt = await ret.text();
        const end = Date.now();
        const latency = end - start;
        DEBUG(`Latency for ${addr}: ${latency} ms ${txt.length}`);
        resolved.push({
          addr,
          latency,
        });
      } catch (err) {
        DEBUG(`ERROR while connecting to ${addr}`, err);
      }
    })
    .map(async (task) => {
      // 최대 2초 내에 리턴한 서버만 사용
      await Promise.any([task, delay(2000)]);
    });

  await Promise.all(tasks);
  if (resolved.length > 0) {
    let best = undefined; // 가장 시간 좋은 것
    for (const item of resolved) {
      if (item.addr === "ipfs.redwit.io") {
        // 만약 ipfs.redwit.io 가 있다면 다른 서버보다 여기를 우선시해서 사용
        best = item;
        break;
      }
      if (best === undefined || best.latency > item.latency) {
        best = item;
        // continue;
      }
    }
    if (best !== undefined) {
      INFO(`Changing IPFS mirror from ${current_ipfs_mirror} to ${best.addr}`);
      current_ipfs_mirror = best.addr;
    }
  }
};

export const resetIPFSServer = () => {
  last_ipfs_mirror_updated = 0;
};

/**
 * 특정 task 에서 생성된 가장 최신 파일을 리턴한다.
 * @param token
 * @param taskId
 * @returns cid
 */
export const getIPFSTaskFile = async (token: string, taskId: string) => {
  const ret = await fetch(mkGetURL(ENV.API_SERVER, `task/${taskId}/file`, {}), {
    headers: { ...mkAuthHeader(token) },
    method: "GET",
  });
  return validateIpfsFile(await ret.json());
};

export const getFileSizeIPFS = async (token: string, cid: string) => {
  const ret = await fetch(mkGetURL(ENV.API_SERVER, `ipfs/size/${cid}`, {}), {
    headers: { ...mkAuthHeader(token) },
    method: "GET",
  });
  if (!ret.ok) return undefined;
  return await ret.text();
};

export default {
  uploadFileIPFSWithEncryption,
  getIPFSTaskFile,
  getIPFSUrl,
  getIPFSUrlDecrypted,
  getFileSizeIPFS,
};
