import { omit } from "lodash";
import React, { ImgHTMLAttributes } from "react";

type Props = { preloadsrc: string } & ImgHTMLAttributes<any>;

/**
 * 이미지가 로드되기 전까지 preloadsrc로 대체해서 보여줌
 * preloadsrc에는 5kb 이하 용량의 이미지를 넣어줄 것 ( 3G 환경 대응 가능한 사이즈 )
 */
export default function PreloadImage(props: Props) {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);

  React.useEffect(() => {
    const img = new Image();
    img.src = props.src as string;
    img.onload = () => setIsImageLoaded(true);
  }, []);

  if (isImageLoaded === false)
    return <img {...omit(props, "src")} src={props.preloadsrc} />;
  else return <img {...props} />;
}
