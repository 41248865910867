import { mapUndefined } from "@redwit-commons/utils/function";
import T from "@redwit-commons/utils/typecheck";

export type SimpleQuery = {
  // [key: string]: string | number | boolean | undefined;
  limit?: number;
  offset?: number;
  order?: "ASC" | "DESC";
  orderBy?: string;
};

export const simpleQuerySchema = T.object()
  .addField("limit", T.integer(), false)
  .addField("offset", T.integer(), false)
  .addField("order", T.string().withEnum(["ASC", "DESC"]), false)
  .addField("orderBy", T.string(), false);

/**
 * Search 수행 시 beforeAt 등의 시간이 같은 경우에 최종적으로 비교를 한번 더 수행
 */
export type SearchPK = {
  id: string;
  createdAt: string;
  updatedAt: string;
};

type SearchPKWithNumID = Omit<SearchPK, "id"> & { id: string | number };

export const extractSearchPK = (objectWithPK: SearchPKWithNumID) => {
  return JSON.stringify({
    id:
      typeof objectWithPK.id === "number"
        ? objectWithPK.id.toString()
        : objectWithPK.id,
    createdAt: objectWithPK.createdAt,
    updatedAt: objectWithPK.updatedAt,
  });
};

export const extractSearchPKArray = (
  objectWithPKArray: SearchPKWithNumID[]
) => {
  if (objectWithPKArray.length < 1) {
    return undefined;
  }
  return extractSearchPK(objectWithPKArray[objectWithPKArray.length - 1]);
};

export const searchPKSchema = T.object()
  .addField("id", T.string())
  .addField("createdAt", T.string())
  .addField("updatedAt", T.string());

export interface SearchParams {
  beforeAt?: string;
  afterAt?: string;
  /**
   * 시간은 같을 수 있으므로 최종적으로 이전 쿼리의 PK 를 이용해서 겹침 방지.
   * Get params 로는 Object 전달이 어려워서 json string 만을 전달
   */
  lastPK?: string;
  fetchSize?: number;
  pagingSize?: number;
  reverse?: boolean;
}

export interface ParsedSearchParams {
  beforeAt?: string;
  afterAt?: string;
  /// 시간은 같을 수 있으므로 최종적으로 이전 쿼리의 PK 를 이용해서 겹침 방지
  lastPK?: SearchPK;
  fetchSize?: number;
  pagingSize?: number;
  reverse?: boolean;
}

export const addSearchParams = (from: T) => {
  return from
    .addField("beforeAt", T.string(), false)
    .addField("afterAt", T.string(), false)
    .addField("lastPK", T.string(), false)
    .addField("fetchSize", T.integer(), false)
    .addField("pagingSize", T.integer(), false)
    .addField("reverse", T.boolean(), false);
};

export const searchParamsSchema = addSearchParams(T.object());

const rawPKExtractor = T.mkObjectExtractor<SearchPK>(searchPKSchema);
export const extractSearchParams = (pre: SearchParams): ParsedSearchParams => {
  const lastPK = mapUndefined(pre.lastPK, (val) => {
    const data = JSON.parse(val);
    return rawPKExtractor(data);
  });
  return {
    ...pre,
    lastPK,
  };
};
