import T from "@redwit-commons/utils/typecheck";
import { IpfsData, IpfsDataSchema } from "../ipfs";

/**
 * PDF Task 의 세부 stage
 */
export enum PDF_TASK_STAGE {
  INIT = "BUILD_PDF_STAGE::INIT",
  LOAD_BROWSER = "BUILD_PDF_STAGE::LOAD_BROWSER",
  CREATE_COVER_PAGE = "BUILD_PDF_STAGE::CREATE_COVER_PAGE",
  CONVERT_PDF_TO_IMAGES = "BUILD_PDF_STAGE::CONVERT_PDF_TO_IMAGES",
  CREATE_CONTENT_PAGE = "BUILD_PDF_STAGE::CREATE_CONTENT_PAGE",
  CREATE_CERTIFICATION_PAGE = "BUILD_PDF_STAGE::CREATE_CERTIFICATION_PAGE",
  COMBINE_PDF = "BUILD_PDF_STAGE::COMBINE_PDF",
  ENCRYPT_PDF = "BUILD_PDF_STAGE::ENCRYPT_PDF",
}

/** 전체를 100분률로 나눴을 때 각 Stage 가 얼마나 차지하는지를 나타냄. */
export const PDF_TASK_STAGE_INFO: Array<[PDF_TASK_STAGE, number]> = [
  [PDF_TASK_STAGE.INIT, 5],
  [PDF_TASK_STAGE.LOAD_BROWSER, 5],
  [PDF_TASK_STAGE.CREATE_COVER_PAGE, 5],
  [PDF_TASK_STAGE.CONVERT_PDF_TO_IMAGES, 15],
  [PDF_TASK_STAGE.CREATE_CONTENT_PAGE, 55],
  [PDF_TASK_STAGE.CREATE_CERTIFICATION_PAGE, 5],
  [PDF_TASK_STAGE.COMBINE_PDF, 5],
  [PDF_TASK_STAGE.ENCRYPT_PDF, 5],
];

/**
 * PDFTask 의 이름
 */
export const PDFTaskKind = "CreatePDF";

export type PDFBuildLanguage = "ko" | "en";

export type PDFBuildOptions = {
  language: PDFBuildLanguage;
  horizontal: boolean;
  has_cover: boolean;
  has_certification: boolean;
  has_comments: boolean;
  order?: {
    sort_by: "createdAt" | "writtenAt";
    sort_type: "ASC" | "DESC";
  };
  /**
   * PDF encryption 을 해제하는 기능.
   */
  disable_enc?: boolean;
};

/**
 * PDFTask 의 input 에 들어갈 정보
 */
export type PDFTaskInput = {
  projectId: string;
  noteIds: string[];
  folderIds: string[];
  pdfName: string;
  projectName?: string;
  UserId: string;
  pdfOptions?: PDFBuildOptions;
  /**@deprecated */
  licenseId?: string;
  workspaceId?: string;
};

export const PDFBuildOptionsSchema = T.object()
  .addField("horizontal", T.boolean())
  .addField("has_cover", T.boolean())
  .addField("has_certification", T.boolean())
  .addField("has_comments", T.boolean())
  .addField("disable_enc", T.boolean(), false)
  .addField(
    "order",
    T.object()
      .addField("sort_by", T.string().withEnum(["createdAt", "writtenAt"]))
      .addField("sort_type", T.string().withEnum(["ASC", "DESC"])),
    false
  );

const PDFTaskInputSchema = T.object()
  .addField("projectId", T.string())
  .addField("noteIds", T.array(T.string()))
  .addField("folderIds", T.array(T.string()))
  .addField("pdfName", T.string())
  .addField("projectName", T.string(), false)
  .addField("UserId", T.string())
  .addField("licenseId", T.string(), false)
  .addField("workspaceId", T.string(), false)
  .addField("pdfOptions", PDFBuildOptionsSchema.clone(), false);

export const validatePDFTaskInput = T.mkValidator<PDFTaskInput>(
  PDFTaskInputSchema.clone()
);

export type FailedNoteInfo = {
  fileName: string;
  error?: string;
};
/**
 * PDFTask 의 output 에 들어갈 정보
 */
export type PDFTaskOutput = IpfsData & {
  debug?: {
    success: number;
    failed: number;
    total: number;
    failedList: string[];
    failReason?: Array<FailedNoteInfo>;
  };
};
export const FailedNoteInfoSchema = T.object()
  .addField("fileName", T.string())
  .addField("error", T.string(), false);

export const PDFTaskOutputSchema = IpfsDataSchema.clone()
  .addField(
    "debug",
    T.object()
      .addField("success", T.number())
      .addField("failed", T.number())
      .addField("total", T.number())
      .addField("failedList", T.array(T.string())),
    false
  )
  .addField("failReason", T.array(FailedNoteInfoSchema.clone()), false);
export const validatePDFTaskOutput =
  T.mkValidator<PDFTaskOutput>(PDFTaskOutputSchema);

export class PDFTaskError extends Error {
  failedList: string[];
  constructor(message: string, failedList: string[] = []) {
    super(message);
    this.name = "PDFTaskError";
    this.failedList = failedList;
  }
}
