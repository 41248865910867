import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

interface MetaPixelProps {
  pixelId: string;
  children: React.ReactNode;
}

export default function MetaPixel({ pixelId, children }: MetaPixelProps) {
  useEffect(() => {
    // meta pixel initialize
    ReactPixel.init(pixelId);
    // pageView event를 호출하여 페이지 전환 자동 추적
    ReactPixel.pageView();
  }, [pixelId]);

  return <>{children}</>;
}
