import { createStyles, makeStyles } from "@material-ui/core";
import { theme } from "@theme";

export const useActiveLoadingViewStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#ffffff80",
      height: window.innerHeight,
      position: "absolute",
      zIndex: 999,
    },

    container: {
      backgroundColor: theme.white,
      borderRadius: theme.round_4,
      padding: 32,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "2.7px 2.7px 13.7px 0.8px rgba(90, 90, 90, 0.07)",
    },

    loadingImage: {
      width: 200,
      height: 200,
      marginBottom: 20,
    },

    linearProgres: {
      width: "100%",
      color: theme.primary,
    },
  })
);
