export default {
  ko: {
    placeholder: {
      input_email: "이메일을 입력해주세요.",
      input_name: "이름을 입력해주세요.",
      input_school: "소속 기관을 입력해주세요.",
      input_department: "전공/분야 혹은 부서명을 입력해주세요.",
      input_pw: "비밀번호를 입력해주세요.",
      input_pw_again: "비밀번호를 한번 더 입력해주세요.",
      input_invite_code: "초대코드 입력",
      input_search: "검색어를 입력해주세요.",
      input_tag: "태그를 입력해주세요.",
      input_folder: "폴더 이름을 입력해주세요.",
      input_invite_email: "초대할 팀원의 이메일을 입력하세요.",
      input_sign: "점선 안쪽으로 서명 부탁드립니다.",
      input_contact: "연락처를 입력해주세요. (ex.01012345678)",
      input_organization: "기관명을 입력해주세요.",
      input_invite_email_short: "메일을 입력하세요.",
      input_user: "작성자명으로 찾기",
      input_filter_search: "OCR/Tag 검색",
      input_filter_search_mobile: "OCR 또는 태그를 입력해주세요.",
    },
  },
  en: {
    placeholder: {
      input_email: "Enter your email.",
      input_name: "Enter your name.",
      input_school: "Enter your Institution.",
      input_department: "Enter your Major/Field.",
      input_pw: "Enter your password.",
      input_pw_again: "Enter your password once again.",
      input_invite_code: "Enter invitation code.",
      input_search: "Search here",
      input_tag: "Please enter tags.",
      input_folder: "Enter the folder name.",
      input_invite_email: "Enter the email of the person you'd like to invite.",
      input_sign: "Please sign inside the dotted line.",
      input_contact: "Enter your contact number. (01051465924)",
      input_organization: "Enter the name of your organization.",
      input_invite_email_short: "Enter the Email.",
      input_user: "Find by Writer Name",
      input_filter_search: "Search by OCR, tag ",
      input_filter_search_mobile: "OCR 또는 태그를 입력해주세요.",
    },
  },
};
