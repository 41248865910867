import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import useLandingHomeStyles from "../styles";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import clsx from "clsx";
import { LandingHomeScreenContext } from "../";

export default function CustomSlideAction(props: { type: "gray" | "dark" }) {
  const classes = useLandingHomeStyles();
  const context = React.useContext(LandingHomeScreenContext);

  return (
    <Box
      className={clsx(
        classes.slide_custom_counter,
        props.type === "dark" && "dark-bg"
      )}
    >
      <Flex container className="counter-wrap">
        <ButtonBase onClick={context.mainSlideController.onPrev}>
          <ChevronLeftRoundedIcon />
        </ButtonBase>
        <p className="counter-text">
          {context.mainSlideController.page} <span className="divider">/</span>{" "}
          <span> 2</span>
        </p>
        <ButtonBase onClick={context.mainSlideController.onNext}>
          <ChevronRightRoundedIcon />
        </ButtonBase>
      </Flex>
    </Box>
  );
}

CustomSlideAction.defaultProps = { type: "gray" };
