import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "src/redux/store/reducers";
import { WorkspaceMenu } from "src/redux/store/reducers/workspace";
import { ScreenURL } from "src/routes/route_list";

export const useMoveServiceDashboard = (withReload?: boolean) => {
  const workspaceState = useSelector(
    (state: RootState) => state.workspace
  ).state;

  const history = useHistory();

  const move = (url: string) => {
    if (withReload) window.location.replace(url);
    else history.push(url);
  };

  return {
    moveServiceDashboard: () =>
      move(
        workspaceState.currentMenu === WorkspaceMenu.RESEARCH_NOTE
          ? ScreenURL.RESEARCH_NOTE_DASHBOARD
          : ScreenURL.DATAROOM_DASHBOARD
      ),
  };
};
