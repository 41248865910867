import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  DataRoomTutorialActionKind,
  DataRoomTutorialAction,
  DataRoomTutorialStateStatus,
  DataRoomTutorialStateMachine,
  DataRoomTutorialStateMachineType,
  DataRoomTutorialState,
} from "../store/reducers/dataRoomTutorial";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.dataRoomTutorial,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type DataRoomTutorialContainerProps = PropsFromRedux & {
  stateMachine: DataRoomTutorialStateMachineType;
};

export const handleDataRoomTutorialAction = (
  _prevState: DataRoomTutorialState,
  action: DataRoomTutorialAction
): DataRoomTutorialState => {
  switch (action.kind) {
    case DataRoomTutorialActionKind.START_TUTORIAL: {
      return {
        status: DataRoomTutorialStateStatus.ON,
        currentStep: 1,
      };
    }

    case DataRoomTutorialActionKind.FINISH_TUTORIAL: {
      return {
        status: DataRoomTutorialStateStatus.OFF,
      };
    }

    case DataRoomTutorialActionKind.UPDATE_TUTORIAL_STEP: {
      return {
        status: DataRoomTutorialStateStatus.ON,
        currentStep: action.stepNumber,
      };
    }
  }
};

class DataRoomTutorialContainer extends ReduxStateComponent3<DataRoomTutorialContainerProps> {
  static defaultProps = { stateMachine: DataRoomTutorialStateMachine };
  constructor(props: DataRoomTutorialContainerProps) {
    super(props);
  }

  protected onAction = handleDataRoomTutorialAction;
}

export default connector(DataRoomTutorialContainer);
