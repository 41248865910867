import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { getLoggers } from "@redwit-commons/utils/log";
import React from "react";

const { WARN } = getLoggers("hooks/event");

/**
 * Consume 할지 여부를 boolean 으로 리턴. true 면 consume 하고 뒤로 넘기지 않음.
 * 만약 void 를 리턴하면 false 리턴으로 간주.
 *
 * @param props 기본적으로 부른 곳의 props를 함께 전송한다
 * @param args props로도 부족한 추가 정보를 넘길 때 사용한다
 */
export type EventCallback = (
  args: unknown
) => boolean | PromiseLike<boolean> | void | PromiseLike<void>;

/**
 * 이벤트가 중복 발생 시 처리할 방침
 */
export enum OverflowPolicy {
  /**
   * Listener 가 충분히 처리하지 못한 경우 두 번째 이벤트 무시
   */
  IGNORE = "OverflowPolicy::IGNORE",
  /**
   * 두 번째 이벤트를 무시하지 않고 QUEUE 에 저장
   */
  QUEUE = "OverflowPolicy::QUEUE",
  /**
   * 가장 마지막 이벤트만 최신 이벤트로 교체 (onResize 등에서 요긴하게 사용)
   */
  LATEST = "OverflowPolicy::LATEST",
  /**
   * 기존 실행중 이벤트가 있어도 병렬로 실행
   */
  PARALLEL = "OverflowPolicy::PARALLEL",
}

export const EventGroupContext = React.createContext<string | undefined>(
  undefined
);

type Listener = {
  callback: EventCallback;
  id: string;
};

type ListenerCtx = {
  listeners: Array<Listener>;
  // `[unknown]` 으로 함으로서 undefined 가 arg로 들어온 경우에도 정상적으로
  // 인식한다.
  queued_events: Array<[unknown]>;
  in_process: number;
};

const listeners = new Map<string, ListenerCtx>();
const glob_listener = {
  listener: (_: string, _policy: OverflowPolicy, _args: unknown) => {
    return;
  },
};

/**
 * 전역 이벤트 리스너를 설정한다. 디버그 이외에 쓰일 이유가 없다.
 *
 * @param listener
 * @returns
 */
export const setGlobalListener = (
  listener: (kind: string, policy: OverflowPolicy, args: unknown) => void
) => {
  const prev = glob_listener.listener;
  glob_listener.listener = listener;
  return prev;
};

/**
 * Listener 를 등록하고 해제하는 함수 쌍을 생성한다.  함수형 컴포넌트에서는 쓸
 * 이유가 없다.  클래스 컴포넌트에서 componentDidMount, componentWillUnmount
 * 생명주기에 register/unregister 함수를 등록하기 위해서 사용한다.
 *
 * @param kind
 * @param callback
 * @returns `[register, unregister]` 등록/해제를 위한 함수 쌍.
 */
export const mkEventListener = (kind: string, callback: EventCallback) => {
  const id = uuid();
  const register = () => {
    const prevEntry = listeners.get(kind);
    if (!prevEntry) {
      const ctx = {
        listeners: [{ callback, id }],
        queued_events: [],
        in_process: 0,
      };
      listeners.set(kind, ctx);
    } else {
      prevEntry.listeners.push({ callback, id });
    }
  };
  const unregister = () => {
    // On unload, unregister listener
    const prevEntry = listeners.get(kind);
    if (!prevEntry) return;
    const removed = prevEntry.listeners.filter((x) => x.id !== id);
    if (removed.length === 0) {
      // 더이상 listener 없어서 삭제
      listeners.delete(kind);
    } else {
      prevEntry.listeners = removed;
    }
  };
  return [register, unregister];
};

/**
 * 이벤트 리스너를 사용하는 커스넘 훅.  함수형 컴포넌트의 생명주기동안 이벤트
 * 리스너를 작동시킨다.
 *
 * @param kind 듣기 대상인 이벤트 종류
 * @param onEvent 콜백 함수
 */
export const useEventListener = (kind: string, onEvent: EventCallback) => {
  const groupId = useContext(EventGroupContext);
  const eventName = useMemo(
    () => (groupId !== undefined ? `${kind}/${groupId}` : kind),
    [kind, groupId]
  );
  const saved = useRef(onEvent);
  useEffect(() => {
    saved.current = onEvent;
  }, [onEvent]);
  useEffect(() => {
    const callback: EventCallback = (args) => {
      return saved.current(args);
    };
    const [register, unregister] = mkEventListener(eventName, callback);
    // On load, register listener
    register();
    return unregister;
  }, [eventName]);
};

/**
 * Event emitter 타입
 */
export type EmitterType<T = unknown> = (arg: T) => void;

/**
 * 이벤트를 발생시킬 수 있는 emitter 를 생성한다.  함수형, 클래스형 컴포넌트
 * 모두에서 사용할 수 있다.  함수형에서는 useEventEmitter 를 사용하면 rule of
 * hook 검사가 더 쉬워진다.  (기능상으론 동일)
 *
 * @param kind 이벤트 종류
 * @param policy 중복해서 이벤트 발생 시 행동
 * @returns 이벤트를 생성할 수 있는 emitter 함수
 */
export function mkEventEmitter<T = unknown>(
  kind: string,
  policy: OverflowPolicy = OverflowPolicy.IGNORE
): EmitterType<T> {
  const emitter: EmitterType<T> = (args) => {
    glob_listener.listener(kind, policy, args);
    const prevEntry = listeners.get(kind);
    if (!prevEntry || prevEntry.listeners.length === 0) {
      // No listener has set
      return;
    }
    if (prevEntry.in_process > 0) {
      // Already running a job
      switch (policy) {
        case OverflowPolicy.LATEST: {
          // Remove latest and insert current.  If the first event already
          // processing (when queued_event is empty and in_process > 0), queue
          // this event.
          prevEntry.queued_events.pop();
          prevEntry.queued_events.push([args]);
          return;
        }
        case OverflowPolicy.IGNORE: {
          // Just ignore
          return;
        }
        case OverflowPolicy.QUEUE: {
          prevEntry.queued_events.push([args]);
          return;
        }
        case OverflowPolicy.PARALLEL: {
          const curEntry = prevEntry;
          const run_parallel = async () => {
            try {
              for (const cb of curEntry.listeners) {
                try {
                  if (true === (await Promise.resolve(cb.callback(args)))) {
                    break;
                  }
                } catch (err) {
                  WARN(
                    `Error while sending event to listener ${cb.id} (run_parallel)`,
                    err
                  );
                }
              }
            } finally {
              curEntry.in_process -= 1;
            }
          };
          curEntry.in_process += 1;
          void run_parallel();
          return;
        }
      }
    }

    prevEntry.queued_events.push([args]);
    const curEntry = prevEntry;

    const run_task = async () => {
      try {
        for (;;) {
          const cur_event = curEntry.queued_events.shift();
          if (cur_event === undefined) break;

          // 위의 shift 연산에서 arg 로 undefined 가 들어온 경우에도 [undefined]
          // vs undefined 로서 정상적으로 인식 가능하다.
          const [cur_args] = cur_event;
          for (const cb of curEntry.listeners) {
            try {
              if (true === (await Promise.resolve(cb.callback(cur_args)))) {
                break;
              }
            } catch (err) {
              WARN(
                `Error while sending event to listener ${cb.id} (run_task)`,
                err
              );
            }
          }
        }
      } finally {
        curEntry.in_process -= 1;
      }
    };
    curEntry.in_process += 1;
    void run_task();
  };
  return emitter;
}

/**
 * 이벤트를 발생시킬 수 있는 emitter 를 생성한다.  함수형, 클래스형 컴포넌트
 * 모두에서 사용할 수 있다.  함수형에서는 useEventEmitter 를 사용하면 rule of
 * hook 검사가 더 쉬워진다.  (기능상으론 동일)
 *
 * @param kind 이벤트 종류
 * @param policy 중복해서 이벤트 발생 시 행동
 * @returns 이벤트를 생성할 수 있는 emitter 함수
 */
export function useEventEmitter<T = unknown>(
  kind: string,
  policy: OverflowPolicy = OverflowPolicy.IGNORE
): EmitterType<T> {
  const groupId = useContext(EventGroupContext);
  const eventName = useMemo(
    () => (groupId !== undefined ? `${kind}/${groupId}` : kind),
    [kind, groupId]
  );
  // mkEventEmitter 를 inline 으로 넣으면 문제 없음.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(mkEventEmitter<T>(eventName, policy), [eventName, policy]);
}
