import React from "react";
import { useActiveLoadingViewStyles } from "./styles/ActiveLoadingView";
import { Grid, LinearProgress } from "@material-ui/core";
import CachedImage from "../../public/images/CachedImage";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import translate from "@translate";

type Props = {
  loading_percent?: number;
  style?: React.CSSProperties;
  msg?: string;
};

export default function ActiveLoadingView(props: Props) {
  const classes = useActiveLoadingViewStyles();
  const [currentImageNumber, setCurrentImageNumber] = React.useState(1);

  React.useEffect(() => {
    let isMount = true;
    let imageNumber = 1;

    setInterval(() => {
      if (imageNumber === 4) imageNumber = 1;
      else imageNumber += 1;
      isMount && setCurrentImageNumber(imageNumber);
    }, 800);

    return () => {
      isMount = false;
    };
  }, []);

  return (
    <Grid
      xs={12}
      container
      item
      alignItems="center"
      justifyContent="center"
      className={classes.root}
      style={props.style}
    >
      <div className={classes.container}>
        <CachedImage
          className={classes.loadingImage}
          src={require(`../../../assets/images/public/content/loading/${currentImageNumber}.png`)}
        />
        <LinearProgress
          className={classes.linearProgres}
          value={props.loading_percent}
          variant={props.loading_percent ? "determinate" : undefined}
        />
        <GoonoTypography>
          {props.msg ?? translate.loading_screen.loading}
        </GoonoTypography>
      </div>
    </Grid>
  );
}
