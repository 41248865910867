import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum GithubStateStatus {
  INIT = "GithubState::INIT",
  SUCCESS = "GithubState::SUCCESS",
}

export enum GithubActionKind {
  TRY_SAVE_GITHUB_TOKEN = "GithubAction::TRY_SAVE_GITHUB_TOKEN",
  TRY_CHECKED_LINK_FIRST = "GithubAction::TRY_CHECKED_LINK_FIRST",
}

export type GithubError = never;

export type GithubState =
  | {
      readonly status: GithubStateStatus.INIT;
    }
  | {
      readonly status: GithubStateStatus.SUCCESS;
      readonly ghToken?: string;
      readonly linkFirst?: boolean;
    };

export type GithubAction =
  | {
      readonly kind: GithubActionKind.TRY_SAVE_GITHUB_TOKEN;
      readonly ghToken: string;
    }
  | {
      readonly kind: GithubActionKind.TRY_CHECKED_LINK_FIRST;
    };

const smid = "GITHUB_STATE_MACHINE3";
export type GithubStateMachineType = StateMachine3<
  GithubStateStatus,
  GithubState,
  GithubActionKind,
  GithubAction,
  GithubError
>;
export const githubStateMachine: GithubStateMachineType = new StateMachine3<
  GithubStateStatus,
  GithubState,
  GithubActionKind,
  GithubAction,
  GithubError
>(smid, { status: GithubStateStatus.INIT }, [
  transition(
    GithubStateStatus.INIT,
    GithubStateStatus.SUCCESS,
    GithubActionKind.TRY_SAVE_GITHUB_TOKEN
  ),
  transition(
    GithubStateStatus.SUCCESS,
    GithubStateStatus.SUCCESS,
    GithubActionKind.TRY_SAVE_GITHUB_TOKEN
  ),
  transition(
    GithubStateStatus.SUCCESS,
    GithubStateStatus.SUCCESS,
    GithubActionKind.TRY_CHECKED_LINK_FIRST
  ),
]);

export type DispatchGithubAction = Dispatch<
  StateMachineAction<
    GithubStateStatus,
    GithubState,
    GithubActionKind,
    GithubAction,
    GithubError
  >
>;
export default mkReducer<
  GithubStateStatus,
  GithubState,
  GithubActionKind,
  GithubAction,
  GithubError
>(githubStateMachine);

export const doGithubAction = (
  dispatch: DispatchGithubAction,

  nextAction: GithubAction,
  onResolve: () => void = () => {},
  onReject: (err: GithubError | InternalError) => void = () => {}
) => {
  dispatch(githubStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doGithubActionAsync = (
  dispatch: DispatchGithubAction,
  nextAction: GithubAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(githubStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetGithub = (dispatch: DispatchGithubAction) => {
  dispatch(githubStateMachine.newResetAction());
};
