import { IpfsData } from "@goono-commons/api/object/ipfs";
import { getIPFSUrlDecrypted } from "@goono-react-commons/services/ipfs";

export type IpfsUrlData = {
  type: "img" | "pdf";
  url: string;
};

export const getIPFSUrlData = async (
  ipfs_src: IpfsData | undefined,
  token: string
): Promise<IpfsUrlData> => {
  if (ipfs_src === undefined)
    return {
      type: "img",
      url: ``,
    };

  const { uri, init } = getIPFSUrlDecrypted(
    token,
    ipfs_src.cid,
    ipfs_src.extension,
    ipfs_src.CipherId
  );

  const res = await fetch(uri, init);
  const blob = await res.blob();

  const mimeType =
    ipfs_src.extension.toLowerCase() === "pdf"
      ? "application/pdf"
      : `image/${ipfs_src.extension.toLowerCase()}`;

  const blobWithMime = blob.slice(0, blob.size, mimeType);

  return {
    type: ipfs_src.extension.toLowerCase() === "pdf" ? "pdf" : "img",
    url: window.URL.createObjectURL(blobWithMime),
  };
};
