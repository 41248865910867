import React from "react";
import {
  doSnackbarAction,
  SnackbarStateStatus,
  SnackbarActionKind,
  IndicatorType,
  SnackbarType,
} from "../../redux/store/reducers/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import loadable from "@loadable/component";

const SnackBar = loadable(
  () => import("../../components/public/modals/SnackBar")
);

export default function GoonoSnackbarManager() {
  const dispatch = useDispatch();
  const snackbar = useSelector((state: RootState) => state.snackbar);

  if (
    snackbar.state.status !== SnackbarStateStatus.OPENED &&
    snackbar.state.indicatorType === IndicatorType.INIT
  )
    return <></>;

  return (
    <SnackBar
      open={snackbar.state.status === SnackbarStateStatus.OPENED}
      indicatorType={snackbar.state.indicatorType ?? IndicatorType.INIT}
      indicatorPercent={snackbar.state.indicatorPercent}
      taskId={snackbar.state.taskId}
      type={
        snackbar.state.status === SnackbarStateStatus.OPENED
          ? snackbar.state.type
          : SnackbarType.CONFIRM
      }
      buttonMsg={
        snackbar.state.status === SnackbarStateStatus.OPENED
          ? snackbar.state.buttonMsg
          : undefined
      }
      onClickBtn={
        snackbar.state.status === SnackbarStateStatus.OPENED
          ? snackbar.state.onClickBtn
          : undefined
      }
      duration={
        snackbar.state.status == SnackbarStateStatus.OPENED
          ? snackbar.state.duration
          : undefined
      }
      msg={
        snackbar.state.status === SnackbarStateStatus.OPENED
          ? snackbar.state.msg
          : ``
      }
      isBackdrop={
        snackbar.state.status === SnackbarStateStatus.OPENED
          ? snackbar.state.isBackdrop
          : undefined
      }
      onClose={() => {
        doSnackbarAction(dispatch, {
          kind: SnackbarActionKind.TRY_CLOSE,
        });
      }}
    />
  );
}
