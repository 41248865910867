import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  StateMachineAction,
  mkReducer,
  transition,
} from "@redwit-react-commons/reducers/state3";
import { Dispatch } from "react";

export enum DataRoomTutorialStateStatus {
  ON = "DataRoomTutorialStatus::ON",
  OFF = "DataRoomTutorialStatus::OFF",
}

export enum DataRoomTutorialActionKind {
  START_TUTORIAL = "DataRoomTutorialActionKind::START_TUTORIAL",
  FINISH_TUTORIAL = "DataRoomTutorialActionKind::FINISH_TUTORIAL",
  UPDATE_TUTORIAL_STEP = "DataRoomTutorialActionKind::UPDATE_TUTORIAL_STEP",
}

export type DataRoomTutorialError = never;

export type DataRoomTutorialState =
  | {
      readonly status: DataRoomTutorialStateStatus.OFF;
    }
  | {
      readonly status: DataRoomTutorialStateStatus.ON;
      readonly currentStep: number;
    };

export type DataRoomTutorialAction =
  | {
      readonly kind: DataRoomTutorialActionKind.START_TUTORIAL;
    }
  | {
      readonly kind: DataRoomTutorialActionKind.FINISH_TUTORIAL;
    }
  | {
      readonly kind: DataRoomTutorialActionKind.UPDATE_TUTORIAL_STEP;
      readonly stepNumber: number;
    };

const smid = "DATA_ROOM_TUTORIAL_STATE_MACHINE3";

export type DataRoomTutorialStateMachineType = StateMachine3<
  DataRoomTutorialStateStatus,
  DataRoomTutorialState,
  DataRoomTutorialActionKind,
  DataRoomTutorialAction,
  DataRoomTutorialError
>;

export const DataRoomTutorialStateMachine: DataRoomTutorialStateMachineType =
  new StateMachine3<
    DataRoomTutorialStateStatus,
    DataRoomTutorialState,
    DataRoomTutorialActionKind,
    DataRoomTutorialAction,
    DataRoomTutorialError
  >(smid, { status: DataRoomTutorialStateStatus.OFF }, [
    transition(
      DataRoomTutorialStateStatus.OFF,
      DataRoomTutorialStateStatus.ON,
      DataRoomTutorialActionKind.START_TUTORIAL
    ),
    transition(
      DataRoomTutorialStateStatus.ON,
      DataRoomTutorialStateStatus.OFF,
      DataRoomTutorialActionKind.FINISH_TUTORIAL
    ),
    transition(
      DataRoomTutorialStateStatus.ON,
      DataRoomTutorialStateStatus.ON,
      DataRoomTutorialActionKind.UPDATE_TUTORIAL_STEP
    ),
  ]);

export type DispatchDataRoomTutorialAction = Dispatch<
  StateMachineAction<
    DataRoomTutorialStateStatus,
    DataRoomTutorialState,
    DataRoomTutorialActionKind,
    DataRoomTutorialAction,
    DataRoomTutorialError
  >
>;

export default mkReducer<
  DataRoomTutorialStateStatus,
  DataRoomTutorialState,
  DataRoomTutorialActionKind,
  DataRoomTutorialAction,
  DataRoomTutorialError
>(DataRoomTutorialStateMachine);

export const doDataRoomTutorialAction = (
  dispatch: DispatchDataRoomTutorialAction,
  nextAction: DataRoomTutorialAction,
  onResolve: () => void = () => {},
  onReject: (err: DataRoomTutorialError | InternalError) => void = () => {}
) => {
  dispatch(
    DataRoomTutorialStateMachine.newTryAction(nextAction, onResolve, onReject)
  );
};

export const doDataRoomTutorialActionAsync = (
  dispatch: DispatchDataRoomTutorialAction,
  nextAction: DataRoomTutorialAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(
      DataRoomTutorialStateMachine.newTryAction(nextAction, resolve, reject)
    );
  });
};

export const resetDataRoomTutorialbar = (
  dispatch: DispatchDataRoomTutorialAction
) => {
  dispatch(DataRoomTutorialStateMachine.newResetAction());
};
