import { getAllWorkspaces } from "@goono-react-commons/services/workspace";
import { WorkspaceQueryKey } from "@react-query/key/workspace";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { TokenStateStatus } from "src/redux/store/reducers/token";

export const useGetJoinedWorkspaceListQuery = () => {
  /** token success 아닌 상태로도 불릴 수 있음, 따라서 useLoginUserInfo 사용 x */
  const tokenState = useSelector((state: RootState) => state.token).state;

  const token =
    tokenState.status === TokenStateStatus.SUCCESS
      ? tokenState.token
      : "unknown";

  return useQuery(
    [WorkspaceQueryKey.getJoinedWorkspaceList],
    async () => {
      return { workspaceList: await getAllWorkspaces(token) };
    },
    { staleTime: Infinity, cacheTime: Infinity }
  );
};
