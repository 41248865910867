export default {
  ko: {
    view: {
      fail: {
        401: {
          title: "접근 권한이 없습니다",
          sub1: "해당 페이지에 대한 접근 권한이 없습니다.",
          sub2: "권한과 url을 확인해주세요.",
        },
        404: {
          title: "요청하신 페이지를 찾을 수 없습니다",
          sub1: "주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.",
          sub2: "입력하신 주소가 정확한지 다시 한번 확인해 주세요.",
        },
        title: "요청하신 페이지를 찾을 수 없습니다",
        sub1: "주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.",
        sub2: "입력하신 주소가 정확한지 다시 한번 확인해 주세요.",
      },
      sing_up_success: {
        title: "인증이 완료되었습니다",
        sub1: "연구의 시작부터 마무리까지,",
        sub2: "구노와 함께하세요!",
      },
    },
  },
  en: {
    view: {
      fail: {
        401: {
          title: "Access Deny",
          sub1: "You cannot access this page.",
          sub2: "Please check your authenication.",
        },
        404: {
          title: "The requested page cannot be found",
          sub1: "The page cannot be found since the address is changed or deleted. ",
          sub2: "Please check whether the entered address is correct.",
        },
        title: "You cannot access to page",
        sub1: "The connection is unstable or inaccessible.",
        sub2: "Please check again.",
      },
      sing_up_success: {
        title: "Verification complete",
        sub1: "From the beginning to the end, ",
        sub2: "GOONO will be with you!",
      },
    },
  },
};
