import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { PaymentDBObject } from "@goono-commons/api/object/payment";

export enum PaymentStateStatus {
  INIT = "PaymentState::INIT",
  PENDING = "PaymentState::PENDING",
  SUCCESS = "PaymentState::SUCCESS",
}

export enum PaymentActionKind {
  /** 결제창에서 생성된 링크를 통한 결제 */
  TRY_PURCHASE_VIA_PAID_LINK = "PaymentAction::TRY_PURCHASE_VIA_PAID_LINK",
  /** 서비스 내에서 진행되는 개인결제 */
  TRY_PERSONAL_PURCHASE = "PaymentAction::TRY_PERSONAL_PURCHASE",
  TRY_GET_PAYMENT = "PaymentAction::TRY_GET_PAYMENT",
  TRY_GET_PENDING_PAYMENT = "PaymentAction::TRY_GET_PENDING_PAYMENT",
}
export type PaymentError = never;

export type PaymentState =
  | {
      readonly status: PaymentStateStatus.INIT;
    }
  | {
      readonly status: PaymentStateStatus.PENDING;
      readonly payment: PaymentDBObject;
    }
  | {
      readonly status: PaymentStateStatus.SUCCESS;
      readonly payment: PaymentDBObject;
    };

export type PaymentAction =
  | {
      readonly kind: PaymentActionKind.TRY_GET_PENDING_PAYMENT;
    }
  | {
      readonly kind: PaymentActionKind.TRY_PURCHASE_VIA_PAID_LINK;
      readonly allow_discount?: boolean;
      readonly paid_token?: string;
      readonly payment_res:
        | {
            pay_method: "vbank";
          }
        | {
            pay_method: "card";
            imp_uid: string;
            merchant_uid: string;
            status: string;
            name: string;
            card_name: string;
            card_quota: number;
            buyer_name: string;
            buyer_email: string;
            paid_amount: number;
            paid_at: string;
            receipt_url: string;
          };
    }
  | {
      readonly kind: PaymentActionKind.TRY_PERSONAL_PURCHASE;
      readonly allow_discount?: boolean;
      readonly workspace_id: string;
      readonly payment_res: {
        pay_method: "card";
        imp_uid: string;
        merchant_uid: string;
        status: string;
        name: string;
        card_name: string;
        card_quota: number;
        buyer_name: string;
        buyer_email: string;
        paid_amount: number;
        paid_at: string;
        receipt_url: string;
      };
    }
  | {
      readonly kind: PaymentActionKind.TRY_GET_PAYMENT;
    };

const smid = "PAYMENT_STATE_MACHINE3";
export type PaymentStateMachineType = StateMachine3<
  PaymentStateStatus,
  PaymentState,
  PaymentActionKind,
  PaymentAction,
  PaymentError
>;
export const paymentStateMachine: PaymentStateMachineType = new StateMachine3<
  PaymentStateStatus,
  PaymentState,
  PaymentActionKind,
  PaymentAction,
  PaymentError
>(smid, { status: PaymentStateStatus.INIT }, [
  transition(
    PaymentStateStatus.SUCCESS,
    PaymentStateStatus.SUCCESS,
    PaymentActionKind.TRY_GET_PAYMENT
  ),
  transition(
    PaymentStateStatus.PENDING,
    PaymentStateStatus.SUCCESS,
    PaymentActionKind.TRY_GET_PAYMENT
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.SUCCESS,
    PaymentActionKind.TRY_PURCHASE_VIA_PAID_LINK
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.PENDING,
    PaymentActionKind.TRY_PURCHASE_VIA_PAID_LINK
  ),
  transition(
    PaymentStateStatus.SUCCESS,
    PaymentStateStatus.PENDING,
    PaymentActionKind.TRY_PURCHASE_VIA_PAID_LINK
  ),
  transition(
    PaymentStateStatus.SUCCESS,
    PaymentStateStatus.SUCCESS,
    PaymentActionKind.TRY_PURCHASE_VIA_PAID_LINK
  ),

  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.SUCCESS,
    PaymentActionKind.TRY_PERSONAL_PURCHASE
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.PENDING,
    PaymentActionKind.TRY_PERSONAL_PURCHASE
  ),
  transition(
    PaymentStateStatus.SUCCESS,
    PaymentStateStatus.PENDING,
    PaymentActionKind.TRY_PERSONAL_PURCHASE
  ),
  transition(
    PaymentStateStatus.SUCCESS,
    PaymentStateStatus.SUCCESS,
    PaymentActionKind.TRY_PERSONAL_PURCHASE
  ),

  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.PENDING,
    PaymentActionKind.TRY_GET_PENDING_PAYMENT
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.INIT,
    PaymentActionKind.TRY_GET_PENDING_PAYMENT
  ),
]);

export type DispatchPaymentAction = Dispatch<
  StateMachineAction<
    PaymentStateStatus,
    PaymentState,
    PaymentActionKind,
    PaymentAction,
    PaymentError
  >
>;
export default mkReducer<
  PaymentStateStatus,
  PaymentState,
  PaymentActionKind,
  PaymentAction,
  PaymentError
>(paymentStateMachine);

export const doPaymentAction = (
  dispatch: DispatchPaymentAction,

  nextAction: PaymentAction,
  onResolve: () => void = () => {},
  onReject: (err: PaymentError | InternalError) => void = () => {}
) => {
  dispatch(paymentStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doPaymentActionAsync = (
  dispatch: DispatchPaymentAction,
  nextAction: PaymentAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(paymentStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetPayment = (dispatch: DispatchPaymentAction) => {
  dispatch(paymentStateMachine.newResetAction());
};
