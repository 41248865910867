import React from "react";
import TokenContainer from "./TokenContainer";
import GDTContainer from "./GDTContainer";
import GithubContainer from "./GithubContainer";
import ModalContainer from "./ModalContainer";
import PaymentContainer from "./PaymentContainer";
import SnackbarContainer from "./SnackbarContainer";
import DialogContainer from "./DialogContainer";
import PersistContainer from "./PersistContainer";
import WorkspaceContainer from "./WorkspaceContainer";
import DataRoomTutorialContainer from "./DataRoomTutorialContainer";
import SidebarContainer from "./SidebarContainer";
import ProjectInsideScreenContainer from "./ProjectInsideScreenContainer";
import ResearchFileUploadScreenContainer from "./ResearchFileUploadScreenContainer";

// 다양한 container 들을 포함하도록 감싸줌.
export default class ContainerProvider extends React.PureComponent {
  render() {
    return (
      <>
        <TokenContainer />
        <GDTContainer />
        <GithubContainer />
        <ModalContainer />
        <PaymentContainer />
        <SnackbarContainer />
        <DialogContainer />
        <PersistContainer />
        <WorkspaceContainer />
        <DataRoomTutorialContainer />
        <SidebarContainer />
        <ProjectInsideScreenContainer />
        <ResearchFileUploadScreenContainer />
        {this.props.children}
      </>
    );
  }
}
