import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  ResearchFileUploadScreenActionKind,
  ResearchFileUploadScreenAction,
  ResearchFileUploadScreenStateMachine,
  ResearchFileUploadScreenStateMachineType,
  ResearchFileUploadScreenState,
} from "../store/reducers/researchFileUploadScreen";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.researchFileUploadScreen,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ResearchFileUploadScreenContainerProps = PropsFromRedux & {
  stateMachine: ResearchFileUploadScreenStateMachineType;
};

export const handleResearchFileUploadScreenAction = (
  prevState: ResearchFileUploadScreenState,
  action: ResearchFileUploadScreenAction
): ResearchFileUploadScreenState => {
  switch (action.kind) {
    case ResearchFileUploadScreenActionKind.TRY_UPDATE_STATE: {
      return {
        ...prevState,
        ...action.newState,
      };
    }
  }
};

class ResearchFileUploadScreenContainer extends ReduxStateComponent3<ResearchFileUploadScreenContainerProps> {
  static defaultProps = { stateMachine: ResearchFileUploadScreenStateMachine };
  constructor(props: ResearchFileUploadScreenContainerProps) {
    super(props);
  }

  protected onAction = handleResearchFileUploadScreenAction;
}

export default connector(ResearchFileUploadScreenContainer);
