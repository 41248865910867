import { InternalError } from "@redwit-commons/utils/exception2";
import {
  mkReducer,
  StateMachine3,
  StateMachineAction,
  transition,
} from "@redwit-react-commons/reducers/state3";
import moment from "moment";
import { Dispatch } from "react";
import { GoogleDriveFile } from "src/services/google_drive";

export enum ResearchFileUploadScreenStateStatus {
  INIT = "ResearchFileUploadScreenStateStatus::INIT",
}

export enum ResearchFileUploadScreenActionKind {
  TRY_UPDATE_STATE = "ResearchFileUploadScreenActionKind::TRY_UPDATE_STATE",
}

export type ResearchFileUploadScreenError = never;

export type ResearchFileUploadScreenState = {
  readonly status: ResearchFileUploadScreenStateStatus.INIT;
  readonly targetResearchNoteId: string | undefined;
  readonly targetResearchNoteName: string | undefined;
  readonly targetResearchFolderId: string | undefined;
  readonly targetResearchFolderName: string | undefined;
  readonly researchFileWrittenAt: string;
  /** 일반 업로드 전용 state */
  readonly targetFiles: File[];
  /** google drive 업로드 전용 state */
  readonly targetFilesGDT: GoogleDriveFile[];
};

export type ResearchFileUploadScreenAction = {
  readonly kind: ResearchFileUploadScreenActionKind.TRY_UPDATE_STATE;
  readonly newState: Partial<ResearchFileUploadScreenState>;
};

const smid = "RESEARCH_FILE_UPLOAD_SCREEN_STATE_MACHINE3";

export type ResearchFileUploadScreenStateMachineType = StateMachine3<
  ResearchFileUploadScreenStateStatus,
  ResearchFileUploadScreenState,
  ResearchFileUploadScreenActionKind,
  ResearchFileUploadScreenAction,
  ResearchFileUploadScreenError
>;

export const ResearchFileUploadScreenStateMachine: ResearchFileUploadScreenStateMachineType =
  new StateMachine3<
    ResearchFileUploadScreenStateStatus,
    ResearchFileUploadScreenState,
    ResearchFileUploadScreenActionKind,
    ResearchFileUploadScreenAction,
    ResearchFileUploadScreenError
  >(
    smid,
    {
      status: ResearchFileUploadScreenStateStatus.INIT,
      targetResearchNoteId: undefined,
      targetResearchNoteName: undefined,
      targetResearchFolderId: undefined,
      targetResearchFolderName: undefined,
      researchFileWrittenAt: moment().toISOString(),
      targetFiles: [],
      targetFilesGDT: [],
    },
    [
      transition(
        ResearchFileUploadScreenStateStatus.INIT,
        ResearchFileUploadScreenStateStatus.INIT,
        ResearchFileUploadScreenActionKind.TRY_UPDATE_STATE
      ),
    ]
  );

export type DispatchResearchFileUploadScreenAction = Dispatch<
  StateMachineAction<
    ResearchFileUploadScreenStateStatus,
    ResearchFileUploadScreenState,
    ResearchFileUploadScreenActionKind,
    ResearchFileUploadScreenAction,
    ResearchFileUploadScreenError
  >
>;

export default mkReducer<
  ResearchFileUploadScreenStateStatus,
  ResearchFileUploadScreenState,
  ResearchFileUploadScreenActionKind,
  ResearchFileUploadScreenAction,
  ResearchFileUploadScreenError
>(ResearchFileUploadScreenStateMachine);

export const doResearchFileUploadScreenAction = (
  dispatch: DispatchResearchFileUploadScreenAction,
  nextAction: ResearchFileUploadScreenAction,
  onResolve: () => void = () => {},
  onReject: (
    err: ResearchFileUploadScreenError | InternalError
  ) => void = () => {}
) => {
  dispatch(
    ResearchFileUploadScreenStateMachine.newTryAction(
      nextAction,
      onResolve,
      onReject
    )
  );
};

export const doResearchFileUploadScreenActionAsync = (
  dispatch: DispatchResearchFileUploadScreenAction,
  nextAction: ResearchFileUploadScreenAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(
      ResearchFileUploadScreenStateMachine.newTryAction(
        nextAction,
        resolve,
        reject
      )
    );
  });
};

export const resetResearchFileUploadScreen = (
  dispatch: DispatchResearchFileUploadScreenAction
) => {
  dispatch(ResearchFileUploadScreenStateMachine.newResetAction());
};
