import React, { CSSProperties } from "react";
import { Box, Container, List, ListItem } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useLandingHomeStyles from "../styles";
import clsx from "clsx";

type FunctionDescriptionList = {
  icon: React.ReactNode;
  text: string;
};
export type FunctionSectionItem = {
  type: "white" | "gray";
  imgSrc: string;
  imgPos: CSSProperties;
  title: string;
  list: FunctionDescriptionList[];
};

type FunctionSectionProps = {
  index: number;
  id: string;
  active?: boolean;
} & FunctionSectionItem;

export default function FunctionSection({
  index,
  type,
  imgSrc,
  imgPos,
  title,
  list,
  id,
  active,
}: FunctionSectionProps) {
  const classes = useLandingHomeStyles();

  const renderImageBox = () => {
    return (
      <Box className="function-img-box">
        <img src={imgSrc} alt={title} style={{ ...imgPos }} />
      </Box>
    );
  };

  const renderDescriptionList = () => {
    return (
      <List className="function-list">
        {list.map((li) => {
          return (
            <ListItem className="function-list-item" key={li.text}>
              {li.icon}
              <span>{li.text}</span>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderDescriptoinBox = () => {
    return (
      <Box className="function-description-box">
        <GoonoTypography className="function-title" keepAll>
          {title}
        </GoonoTypography>
        {renderDescriptionList()}
      </Box>
    );
  };

  const renderContentTypeGray = () => {
    return (
      <>
        {renderImageBox()}
        {renderDescriptoinBox()}
      </>
    );
  };
  const renderContentTypeWhite = () => {
    return (
      <>
        {renderDescriptoinBox()}
        {renderImageBox()}
      </>
    );
  };

  return (
    <Box
      id={id}
      className={clsx(
        classes.function_section,
        `function_section_0${index + 1}`,
        type === "gray" && classes.function_section_gray
      )}
    >
      <Container className={classes.container}>
        <Flex
          container
          className={clsx(
            classes.function_section_content_box,
            active && classes.function_section_content_box_active
          )}
        >
          {type === "gray" ? renderContentTypeGray() : renderContentTypeWhite()}
        </Flex>
      </Container>
    </Box>
  );
}
