import React from "react";
import { Route, RouteProps } from "react-router-dom";
import ActiveLoadingView from "src/components/public/view/ActiveLoadingView";

export const CommonRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <React.Suspense fallback={<ActiveLoadingView />}>
      <Route {...rest} />
    </React.Suspense>
  );
};
