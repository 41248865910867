import React, { CSSProperties } from "react";
import { theme } from "@theme";

export const SkeletonBox = ({ style }: { style: CSSProperties }) => {
  return (
    <div
      style={{
        ...style,
        backgroundColor: "#f3f3f3",
        borderRadius: theme.round_3,
      }}
    />
  );
};
