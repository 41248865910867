import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "./access_time";
import { WorkspacePlanKind } from "../../v3/workspace";

export enum PaymentStatus {
  READY = "ready",
  PAID = "paid",
  CANCEL = "cancelled",
  FAIL = "failed",
}
export type PaymentCoreWithoutEtc = {
  readonly id: string;
  readonly seats: number;
  readonly months: number;
  readonly pg?: string;
  readonly pay_method: string;
  readonly imp_uid: string;
  readonly status: PaymentStatus;
  readonly UserId: string;
  readonly WorkspaceId?: string;
};

export type PaymentCore = PaymentCoreWithoutEtc & { etc: string };
export type PaymentDBObjectWOEtc = PaymentCoreWithoutEtc & AccessTime;

export type VBankDescription = {
  vbank_code: string;
  vbank_num: string;
  vbank_name: string;
  vbank_holder: string;
  vbank_date: string;
  name: string;
  buyer_name: string;
  buyer_email: string;
};

export type CardDescription = {
  card_name: string;
  card_quota: number;
  name: string;
  buyer_name: string;
  buyer_email: string;
  paid_amount: number;
  paid_at: string;
  receipt_url: string;
};

export const addCardDescription = (from: T): T => {
  return from
    .addField("name", T.string())
    .addField("buyer_name", T.string())
    .addField("buyer_email", T.string())
    .addField("card_name", T.string())
    .addField("card_quota", T.number())
    .addField("paid_amount", T.number())
    .addField("paid_at", T.string())
    .addField("receipt_url", T.string());
};

export const addVBankDescription = (from: T): T => {
  return from
    .addField("vbank_code", T.string())
    .addField("vbank_num", T.string())
    .addField("vbank_name", T.string())
    .addField("vbank_holder", T.string())
    .addField("vbank_date", T.string())
    .addField("name", T.string())
    .addField("buyer_name", T.string())
    .addField("buyer_email", T.string());
};

export const validateVBankInfo = T.mkValidator<VBankDescription>(
  addVBankDescription(T.object())
);
export const validateCardInfo = T.mkValidator<CardDescription>(
  addCardDescription(T.object())
);

export type PaymentDBObject = PaymentCore & AccessTime;

export type vBankPaymentObject = PaymentDBObjectWOEtc & VBankDescription;
export type CardPaymentObject = PaymentDBObjectWOEtc & CardDescription;

export const PaymentCoreWithoutEtcSchema = T.object()
  .addField("id", T.string())
  .addField("pg", T.string(), false)
  .addField("seats", T.number())
  .addField("months", T.number())
  .addField("pay_method", T.string())
  .addField("imp_uid", T.string())
  .addField(
    "status",
    T.string().withEnum([
      PaymentStatus.READY,
      PaymentStatus.PAID,
      PaymentStatus.CANCEL,
      PaymentStatus.FAIL,
    ])
  )
  .addField("UserId", T.string())
  .addField("WorkspaceId", T.string(), false);

export const PaymentDBObjectWOEtcSchema = addAccessTime(
  PaymentCoreWithoutEtcSchema.clone()
);

export const PaymentCoreSchema = PaymentCoreWithoutEtcSchema.clone().addField(
  "etc",
  T.string()
);
export const PaymentDBObjectSchema = addAccessTime(PaymentCoreSchema.clone());
export const extractPaymentDBObject = T.mkObjectExtractor<PaymentDBObject>(
  PaymentDBObjectSchema
);

export const extractPaymentDBObjectWOEtc =
  T.mkObjectExtractor<PaymentDBObjectWOEtc>(PaymentDBObjectWOEtcSchema);

export const vBankPaymentObjectSchema = addVBankDescription(
  PaymentDBObjectWOEtcSchema.clone()
);
export const validateVBankPaymentObject = T.mkValidator<vBankPaymentObject>(
  vBankPaymentObjectSchema
);

export const CardPaymentObjectSchema = addCardDescription(
  PaymentDBObjectWOEtcSchema.clone()
);
export const validateCardPaymentObject = T.mkValidator<CardPaymentObject>(
  CardPaymentObjectSchema
);

export const refineCardPayment = (rdbPayment: PaymentDBObject) => {
  const etc_string = rdbPayment.etc;
  // const vbankWOetc = extractPaymentDBObjectWOEtc( rdbPayment );
  const CardInfo = validateCardInfo(JSON.parse(etc_string));
  return validateCardPaymentObject({ ...rdbPayment, ...CardInfo });
};

export const refineVBankPayment = (rdbPayment: PaymentDBObject) => {
  const etc_string = rdbPayment.etc;
  // const vbankWOetc = extractPaymentDBObjectWOEtc( rdbPayment );
  const vBankInfo = validateVBankInfo(JSON.parse(etc_string));
  return validateVBankPaymentObject({
    ...rdbPayment,
    ...vBankInfo,
  });
};

export type WorkspacePlanPurchaseInfo = {
  workspace_id: string;
  workspace_name: string;
  institution_name?: string;
  plan: WorkspacePlanKind;
  memo: string;
  person: number;
  /**@description 총 결제 금액 */
  price: number;
  vat: number;
  start: string;
  end: string;
  /**@description 서비스 별 결제 금액 */
  featurePrice?: {
    researchNote?: {
      price: number;
      vat: number;
    };
    dataRoom?: {
      price: number;
      vat: number;
    };
  };
};

export const TempPurchaseInfoObjectSchema = T.object()
  .addField("memo", T.string(), false)
  .addField("price", T.number())
  .addField("period", T.number())
  .addField("vat", T.number())
  .addField("person", T.number())
  .addField("institution_name", T.string(), false)
  .addField("plan", T.string(), false)
  .addField("type", T.string(), false)
  .addField("start", T.string(), false)
  .addField("end", T.string(), false);

export const PaymentPriceObjectSchema = T.object()
  .addField("price", T.number())
  .addField("vat", T.number());

export const WorkspacePlanPurchaseInfoSchema = T.object()
  .addField("workspace_id", T.string())
  .addField("workspace_name", T.string())
  .addField("institution_name", T.string(), false)
  .addField("plan", T.string())
  .addField("memo", T.string())
  .addField("person", T.number())
  .addField("price", T.number())
  .addField("vat", T.number())
  .addField("start", T.string())
  .addField("end", T.string())
  .addField(
    "featurePrice",
    T.object()
      .addField("researchNote", PaymentPriceObjectSchema.clone(), false)
      .addField("dataRoom", PaymentPriceObjectSchema.clone(), false),
    false
  );

export type TempPurchaseObject = {
  paid_token: string;
  paid_info: WorkspacePlanPurchaseInfo;
  created_at: string;
  paid_status?: PaymentStatus;
};

export const TempPurchaseObjectSchema = T.object()
  .addField("paid_token", T.string(), false)
  .addField("paid_info", WorkspacePlanPurchaseInfoSchema.clone())
  .addField("created_at", T.string())
  .addField(
    "paid_status",
    T.string().withEnum([
      PaymentStatus.READY,
      PaymentStatus.PAID,
      PaymentStatus.CANCEL,
      PaymentStatus.FAIL,
    ]),
    false
  );

export const TempPurchaseObjectArraySchema = T.array(
  TempPurchaseObjectSchema.clone()
);
