import React, { ReactNode } from "react";
import { Box, Container, ButtonBase, Paper } from "@material-ui/core";

import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import Flex from "@ui/goono/components/layouts/Flex";
import useLandingHomeStyles from "../styles";
import clsx from "clsx";

import { ReactComponent as LeftArrow } from "@assets/images/public/screen/landingHomeScreen/review/slide-arrow-left.svg";
import { ReactComponent as RightArrow } from "@assets/images/public/screen/landingHomeScreen/review/slide-arrow-right.svg";

import LogoImmuno from "@assets/images/public/screen/landingHomeScreen/review/logo-immuno.png";
import LogoIae from "@assets/images/public/screen/landingHomeScreen/review/logo-iae.png";
import LogoIntek from "@assets/images/public/screen/landingHomeScreen/review/logo-intek.png";
import LogoSangji from "@assets/images/public/screen/landingHomeScreen/review/logo-sang.png";
import LogoNxv from "@assets/images/public/screen/landingHomeScreen/review/logo-nxv.png";
import LogoEwha from "@assets/images/public/screen/landingHomeScreen/review/lgogo-ewha.png";
import LogoRoot from "@assets/images/public/screen/landingHomeScreen/review/logo-root.png";

import Slider, {
  Settings,
  ResponsiveObject,
  CustomArrowProps,
} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import translate from "@translate";

type SlideItemData = {
  title: string;
  text: string;
  reviewer: string;
  logoSrc: string;
};

export default function MainReviewSection() {
  const classes = useLandingHomeStyles();

  return (
    <Box className={classes.review_section} component="section">
      <Container className={classes.container}>
        <Box>
          <GoonoTypography type="h2" className={classes.review_section_title}>
            {translate.landing_home.review.title}
          </GoonoTypography>
          <GoonoTypography className={classes.review_section_sub_title} keepAll>
            {translate.landing_home.review.sub_title}
          </GoonoTypography>
        </Box>
        <ReviewSlide />
      </Container>
    </Box>
  );
}

type SlideArrowProps = { direction: "prev" | "next" } & CustomArrowProps;

function SlideArrow(props: SlideArrowProps) {
  const { onClick, style, className, direction } = props;
  const classes = useLandingHomeStyles();

  const arrowIcon = direction === "prev" ? <LeftArrow /> : <RightArrow />;

  return (
    <ButtonBase
      onClick={onClick}
      className={clsx(className, classes.slide_arrow_button)}
      style={style}
    >
      {arrowIcon}
    </ButtonBase>
  );
}

function SlideAppendDots(props: { dots: ReactNode }) {
  const classes = useLandingHomeStyles();

  return (
    <Box component="ul" className={classes.slide_append_dots}>
      {props.dots}
    </Box>
  );
}
function SlidePaging() {
  const classes = useLandingHomeStyles();

  return <div className={classes.slide_paging}></div>;
}

function ReviewSlideItem({ title, text, reviewer, logoSrc }: SlideItemData) {
  const classes = useLandingHomeStyles();

  return (
    <Paper className={classes.review_slide_item}>
      <GoonoTypography className="slide-item-title" keepAll>
        {title}
      </GoonoTypography>
      <GoonoTypography className="slide-item-text" keepAll>
        {text}
      </GoonoTypography>
      <Flex container className="slide-item-bottom-box">
        <GoonoTypography className="slide-item-reviewer">
          {reviewer}
        </GoonoTypography>
        <hr className="slide-item-divider" />
        <img src={logoSrc} alt="" className="slide-item-logo" />
      </Flex>
    </Paper>
  );
}
function ReviewSlide() {
  const classes = useLandingHomeStyles();
  const slider = React.createRef<Slider>();

  const SlideItemDataList: SlideItemData[] = [
    {
      title: translate.landing_home.review.item_01.title,
      text: translate.landing_home.review.item_01.text,
      reviewer: translate.landing_home.review.item_01.reviewer,
      logoSrc: LogoImmuno,
    },
    {
      title: translate.landing_home.review.item_02.title,
      text: translate.landing_home.review.item_02.text,
      reviewer: translate.landing_home.review.item_02.reviewer,
      logoSrc: LogoIae,
    },
    {
      title: translate.landing_home.review.item_03.title,
      text: translate.landing_home.review.item_03.text,
      reviewer: translate.landing_home.review.item_03.reviewer,
      logoSrc: LogoIntek,
    },
    {
      title: translate.landing_home.review.item_04.title,
      text: translate.landing_home.review.item_04.text,
      reviewer: translate.landing_home.review.item_04.reviewer,
      logoSrc: LogoSangji,
    },
    {
      title: translate.landing_home.review.item_05.title,
      text: translate.landing_home.review.item_05.text,
      reviewer: translate.landing_home.review.item_05.reviewer,
      logoSrc: LogoNxv,
    },
    {
      title: translate.landing_home.review.item_06.title,
      text: translate.landing_home.review.item_06.text,
      reviewer: translate.landing_home.review.item_06.reviewer,
      logoSrc: LogoEwha,
    },
    {
      title: translate.landing_home.review.item_07.title,
      text: translate.landing_home.review.item_07.text,
      reviewer: translate.landing_home.review.item_07.reviewer,
      logoSrc: LogoRoot,
    },
  ];

  const responsiveSettings: ResponsiveObject[] = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        infinite: true,
        variableWidth: true,
        dots: false,
        appendDots: undefined,
        customPaging: undefined,
      },
    },
  ];
  const settings: Settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    responsive: responsiveSettings,
    prevArrow: <SlideArrow direction="prev" />,
    nextArrow: <SlideArrow direction="next" />,
    appendDots: (dots) => <SlideAppendDots dots={dots} />,
    customPaging: () => <SlidePaging />,
  };
  return (
    <Box className={classes.review_slide_box}>
      <Slider ref={slider} {...settings}>
        {SlideItemDataList.map((item) => {
          return <ReviewSlideItem key={item.title} {...item} />;
        })}
      </Slider>
    </Box>
  );
}
