import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import ContainerProvider from "./redux/container";
import GoonoThemeProvider, {
  GoonoTheme,
} from "@ui/goono/styles/GoonoThemeProvider";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { configLogLevel, DEBUG, INFO } from "@redwit-commons/utils/log";
import {
  setBgTaskLauncher,
  mkIdleBgTaskLauncher,
} from "@redwit-commons/utils/bgtask";
import { QueryClient, QueryClientProvider } from "react-query";
import MetaPixel from "./services/MetaPixel";
import GoonoRoutingManager from "./routes/GoonoRoutingManager";
import { BrowserRouter } from "react-router-dom";
import { ENV, changeEnv } from "@goono-react-commons/services/api";
import { ReactChannelIO } from "react-channel-plugin";
import config from "./config";

configLogLevel({
  ReduxComponent: DEBUG,
  ReduxComponent3: DEBUG,
  NavigationState: DEBUG,
  StateMachine2: DEBUG,
  StateMachine3: DEBUG,
  "service/manager": INFO,
  "service/payment": INFO,
  "service/user": INFO,
  service: INFO,
  FutureComponent: INFO,
});

setBgTaskLauncher(mkIdleBgTaskLauncher());

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  /** 환경변수 설정 */
  React.useEffect(() => {
    const test_mode = process.env.REACT_APP_TEST_ENABLED?.toLocaleLowerCase();
    const app_api_server = process.env.REACT_APP_API_SERVER;
    const app_ipfs_api_server = process.env.REACT_APP_IPFS_API_SERVER;

    if (test_mode === "1" || test_mode === "y") {
      if (config.is_test_server) changeEnv("test");
      else changeEnv("staging");

      if (app_api_server && app_api_server.trim() !== "")
        ENV.API_SERVER = app_api_server!;

      if (app_ipfs_api_server && app_ipfs_api_server.trim() !== "")
        ENV.IPFS_API_SERVER = app_ipfs_api_server!;

      console.log("ENV CHANGED: ", ENV);
    }
  }, []);

  return (
    <ServiceProvider>
      <GoonoRoutingManager />
    </ServiceProvider>
  );
}

function ServiceProvider(props: { children: React.ReactChild }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <EmotionThemeProvider theme={GoonoTheme}>
          <GoonoThemeProvider>
            <PersistGate loading={null} persistor={persistor}>
              <ContainerProvider>
                <MetaPixel pixelId="6764709906940838">
                  <BrowserRouter>
                    <ReactChannelIO
                      autoBoot
                      autoBootTimeout={500}
                      pluginKey="48018974-9136-4ba8-95fd-0af0c15d3018"
                    >
                      {props.children}
                    </ReactChannelIO>
                  </BrowserRouter>
                </MetaPixel>
              </ContainerProvider>
            </PersistGate>
          </GoonoThemeProvider>
        </EmotionThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}
