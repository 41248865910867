import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  SnackbarActionKind,
  SnackBarState,
  SnackbarStateStatus,
  snackbarStateMachine,
  SnackbarStateMachineType,
  SnackBarAction,
  SnackbarType,
  IndicatorType,
} from "../store/reducers/snackbar";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.snackbar,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SnackbarContainerProps = PropsFromRedux & {
  stateMachine: SnackbarStateMachineType;
};

export const handleSnackbarAction = (
  prevState: SnackBarState,
  action: SnackBarAction
): SnackBarState => {
  switch (action.kind) {
    case SnackbarActionKind.TRY_UPDATE_INDICATOR_TYPE: {
      return {
        ...prevState,
        taskId: action.taskId,
        indicatorType: action.indicatorType,
        indicatorPercent: 0,
      };
    }
    case SnackbarActionKind.TRY_OPEN: {
      const { allowIndicator = true, ...rest } = action;
      if (rest.isBackdrop === true) {
        document.body.style.overflowY = "hidden";
      } else if (
        document.body.style.overflowY === "hidden" &&
        rest.type === SnackbarType.CONFIRM
      ) {
        document.body.style.overflowY = "scroll";
      }
      return {
        status: SnackbarStateStatus.OPENED,
        ...rest,
        taskId: prevState.taskId,
        indicatorPercent: prevState.indicatorPercent,
        indicatorType:
          allowIndicator === false
            ? IndicatorType.INIT
            : prevState.indicatorType,
      };
    }
    case SnackbarActionKind.TRY_CLOSE: {
      if (
        prevState.status === SnackbarStateStatus.OPENED &&
        prevState.onClose !== undefined
      ) {
        prevState.onClose();
      }
      document.body.style.overflowY = "unset";
      return {
        status: SnackbarStateStatus.INIT,
        taskId: prevState.taskId,
        indicatorType: prevState.indicatorType,
        indicatorPercent: prevState.indicatorPercent,
      };
    }
    case SnackbarActionKind.TRY_UPDATE_INDICATER_PERCENT: {
      return {
        ...prevState,
        indicatorPercent: action.indicatorPercent,
      };
    }
  }
};

class SnackbarContainer extends ReduxStateComponent3<SnackbarContainerProps> {
  static defaultProps = { stateMachine: snackbarStateMachine };
  constructor(props: SnackbarContainerProps) {
    super(props);
  }

  protected onAction = handleSnackbarAction;
}

export default connector(SnackbarContainer);
