export default {
  ko: {
    box: {
      auth_info: {
        name1: "님의",
        name2: "블록체인 기반 안심보안",
        save: "보관 중",
        working: "블록체인 시스템 작동 중",
        description: {
          title: "블록체인 안심보안이란?",
          text1: "블록체인에 암호화되어 안전하게 보관됩니다.",
          text2: "작성 시점을 공적으로 인증 받았습니다.",
          text3: "사진 속 텍스트도 인식하여 보관합니다.",
        },
      },
      pricing_info: {
        title: "결제정보",
        plan: {
          left: "플랜명",
          right: "구노 프리미엄",
        },
        person: "인원 수",
        period: "기간",
        count: "명",
        month: "개월",
        sub_total: "소계",
        total: "최총금액",
        include: "VAT 포함",
      },
    },
  },
  en: {
    box: {
      auth_info: {
        name1: "'s",
        name2: "Blockchain Based Security",
        save: "Being Stored",
        working: "Blockchain System Activated",
        description: {
          title: "What is block chain safe security?",
          text1: "It is safely stored, encrypted in block chain.",
          text2: "The written point is officially certified.",
          text3: "In-photo texts can be recognized and stored.",
        },
      },
      pricing_info: {
        title: "Payment Information",
        plan: {
          left: "Plan",
          right: "GOONO Premium",
        },
        person: "per",
        period: "period",
        count: "per",
        month: "mon",
        sub_total: "Sum ",
        total: "Final amount",
        include: "VAT included",
      },
    },
  },
};
