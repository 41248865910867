import React from "react";
import { ServiceSidebarOptionsProps } from "src/navs/ServiceSidebar";
import { ServiceHeaderOptionsProps } from "src/navs/ServiceHeader";
import loadable from "@loadable/component";

const ActiveLoadingViewLazy = loadable(
  () => import(`src/components/public/view/ActiveLoadingView`)
);

const LandingGoonoBaseGridLazy = loadable(
  () => import(`./GoonoBaseGridByRouteType/Landing`)
);

const PrivateGoonoBaseGridLazy = loadable(
  () => import(`./GoonoBaseGridByRouteType/Private`)
);

export type GoonoBaseGridProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  status?: "loading" | "success";
  viewerOption:
    | {
        type: "landing_page";
      }
    | {
        type: "auth_page";
        hasSidebar?: boolean;
        hasHeader?: boolean;
        sidebarFoldingMode?: boolean;
        headerOptions?: ServiceHeaderOptionsProps;
        sidebarOptions?: ServiceSidebarOptionsProps;
      };
};

export default function GoonoBaseGrid(props: GoonoBaseGridProps) {
  const { status = "success", viewerOption } = props;

  /** loading view */
  if (status === "loading") return <ActiveLoadingViewLazy />;

  /** 랜딩 페이지 baseGrid */
  if (viewerOption.type === "landing_page") {
    return <LandingGoonoBaseGridLazy {...props} />;
  }

  /** 로그인 후 화면 baseGrid */
  return <PrivateGoonoBaseGridLazy {...props} />;
}
