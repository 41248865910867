import React from "react";
import { ScreenURL } from "src/routes/route_list";
import { useHistory } from "react-router-dom";
import { Box, ButtonBase, Container } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useLandingHomeStyles from "../styles";
import clsx from "clsx";
import { ReactComponent as DataRoomMainTitleMarker } from "@assets/images/public/screen/landingHomeScreen/data-room/main-title-marker.svg";
import { ReactComponent as DataRoomLogo } from "@assets/images/public/screen/landingHomeScreen/data-room/data-room-logo.svg";
import DataRoomMainVisual from "@assets/images/public/screen/landingHomeScreen/data-room/data-room-main-visual.png";
import translate from "@translate";
import CustomSlideAction from "../Commons/CustomSlideAction";

export default function DataRoomIntroSection(props: {
  isMobile: boolean;
  goToFreeTrial: () => void;
}) {
  const classes = useLandingHomeStyles();
  const history = useHistory();

  const renderMainContent = () => {
    return (
      <Box className="main-content-box">
        <GoonoTypography
          type="h4"
          className="sub-title"
          children={translate.landing_home.data_room_introduce.sub_title}
        />
        <p className={clsx("title", "title-top")}>
          {translate.landing_home.data_room_introduce.title_01}
        </p>
        <p className={clsx("title", "title-bottom")}>
          <span>
            <DataRoomMainTitleMarker className="marker" />
            {translate.landing_home.data_room_introduce.title_02_bold}
          </span>
          {translate.landing_home.data_room_introduce.title_02}
        </p>
        <p className="desc">
          {translate.landing_home.data_room_introduce.desc}
        </p>
        <Flex container className="button-group">
          <ButtonBase
            className={clsx(
              "button",
              "white-button",
              props.isMobile
                ? "dataroom-white-button-mobile"
                : "dataroom-white-button-pc"
            )}
            onClick={props.goToFreeTrial}
          >
            <DataRoomLogo /> {translate.landing_home.introduce.trial_btn}
          </ButtonBase>
          <ButtonBase
            className={clsx(
              "button",
              "solid-button",
              props.isMobile
                ? "dataroom-solid-button-mobile"
                : "dataroom-solid-button-pc"
            )}
            onClick={() => {
              history.push(ScreenURL.LANDING_CONTACT);
            }}
          >
            {translate.landing_home.introduce.contact_btn}
          </ButtonBase>
        </Flex>
        <CustomSlideAction type="dark" />
      </Box>
    );
  };
  const renderMainVisual = () => {
    return (
      <Box className="main-image">
        <img src={DataRoomMainVisual} alt="" />
      </Box>
    );
  };

  return (
    <Box
      className={clsx(
        classes.data_room_intro_section,
        classes.visual_intro_section
      )}
    >
      <Container className="container">
        <Box className={classes.data_room_intro_main_content}>
          {renderMainContent()}
          {renderMainVisual()}
        </Box>
      </Container>
    </Box>
  );
}
