export default {
  ko: {
    item: {
      document_share_link: {
        expired: `기간 만료`,
      },
      note: {
        add_tag: "+태그 추가하기",
      },
      project_list: {
        deleted_note: `삭제`,
        none_detail_info: "정보없음",
        none_period_start_info: "시작일 정보없음",
        none_period_end_info: "종료일 정보없음",
        add_info: "과제 정보를 추가해주세요.",
        enter_info: "과제 정보 입력",
        none_project_info: "과제 정보가 없습니다.",
        edit_name: "폴더 이름 변경",
        option_title: "연구노트 옵션",
        info: "연구노트 정보",
        edit_research: "과제 정보 변경",
        share: "멤버 관리",
        change_owner: "폴더 소유권한 변경",
        delete: "연구노트 삭제",
        modify: "연구노트 수정",
        share_with: "명과 공유",
        sample_note_notice: {
          left: "연구노트 예제를 위한 ",
          sample_note: "샘플 연구노트",
          right: " 입니다.",
        },
        permission: {
          permission: "권한",
          readonly: "읽기전용",
          edit: "작성권한",
          edit_in_short: "작성권한",
          owner: "소유권한",
          external: "외부 사용자",
        },
        research: {
          name: "과제명",
          owner: "책임자",
          date: "기간",
          number: "과제번호",
        },
        auth_badge: {
          owner: "소유권한",
          read_only: "읽기권한",
          write_only: "작성권한",
        },
      },
      user_selector: {
        title: "멤버 검색",
        external_member: "외부 멤버",
        not_format: "이메일 형식에 맞지 않습니다.",
        add: "추가",
        admin: "관리자",
      },
      global_search: {
        recent_search: "최근 검색어",
        clear: "모두 지우기",
        empty_recent_search: "최근 검색어가 없습니다.",
      },
      project_card: {
        workspace_note: "워크스페이스 내 연구노트",
      },
      note_chart: {
        count: "연구노트 수",
      },
      strong_box: {
        member: `Strong Box 멤버`,
        options: {
          title: "Strong Box 옵션",
          edit: "수정",
          delete: "삭제",
          info: "정보 보기",
        },
      },
      document_box_member: {
        label: "멤버 초대",
        placeholder: "hey@goono.io",
        helper_text: "이메일 혹은 이름으로 검색 가능합니다.",
      },
      document_box_folder: {
        options: {
          label: "폴더 옵션",
          edit: "폴더명 수정",
          delete: "폴더 삭제",
        },
        remove: {
          msg: `삭제하시면 업로드된 모든 파일 및 폴더 복구가 불가능 합니다.\n정말 삭제하시겠어요?`,
          msg_highlight: `모든 파일 및 폴더 복구가 불가능`,
          msg_highlight_2: "",
        },
      },
      document_box_file: {
        options: {
          label: "파일 옵션",
          delete: "파일 삭제",
          move: "파일 이동",
          edit: "파일명 수정",
        },
      },
    },
  },
  en: {
    item: {
      document_share_link: {
        expired: `Expired`,
      },
      note: {
        add_tag: "+Add Tags",
      },
      project_list: {
        deleted_note: `Deleted`,
        none_detail_info: "No info",
        none_period_start_info: "No info",
        none_period_end_info: "No info",
        none_project_info: "There is no information.",
        add_info: "Add your research information.",
        enter_info: "Enter",
        edit_name: "Change name",
        option_title: "Note options",
        info: "Note information",
        edit_research: "Edit research info",
        share: "Members",
        delete: "Delete",
        modify: "Modify",
        change_owner: "Change an owner",
        research: {
          name: "Research",
          owner: "Manager",
          date: "Period",
          number: "No.",
        },
        share_with: " people",
        sample_note_notice: {
          left: "This is a ",
          sample_note: "sample research note",
          right: " for demonstration.",
        },
        permission: {
          permission: "Permission",
          readonly: "Read only",
          edit: "Write only",
          edit_in_short: "Write only",
          owner: "Owner",
          external: "Guest",
        },
        auth_badge: {
          owner: "Owner",
          read_only: "Read only",
          write_only: "Write only",
        },
      },
      user_selector: {
        title: "Search members",
        external_member: "External member",
        not_format: "The email address does not fit the format",
        add: "Add",
        admin: "Admin",
      },
      global_search: {
        recent_search: "Recent search terms",
        clear: "Clear all",
        empty_recent_search: "No recent search term history.",
      },
      project_card: {
        workspace_note: "Workspace research note",
      },
      note_chart: {
        count: "Number of notes",
      },
      strong_box: {
        member: `Members with access`,
        options: {
          title: "Strong Box options",
          edit: "Edit",
          delete: "Delete",
          info: "Show Info",
        },
      },
      document_box_member: {
        label: "Add members",
        placeholder: "hey@goono.io",
        helper_text: "Search name or emails",
      },
      document_box_folder: {
        options: {
          label: "Actions",
          edit: "Rename",
          delete: "Delete",
        },
        remove: {
          msg: `Are you sure? The folder and all files will be deleted permanently and can't be undone.`,
          msg_highlight: `deleted permanently`,
          msg_highlight_2: `can't be undone.`,
        },
      },
      document_box_file: {
        options: {
          label: "Actions",
          delete: "Delete",
          move: "Move",
          edit: "Rename",
        },
      },
    },
  },
};
