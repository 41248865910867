export default {
  ko: {
    graph: {
      out_of_0: "전체 ",
      out_of_1: "명 중 ",
      out_of_2: "명이 ",
      uploaded: "연구 파일을 추가했습니다.",
      active: `활동`,
      inactive: `비활동`,
      week_of_month: (
        year: string | number,
        month: string | number,
        weekOfMonth: string | number
      ) => `${year}년 ${month}월 ${weekOfMonth}주차`,
      month_of_year: (year: string | number, month: string | number) =>
        `${year}년 ${month}월`,
    },
  },
  en: {
    graph: {
      out_of_0: "With ",
      out_of_1: " users, ",
      out_of_2: " people ",
      uploaded: "have posted research files.",
      active: `Active`,
      inactive: `Inactive`,
      week_of_month: (
        year: string | number,
        month: string | number,
        weekOfMonth: string | number
      ) => `Week ${weekOfMonth}, ${month} / ${year}`,
      month_of_year: (year: string | number, month: string | number) =>
        `${month}/${year}`,
    },
  },
};
