export const ScreenURL = {
  /** ---------- common ---------- */
  LANDING_HOME: "/",
  LANDING_SERVICE: "/service",
  LANDING_SERVICE_DATAROOM: "/service/data-room",
  LANDING_SERVICE_BUILTIN: "/service/built-in",
  LANDING_PRICE: "/pricing",
  LANDING_CALLBACK: "/landing/callback",
  SERVICE_CALLBACK: "/service/callback",
  LANDING_CONTACT: "/contact",
  LANDING_CONTACT_TYPE: "/contact/:type",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  REGISTER: "/register",
  REGISTER_EMAIL: "/register/email",
  AUTH_PW: "/auth/password",
  UPDATE_PW: "/update/password",
  EMAIL_VERIFEID: "/email/verified",
  TERM: "/terms/:term",
  TERM_INFO: "/terms",
  FORBIDDEN: "*",
  MYPAGE: "/my-page",
  CERTIFICATION: "/certification",

  DATA_ROOM_SHARED_DOCUMENT_PREVIEW: "/shared-document-preview",
  DATA_ROOM_SHARED_DOCUMENT_NDA_TEMPLATE_PREVIEW:
    "/shared-document-preview/nda",

  /** private - common (워크스페이스 유무와 관계없는 로그인 이후 화면) */

  /** @deprecated */
  PURCHASE: "/purchase",
  /** @deprecated */
  PAYMENT_RESULT: "/payment/result",

  CUSTOM_PURCHASE: "/purchase/private",
  PAYMENT_VBANK: "/payment/vbank",

  RESEARCH_NOTE_CONTACT: "/research-note/contact",
  RESEARCH_NOTE_PRICE_GUIDE: "/research-note/price-guide",
  RESEARCH_NOTE_HELP: "/research-note/service-help",

  WORKSPACE_CREATE: "/workspace/create",
  WORKSPACE_EMPTY: "/workspace/empty",
  WORKSPACE_IP_BLOCKING: "/workspace/ip-blocking",
  WORKSPACE_CHECKOUT: "/workspace/checkout",

  RESEARCH_NOTE_DASHBOARD: "/research-note/dashboard",
  DATAROOM_DASHBOARD: `/data-room/dashboard`,
  DATAROOM_LIST: `/data-room/document-boxs`,

  /**
   * worksapce가 있어야 접근 가능한 화면 목록
   * ========== 해당 화면들부터는 앞에 의무적으로 /:workspace_id 추가해주세요 ============
   * */

  /** ---------- workspace ---------- */
  WORKSPACE_SETTING: "/:workspace_id/workspace/setting",

  /** ---------- research-note ---------- */
  RESEARCH_NOTE_SERVICE_LINKAGE: "/:workspace_id/research-note/services",
  RESEARCH_NOTE_GITHUB_LINK: "/:workspace_id/research-note/services/github",
  RESEARCH_NOTE_AUTHS: "/:workspace_id/research-note/auths",
  RESEARCH_NOTE_SEARCH_RESULT: "/:workspace_id/research-note/search/:word",
  RESEARCH_NOTE_UPLOAD: "/:workspace_id/research-note/upload",
  RESEARCH_NOTE_UPLOAD_TYPE: "/:workspace_id/research-note/upload/:type",
  RESEARCH_NOTE_PROJECT_LIST: "/:workspace_id/research-note/projects",
  RESEARCH_NOTE_PROJECT_INSIDE: "/:workspace_id/research-note/project/:id",
  RESEARCH_NOTE_PROJECT_FOLDER_INSIDE:
    "/:workspace_id/research-note/project/:id/:folder_id",
  RESEARCH_NOTE_PROJECT_PREVIEW:
    "/:workspace_id/research-note/project/:project_id/preview/:note_id",
  RESEARCH_NOTE_CREATE_PROJECT:
    "/:workspace_id/research-note/project_write/create",
  RESEARCH_NOTE_EDIT_PROJECT:
    "/:workspace_id/research-note/project_write/edit/:projectId",
  RESEARCH_NOTE_SAMPLE_FILE_PREVIEW:
    "/:workspace_id/research-note/sample_preview/:note_id",

  /** -------- research-note :: admin only ------------- */
  RESEARCH_NOTE_ADMIN_DASHBOARD:
    "/:workspace_id/research-note/admin/dashboard/home/:mode",
  RESEARCH_NOTE_ADMIN_DASHBOARD_PROJECT:
    "/:workspace_id/research-note/admin/dashboard/:id",
  RESEARCH_NOTE_ADMIN_DASHBOARD_PROJECT_FOLDER_INSIDE:
    "/:workspace_id/research-note/admin/dashboard/:id/:folder_id",
  RESEARCH_NOTE_ADMIN_DASHBOARD_PREVIEW:
    "/:workspace_id/research-note/admin/dashboard/:project_id/preview/:note_id",

  /** ---------- data-room  ---------- */
  DATAROOM_DOCUMENT_BOX_INSIDE: `/:workspace_id/data-room/documentBox/:documentBoxId`,
  DATAROOM_DOCUMENT_BOX_FOLDER_INSIDE: `/:workspace_id/data-room/documentBox/:documentBoxId/:documentBoxFolderId`,
  DATAROOM_DOCUMENT_BOX_CREATE: `/:workspace_id/data-room/document-box/create`,
  DATAROOM_DOCUMENT_BOX_EDIT: `/:workspace_id/data-room/document-box/edit`,
  DATAROOM_DOCUMENT_BOX_INFO: `/:workspace_id/data-room/document-box/info`,

  DATAROOM_DOCUMENT_INSIDE: `/:workspace_id/data-room/documentBox/:document_box_id/document/:document_id`,
  DATAROOM_DOCUMENT_PREVIEW: `/:workspace_id/data-room/documentBox/:document_box_id/document/:document_id/preview`,

  DATAROOM_NDA_MANAGEMENT: `/:workspace_id/data-room/document-box/:document_box_id/nda-management`,
  DATAROOM_NDA_INSIDE: `/:workspace_id/data-room/document-box/:document_box_id/nda-management/:nda_id`,
  DATAROOM_NDA_PREVIEW: `/:workspace_id/data-room/document-box/:document_box_id/nda-management/:nda_id/preview`,
  DATAROOM_CONTRACTED_NDA_PREVIEW: `/:workspace_id/data-room/document-box/:document_box_id/nda-management/:nda_contract_id/contract/preview`,

  DATAROOM_SEARCH_RESULT: "/:workspace_id/data-room/search/:word",
  DATAROOM_DOCUMENT_SHARE_LINK_EMAIL_SEND: `/:workspace_id/data-room/document-share-link/email`,
} as const;
