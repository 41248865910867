export default {
  ko: {
    snackbar: {
      loading_share_link_email: `메일을 전송중입니다...`,
      prevent_context_menu: `죄송합니다. 연구파일의 보안을 위해 마우스 우클릭이 불가능합니다.`,
      uploading: "파일추가 중입니다...",
      only_accessible_admin: "소유권한만 접근 가능합니다",
      confirm: "확인",
      loading: "처리 중입니다...",
      copy: "복사 되었습니다.",
      send_mail: "메일 전송 중입니다.",
      problem: "문제가 발생했습니다. 잠시후 다시 시도해주세요.",
      download_lock: "폴더 소유권한가 파일 다운로드를 방지하였습니다.",
      delete_lock: "폴더 소유권한가 파일 삭제를 방지하였습니다.",
      ticket: "인증 횟수가 소진되었습니다.",
      download_ing: "다운로드 중입니다.",
      download_don: "다운로드가 완료되었습니다.",
      no_changes: "변경 내용이 없습니다.",
      success: "정상적으로 반영되었습니다.",
      pop_up:
        "사용하시는 브라우저에서 팝업이 차단되었습니다. 팝업 차단을 해제하고 다시 다운로드 시도해주세요.",
      pdf_formatting:
        "PDF로 변환 중입니다. 용량에 따라 시간이 소요될 수 있으니 잠시 후 다시 시도해주세요.",
      upload: "파일이 업로드 되었습니다.",
      file_exceed: "추가 가능한 파일 개수 초과",
      updated: "변경되었습니다.",
      owner_updated: "소유자가 변경되었습니다.",
      filter_options_change: "검색 옵션이 변경되었습니다.",
      folder: {
        create_limit_alert: `폴더는 최대 30개 까지 생성할 수 있습니다`,
        create_done: `생성되었습니다`,
        edit_done: `수정되었습니다.`,
        delete_done: `삭제되었습니다.`,
      },
      duplicate_upload: {
        title: "연구파일 추가 불가능",
        msg: "죄송하지만 연구파일 추가 중에는 추가적으로 연구파일을 추가할 수 없습니다. 현재 파일 추가 작업이 끝난 후 다시 시도해 주세요.",
      },
      go_back_warning: {
        msg: "연구파일 추가목록이 초기화됩니다. 계속 하시겠습니까?",
      },
      file_upload_alert: {
        none_file: {
          title: "추가 가능한 연구파일이 없습니다",
          size_and_extension_error:
            "죄송합니다. 해당 용량과 형식은 추가 불가능한 연구파일입니다. 확인해주세요. ( 추가 가능 최대 용량 500mb, 추가 가능 파일 형식 jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )",
          size_error:
            "죄송합니다. 해당 용량은 추가 불가능한 연구파일입니다. 확인해주세요. ( 추가 가능 최대 용량 500mb )",
          extenstion_error:
            "죄송합니다. 해당 형식은 추가 불가능한 연구파일입니다. 확인해주세요. ( 추가 가능 파일 형식 jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )",
        },
        common: {
          title: "추가 불가능한 연구 파일이 있습니다.",
          size_and_extension_error: (n: number) =>
            `${n}개의 파일이 성공적으로 추가되었습니다. 그러나 일부 파일은 용량 및 형식으로 인해 추가 불가능 합니다. ( 추가 가능 최대 용량 500mb, 추가 가능 파일 형식 jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )`,
          size_error: (n: number) =>
            `${n}개의 파일이 성공적으로 추가되었습니다. 그러나 일부 파일은 용량으로 인해 추가 불가능 합니다. ( 추가 가능 최대 용량 500mb )`,
          extenstion_error: (n: number) =>
            `${n}개의 파일이 성공적으로 추가되었습니다. 그러나 일부 파일은 형식으로 인해 추가 불가능 합니다. ( 추가 가능 파일 형식 jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )`,
        },
      },
      role: "권한이 변경되었습니다.",
      invite: {
        full_admin: "이미 3명 이상의 관리자를 보유 중입니다.",
        full_members: "워크스페이스 내 초대 정원이 꽉 찼습니다.",
      },
      doc_drive: {
        upcoming: {
          title: "데이터룸 업데이트 예정 안내",
          msg: "데이터룸 기능은 곧 업데이트 될 예정입니다. 업데이트가 완료되면 다시 알려드릴게요!",
        },
      },
      edit_done: `수정되었습니다.`,
      success_data_room: {
        processed: "정상적으로 처리되었습니다.",
        sended_email: "메일이 전송되었습니다.",
        created_share_link: "공유링크가 삭제되었습니다.",
        activated_share_link: "공유링크가 활성화 되었습니다.",
        deactivated_share_link: "공유링크가 비활성화 되었습니다.",
        copied_share_link: "공유링크가 복사되었습니다.",
        created_nda_template: "생성이 완료되었습니다.",
        modified_member: "멤버가 수정되었습니다.",
        uploaded_document: "업로드가 완료되었습니다.",
        updated: "수정되었습니다.",
        created_document_folder: "폴더가 생성되었습니다.",
        created_document_box: "Strong Box가 생성되었습니다.",
        deleted: "삭제되었습니다.",
      },
      creating_nda_template: "NDA 템플릿을 생성중입니다...",
      uploading_document: "업로드를 진행중입니다...",
      document: {
        share_link_create_fail: `공유링크 생성에 실패하였습니다.`,
        share_link_delete_fail: `공유링크 삭제에 실패하였습니다.`,
        share_link_update_fail: `공유링크 상태 변경에 실패하였습니다.`,
        invalid_file_extension: `추가 가능한 파일 확장자가 아닙니다.`,
        upload_fail: `업로드에 실패하였습니다.`,
      },
    },
  },
  en: {
    snackbar: {
      loading_share_link_email: `Sending…`,
      prevent_context_menu: `Sorry, right-click is not possible for security of research files.`,
      uploading: "Adding files…",
      only_accessible_admin: "Only owner can access it.",
      confirm: "Confirm",
      loading: "Loading...",
      copy: "Has been copied.",
      send_mail: "Sending Mail",
      problem: "A problem has occurred. Please try later.",
      download_lock: "Owner has blocked downloading option in this folder.",
      delete_lock: "Owner has blocked deleting option in this folder.",
      ticket: "No free tickets to upload notes left.",
      download_ing: "Downloading Notes",
      download_don: "Downloading Notes Completed",
      no_changes: "There's no changes.",
      success: "Successfully updated.",
      pop_up: "Pop-up is blocked. Please try again after allow the pop-up.",
      pdf_formatting:
        "Converting to PDF. It may take some time according to the file capacity. Please try again in a moment.",
      upload: "Successfully uploaded the file.",
      file_exceed: "File count exceeded",
      updated: "Successfully changed.",
      owner_updated: "Successfully changed.",
      filter_options_change: "Successfully changed search options.",
      folder: {
        create_limit_alert: `You can create up to 30 folders`,
        create_done: `Successfully created.`,
        edit_done: `Successfully edited.`,
        delete_done: `Successfully deleted.`,
      },
      duplicate_upload: {
        title: "Unable to add research file",
        msg: "I'm sorry, but you cannot add additional research files while adding research files. Please try again after adding the current file.",
      },
      go_back_warning: {
        msg: "Add research file list will be initialized. Would you want to continue?",
      },
      file_upload_alert: {
        none_file: {
          title: "No research files available to add.",
          size_and_extension_error:
            "Sorry. This file size & format is invalid. Please check. ( Allowed maximum size is 500mb, Allowed extensions are jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )",
          size_error:
            "Sorry. This file size is invalid. Please check. ( Allowed maximum size is 500mb )",
          extenstion_error:
            "Sorry. This file size & format is invalid. Please check. ( Allowed extensions are jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )",
        },
        common: {
          title: "Research file that cannot be added.",
          size_and_extension_error: (n: number) =>
            `${n} files was successfully added. but, some files size & format are invalid. ( Allowed maximum size is 500mb, Allowed extensions are jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )`,
          size_error: (n: number) =>
            `${n} files was successfully added. but, some files size is invalid. ( Allowed maximum size is 500mb )`,
          extenstion_error: (n: number) =>
            `${n} files was successfully added. but, some format is invalid. ( Allowed extensions are jpg, jpeg, png, svg, tiff, webp, heic, doc, docx, ppt, pptx, pdf, xls, xlsx )`,
        },
      },
      role: "The authority has been changed.",
      invite: {
        full_admin: "already have three or more administrators.",
        full_members: "The invitation capacity within the workspace is full",
      },
      doc_drive: {
        upcoming: {
          title: "Upcoming",
          msg: "Data Room feature will be updated soon. I'll keep you posted once the update is done!",
        },
      },
      edit_done: `수정되었습니다`,
      success_data_room: {
        processed: "Successfully submitted",
        sended_email: "Email sent",
        created_share_link: "Link deleted",
        activated_share_link: "Link activated",
        deactivated_share_link: "Link deactivated",
        copied_share_link: "Link copied",
        created_nda_template: "Template created",
        modified_member: "Member saved",
        uploaded_document: "Upload complete",
        updated: "Saved",
        created_document_folder: "Folder created",
        created_document_box: "Strong Box created",
        deleted: "Deleted",
      },
      creating_nda_template: "Creating NDA template...",
      uploading_document: "Uploading...",
      document: {
        share_link_create_fail: `Link was not created.`,
        share_link_delete_fail: `Link was not deleted.`,
        share_link_update_fail: `Status change failed.`,
        invalid_file_extension: `Unsupported file type.`,
        upload_fail: `Upload failed.`,
      },
    },
  },
};
