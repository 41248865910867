export default {
  ko: {
    alert: {
      only_admin: "워크스페이스 관리자만 접근 가능합니다",
      success_move: "이동되었습니다.",
      success_edit: "수정되었습니다.",
      success_delete: "삭제되었습니다.",
      success_unlink: "해제되었습니다.",
      success_create: "생성되었습니다.",
      success_add: "추가되었습니다.",
      success_change: "변경되었습니다.",
      success_create_project: "연구노트 생성이 완료되었습니다.",
      success_invite_workspace: "워크스페이스에 초대 완료되었습니다.",
      success_change_file_name: "파일명이 변경되었습니다.",
      success_send_email:
        "초대 메일이 성공적으로 발송되었습니다. 확인 후 회원가입을 진행해주세요!",
      enter_invite_email: "초대할 팀원의 이메일을 입력하세요.",
      warn_delete_admin: "관리자는 삭제할 수 없습니다.",
      click_delete_user: "삭제할 팀원을 한명 이상 선택하세요.",
      send_auth_email:
        "가입한 이메일로 인증 메일이 발송되었습니다. (스팸함도 확인해주세요.)",
      no_register_email: "가입되지 않은 이메일입니다.",
      fail_send_email: "이메일 전송에 실패하였습니다.",
      already_linked_github_repo: "이미 연동된 폴더입니다.",
      no_auth_note: "사용자의 노트가 없습니다.",
      send_share_project_email: "멤버 관리 수정사항이 반영되었습니다.",
      valid_email: "사용 가능한 이메일 계정입니다.",
      need_login: "로그인 해주세요.",
      link_github:
        "연동 완료! 매일 밤 12시에 자동으로 연구노트가 업로드 됩니다!",
      resend_auth_email:
        "이메일 인증을 하지 않아서 재발송합니다. 메일함을 확인해주세요.",
      change_pw: "비밀번호가 변경되었습니다.",
      send_contact: "문의하신 내용이 정상적으로 접수되었습니다.",
      problem: "오류가 발생했습니다. 다시 시도해주세요.",
      phone_number: "연락처를 입력해주세요.",
      future_date: "작성 날짜를 미래로 설정할 수 없습니다.",
      enter_word: "검색어를 입력해주세요.",
      error_extension: "사용하실 수 없는 파일 형식입니다.",
      drag_file: "파일을 끌어다 놓으면 업로드 됩니다.",
      uploading: "파일 업로드 중입니다.",
      goono_top_alert:
        "30만 유저의 선택! 만족도 4.6점 전자연구노트 GOONO 이용하기 👍",
      banner_button: "1:1 문의하기",
      drag_drop: "연구파일 추가활동이 불가능합니다.",
      drag_drop_msg:
        "연구파일 추가 활동 필요 시 연구노트 소유권한에게 작성 권한을 요청해주세요.",
      cannot_access_guest: "게스트는 접근할 수 없습니다.",
      create_workspace: "워크스페이스를 생성중입니다...",
    },
  },
  en: {
    alert: {
      only_admin: "Only can access workspace admin",
      success_move: "Success to Move",
      success_edit: "Successfully edited.",
      success_delete: "Successfully deleted.",
      success_unlink: "Successfully unlinked.",
      success_create: "Successfully created.",
      success_add: "Successfully added.",
      success_change: "Successfully changed.",
      success_create_project: "Successfully completed note creation",
      success_invite_workspace: "Successfully invited to the workspace.",
      success_change_file_name: "Successfully changed the file name.",
      success_send_email:
        "Successfully sent the invitation email. After confirmation, please proceed with registration!",
      enter_invite_email:
        "Enter the email of the team member you want to invite.",
      warn_delete_admin: "Cannot delete administrators.",
      click_delete_user: "Please select more than 1 member.",
      send_auth_email: "Certification mail is sent to the registered e-mail.",
      no_register_email: "This email is not registered.",
      fail_send_email: "Fail to send the email",
      already_linked_github_repo: "There's already linked one.",
      no_auth_note: "User cannot have a note.",
      send_share_project_email:
        "Changes in researcher management have been reflected.",
      valid_email: "This email account is available.",
      need_login: "Please sign in.",
      link_github:
        "Successfully completed integration! Your research note will be uploaded every midnight automatically!",
      resend_auth_email:
        "We resend an authenticate the email. please check your mailbox.",
      change_pw: "Your password has been changed.",
      send_contact: "Your inquiry has been received.",
      problem: "There is a problem. Please retry.",
      phone_number: "Enter your phone number",
      future_date: "Written date cannot be set to future.",
      enter_word: "Please enter a word",
      error_extension: "invalid file extenstion",
      drag_file: "Drag files an create notes",
      uploading: "Uploading...",
      goono_top_alert:
        "Chosen by 300K users! 4.6 satisfaction. Use GOONO for electronic research notes! 👍",
      banner_button: "1:1 contact",
      drag_drop: "Unable to add research files.",
      drag_drop_msg:
        "If you need to add a research files, please ask the owner of the research note for permission to write only.",
      cannot_access_guest: "Guest cannot access.",
      create_workspace: "Creating workspace…",
    },
  },
};
