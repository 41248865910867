import { useGetJoinedWorkspaceListQuery } from "@react-query/workspace/useGetJoinedWorkspaceListQuery";
import { useQueryString } from "@utils/hooks/pure/useQueryString";
import { useMoveServiceDashboard } from "@utils/hooks/service/useMoveServiceDashboard";
import GoonoBaseGrid from "@utils/templates/GoonoBaseGrid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ResearchNoteDashboardScreenSkeletonView from "src/components/workspace/WorkspaceDashboardSkeletonView";
import { RootState } from "src/redux/store/reducers";
import { TokenStateStatus } from "src/redux/store/reducers/token";
import {
  doWorkspaceAction,
  WorkspaceActionKind,
} from "src/redux/store/reducers/workspace";
import { ScreenURL } from "src/routes/route_list";

export default function WorkspaceCheckoutScreen() {
  const queryString = useQueryString();
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenState = useSelector((state: RootState) => state.token).state;
  const { moveServiceDashboard } = useMoveServiceDashboard(true);

  const workspaceList =
    useGetJoinedWorkspaceListQuery().data?.workspaceList ?? [];

  React.useEffect(() => {
    const workspaceId = queryString.parse(`workspace_id`);

    const userId =
      tokenState.status === TokenStateStatus.SUCCESS ? tokenState.id : ``;

    const targetWorkspace = workspaceList.find((w) => w.id === workspaceId);

    if (targetWorkspace) {
      doWorkspaceAction(
        dispatch,
        {
          kind: WorkspaceActionKind.TRY_UPDATE_CURRENT_WORKSPACE,
          targetWorkspace: targetWorkspace,
          userId,
        },
        () => setTimeout(() => moveServiceDashboard(), 100)
      );
    } else {
      history.push(ScreenURL.RESEARCH_NOTE_DASHBOARD);
    }
  }, []);

  return (
    <GoonoBaseGrid
      viewerOption={{
        type: "auth_page",
        sidebarOptions: {
          hasLocalMenu: false,
        },
        headerOptions: {
          hasSearchForm: false,
        },
      }}
    >
      <ResearchNoteDashboardScreenSkeletonView />
    </GoonoBaseGrid>
  );
}
