import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  PaymentAction,
  PaymentActionKind,
  PaymentState,
  paymentStateMachine,
  PaymentStateMachineType,
  PaymentStateStatus,
} from "../store/reducers/payment";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../store/reducers/token";
import { refineVBankPayment } from "@goono-commons/api/object/payment";
import moment from "moment";
import {
  checkPayment,
  getPendingPayment,
  issueCard,
  issuePersonalCard,
} from "@goono-react-commons/services/payment";
import { v4 as uuid } from "uuid";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.payment,
    token: state.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PaymentContainerProps = PropsFromRedux & {
  stateMachine: PaymentStateMachineType;
};

class PaymentContainer extends ReduxStateComponent3<PaymentContainerProps> {
  static defaultProps = { stateMachine: paymentStateMachine };
  constructor(props: PaymentContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: PaymentState,
    action: PaymentAction
  ): Promise<PaymentState> {
    const { token } = this.props;

    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "PaymentContainer::onAction",
        msg: "not goono login",
      });

    const userToken = token.state.token;

    switch (action.kind) {
      case PaymentActionKind.TRY_GET_PENDING_PAYMENT: {
        const ret = await this.guardAwait(() => getPendingPayment(userToken));
        if (ret === undefined) return { status: PaymentStateStatus.INIT };
        const pendingVbank = refineVBankPayment(ret.response);
        if (moment(pendingVbank.vbank_date).isAfter(moment()))
          return { status: PaymentStateStatus.PENDING, payment: ret.response };
        return { status: PaymentStateStatus.INIT };
      }
      case PaymentActionKind.TRY_GET_PAYMENT: {
        if (prevState.status === PaymentStateStatus.INIT) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "PaymentContainer",
            msg: "invalid state change",
            prevState,
          });
        }
        const result = await this.guardAwait(() =>
          checkPayment(userToken, {
            merchant_uid: prevState.payment.id,
          })
        );
        const paymentObj = result.response;
        if (paymentObj.status === "paid") {
          return { status: PaymentStateStatus.SUCCESS, payment: paymentObj };
        } else if (
          paymentObj.status === "failed" ||
          paymentObj.status === "cancelled"
        ) {
          // Abort? or make state INIT
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: "PaymentContainer",
            msg: "payment is failed or cancelled",
            paymentObj,
          });
        }
        return { status: PaymentStateStatus.SUCCESS, payment: paymentObj };
      }
      case PaymentActionKind.TRY_PURCHASE_VIA_PAID_LINK: {
        if (prevState.status === PaymentStateStatus.PENDING)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "PaymentContainer",
            msg: "invalid state change",
            prevState,
          });
        const { payment_res, allow_discount, paid_token } = action;

        if (payment_res.pay_method !== "card") {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: "PaymentContainer",
            msg: "Not support payment",
            prevState,
          });
        }

        let res;

        try {
          res = await issueCard(userToken, {
            ...payment_res,
            allow_discount,
            paid_token: paid_token!,
          });
        } catch (err) {
          console.log(err);
        }

        return {
          status: PaymentStateStatus.SUCCESS,
          payment: res?.response as any,
        };
      }
      case PaymentActionKind.TRY_PERSONAL_PURCHASE: {
        if (prevState.status === PaymentStateStatus.PENDING)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "PaymentContainer",
            msg: "invalid state change",
            prevState,
          });
        const { payment_res, allow_discount, workspace_id } = action;

        let res;

        try {
          res = await issuePersonalCard(userToken, workspace_id, {
            ...payment_res,
            allow_discount,
            paid_token: uuid(),
          });
        } catch (err) {
          console.log(err);
        }

        return {
          status: PaymentStateStatus.SUCCESS,
          payment: res?.response as any,
        };
      }
    }
  }
}

export default connector(PaymentContainer);
