import {
  doAPIDeleteRequest,
  doAPIGetRequest,
  doAPIPostRequest,
  doAPIPutRequest,
} from "./api";
import {
  validateGetAllWorkspaces,
  validateOCreateWorkspace,
  validateOGetWorkspace,
  validateOGetWorkspaceACLList,
  validateOGetWorkspaceMemberAccessLogs,
} from "@goono-commons/v3/response/workspace";
import {
  ChangeWorkspaceOwnerParams,
  CreateWorkspaceACLParams,
  CreateWorkspaceParams,
  InviteWorkspaceMemberParams,
  RemoveWorkspaceACLParams,
  RemoveWorkspaceMemberParams,
  RemoveWorkspaceMembersParams,
  UpdateWorkspaceACLOptionParams,
  UpdateWorkspaceMemberRoleParams,
  UpdateWorkspaceParams,
  WorkspaceACLQueryOptions,
} from "@goono-commons/v3/request/workspace";
import { Workspace } from "@goono-commons/v3/workspace";
import { ICreateTrialWorkspacePlan } from "@goono-commons/v3/request/manager";

export const getAllWorkspaces = async (token: string): Promise<Workspace[]> => {
  const ret = await doAPIGetRequest(`/workspaces/list/all`, token);
  return validateGetAllWorkspaces(ret);
};

export const createWorkspace = async (params: {
  token: string;
  reqBody: CreateWorkspaceParams;
}) => {
  const ret = await doAPIPostRequest(
    `/workspaces/create`,
    params.token,
    params.reqBody
  );
  return validateOCreateWorkspace(ret);
};

export const removeWorkspace = async (token: string, workspace_id: string) => {
  await doAPIDeleteRequest(`/workspaces/${workspace_id}`, token);
};

export const updateWorkspace = async (
  token: string,
  workspace_id: string,
  reqBody: UpdateWorkspaceParams
) => {
  await doAPIPutRequest(`/workspaces/update/${workspace_id}`, token, reqBody);
};

export const inviteWorkspaceMember = async (
  token: string,
  workspace_id: string,
  reqBody: InviteWorkspaceMemberParams
) => {
  await doAPIPostRequest(`/workspaces/invite/${workspace_id}`, token, reqBody);
};

export const removeWorkspaceMember = async (
  token: string,
  workspace_id: string,
  reqBody: RemoveWorkspaceMemberParams
) => {
  await doAPIDeleteRequest(
    `/workspaces/member/${workspace_id}`,
    token,
    reqBody
  );
};

export const removeWorkspaceMembers = async (
  token: string,
  workspace_id: string,
  reqBody: RemoveWorkspaceMembersParams
) => {
  await doAPIDeleteRequest(
    `/workspaces/member/list/${workspace_id}`,
    token,
    reqBody
  );
};

export const changeWorkspaceOwner = async (
  token: string,
  workspace_id: string,
  reqBody: ChangeWorkspaceOwnerParams
) => {
  await doAPIPutRequest(
    `/workspaces/transfer/owner/${workspace_id}`,
    token,
    reqBody
  );
};

export const updateWorkspaceMemberRole = async (
  token: string,
  workspaceId: string,
  reqBody: UpdateWorkspaceMemberRoleParams
) => {
  await doAPIPutRequest(
    `/workspaces/member/update/${workspaceId}`,
    token,
    reqBody
  );
};

export const getWorkspaceACLList = async (
  token: string,
  workspace_id: string
) => {
  const ret = await doAPIGetRequest(`/workspaces/acl/${workspace_id}`, token);
  return validateOGetWorkspaceACLList(ret);
};

export const removeWorkspaceACL = async (
  token: string,
  workspace_id: string,
  reqBody: RemoveWorkspaceACLParams
) => {
  await doAPIDeleteRequest(`/workspaces/acl/${workspace_id}`, token, reqBody);
};

export const createWorkspaceACL = async (
  token: string,
  workspace_id: string,
  reqBody: CreateWorkspaceACLParams
) => {
  await doAPIPostRequest(
    `/workspaces/create/acl/${workspace_id}`,
    token,
    reqBody
  );
};

export const updateWorkspaceACLOption = async (
  token: string,
  workspace_id: string,
  reqBody: UpdateWorkspaceACLOptionParams
) => {
  await doAPIPutRequest(
    `/workspaces/update/acl/${workspace_id}`,
    token,
    reqBody
  );
};

export const getWorkspaceACLLogs = async (
  token: string,
  workspace_id: string,
  reqBody: WorkspaceACLQueryOptions
) => {
  const ret = await doAPIGetRequest(
    `/workspaces/acl/logs/${workspace_id}`,
    token,
    reqBody
  );
  return validateOGetWorkspaceMemberAccessLogs(ret);
};

export const createFreeWorkspacePlan = async (
  token: string,
  workspace_id: string,
  reqBody: ICreateTrialWorkspacePlan
) => {
  await doAPIPostRequest(
    `/workspaces/create/trial/${workspace_id}`,
    token,
    reqBody
  );
};

export const getWorkspace = async (token: string, workspace_id: string) => {
  const ret = await doAPIGetRequest(`/workspaces/${workspace_id}`, token);
  return validateOGetWorkspace(ret);
};
