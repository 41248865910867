import { useEffect, useState } from "react";
import translate from "@translate";
import TranslateHook from "@translate_hook";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";

/** 서비스 언어 변경 시 translate에 반영하는 hook */
export const useLanguageSyncor = () => {
  const [rerenderToggle, setRerenderToggle] = useState(false);
  const persist = useSelector((state: RootState) => state.persist).state;
  const trnaslateWithHook = TranslateHook.useTranslate();

  const syncLanguage = () => {
    if (translate.getLanguage() !== persist.lang) {
      translate.setLanguage(persist.lang);
      trnaslateWithHook.setLanguage(persist.lang);

      /** 언어 변경 시 한 번 전체 UI 리렌더링 */
      setRerenderToggle(!rerenderToggle);
    }
  };

  useEffect(syncLanguage, [persist.lang]);
};
