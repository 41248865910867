export default {
  ko: {
    folder: {
      delete: {
        title: "폴더 삭제",
        body_title: "폴더를 삭제할까요?",
        msg: (file: number) =>
          `삭제하시면 작성된 연구파일 ${file}개 복구가 불가능합니다.\n정말 삭제하시겠어요?`,
        msg_highlight: (file: number) => `연구파일 ${file}개 복구가 불가능`,
        confirm: "폴더 삭제",
      },
      delete_linkage: {
        title: "서비스 연동 해제",
        body_title: "Github 서비스 연동을 정말 해제할까요?",
        msg: `Github 서비스 연동을 해제하시면 자동 인증을 받을 수 없게 됩니다.\n정말 Github 연동을 해제하시겠어요?`,
        msg_highlight: "자동 인증을 받을 수 없게 됩니다.",
        confirm: "연동 해제",
      },
    },
  },
  en: {
    folder: {
      delete: {
        title: "Delete research folder",
        body_title: "Are you sure to delete?",
        msg: (file: number) =>
          `If you delete it, you can't restore ${file} created research files.\nAre you sure to delete?`,
        msg_highlight: (file: number) =>
          `can't restore ${file} created research files.`,
        confirm: "Delete",
      },
      delete_linkage: {
        title: "Disconnect interlocking",
        body_title: "Are you sure to disconnect GitHub?",
        msg: `If you disconnect, automatic authentication will not be enabled.`,
        msg_highlight: "",
        confirm: "Disconnect",
      },
    },
  },
};
