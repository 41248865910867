import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export enum DiaLogStateStatus {
  INIT = "DiaLog::INIT",
  OPENED = "DiaLog::OPENED",
}

export enum DiaLogActionKind {
  TRY_OPEN = "DiaLogAction::TRY_OPEN",
  TRY_CLOSE = "DiaLogAction::TRY_CLOSE",
}

export enum DiaLogType {
  ERROR = "DiaLogType::ERROR",
  WARNING = "DiaLogType::WARNING",
}

export type DiaLogError = never;

export type DiaLogState =
  | {
      readonly status: DiaLogStateStatus.INIT;
    }
  | {
      readonly status: DiaLogStateStatus.OPENED;
      readonly type: DiaLogType;
      readonly title?: string;
      readonly bodyMsg?: string;
      readonly bodyNode?: React.ReactNode;
      readonly confirmBtnMsg?: string;
      readonly cancelBtnMsg?: string;
      readonly iconColor?: string;
      readonly iconNode?: React.ReactNode;
      readonly onCancel?: () => void;
      readonly onConfirm?: () => void;
    };

export type DiaLogAction =
  | {
      readonly kind: DiaLogActionKind.TRY_OPEN;
      readonly type: DiaLogType;
      readonly title?: string;
      readonly bodyMsg?: string;
      readonly bodyNode?: React.ReactNode;
      readonly confirmBtnMsg?: string;
      readonly cancelBtnMsg?: string;
      readonly iconColor?: string;
      readonly iconNode?: React.ReactNode;
      readonly onCancel?: () => void;
      readonly onConfirm?: () => void;
    }
  | {
      readonly kind: DiaLogActionKind.TRY_CLOSE;
    };

const smid = "DIALOG_STATE_MACHINE3";
export type DiaLogStateMachineType = StateMachine3<
  DiaLogStateStatus,
  DiaLogState,
  DiaLogActionKind,
  DiaLogAction,
  DiaLogError
>;
export const DiaLogStateMachine: DiaLogStateMachineType = new StateMachine3<
  DiaLogStateStatus,
  DiaLogState,
  DiaLogActionKind,
  DiaLogAction,
  DiaLogError
>(smid, { status: DiaLogStateStatus.INIT }, [
  transition(
    DiaLogStateStatus.INIT,
    DiaLogStateStatus.OPENED,
    DiaLogActionKind.TRY_OPEN
  ),
  transition(
    DiaLogStateStatus.OPENED,
    DiaLogStateStatus.OPENED,
    DiaLogActionKind.TRY_OPEN
  ),
  transition(
    DiaLogStateStatus.OPENED,
    DiaLogStateStatus.INIT,
    DiaLogActionKind.TRY_CLOSE
  ),
  transition(
    DiaLogStateStatus.INIT,
    DiaLogStateStatus.INIT,
    DiaLogActionKind.TRY_CLOSE
  ),
]);

/* */
export type DispatchDialogAction = Dispatch<
  StateMachineAction<
    DiaLogStateStatus,
    DiaLogState,
    DiaLogActionKind,
    DiaLogAction,
    DiaLogError
  >
>;
export default mkReducer<
  DiaLogStateStatus,
  DiaLogState,
  DiaLogActionKind,
  DiaLogAction,
  DiaLogError
>(DiaLogStateMachine);
export const doDiaLogAction = (
  dispatch: DispatchDialogAction,
  nextAction: DiaLogAction,
  onResolve: () => void = () => {},
  onReject: (err: DiaLogError | InternalError) => void = () => {}
) => {
  dispatch(DiaLogStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doDiaLogActionAsync = (
  dispatch: DispatchDialogAction,
  nextAction: DiaLogAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(DiaLogStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetDialogbar = (dispatch: DispatchDialogAction) => {
  dispatch(DiaLogStateMachine.newResetAction());
};
