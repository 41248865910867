import { Container } from "@material-ui/core";
import React from "react";
import { SkeletonBox } from "../public/skeleton/SkeletonBox";

export default function ResearchNoteDashboardScreenSkeletonView() {
  return (
    <Container style={{ paddingTop: 50 }}>
      <SkeletonBox style={{ width: 300, height: 50 }} />
      <SkeletonBox style={{ width: 150, height: 30, marginTop: 40 }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 20,
        }}
      >
        {Array.from({ length: 4 }).map((_, idx) => (
          <SkeletonBox
            style={{ width: idx === 3 ? `29%` : `22%`, height: 150 }}
            key={idx}
          />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 50,
        }}
      >
        {Array.from({ length: 2 }).map((_, idx) => (
          <div style={{ width: `50%` }} key={idx}>
            <SkeletonBox style={{ width: 150, height: 30 }} />
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 20,
        }}
      >
        {Array.from({ length: 2 }).map((_, idx) => (
          <SkeletonBox style={{ width: `49%`, height: 350 }} key={idx} />
        ))}
      </div>
    </Container>
  );
}
