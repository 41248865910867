import React from "react";
import Flex from "@ui/goono/components/layouts/Flex";
import { ButtonBase } from "@material-ui/core";
import useLandingHomeStyles from "../styles";
import clsx from "clsx";
import { GoonoService } from "../hooks/useCurrentViewController";
import { LandingHomeScreenContext } from "../";
import { useContext } from "react";
import { ReactComponent as NoteIcon } from "@assets/images/workspace/gnb/note.svg";
import { ReactComponent as StrongBoxIcon } from "@assets/images/public/icon/strong-box.svg";
import translate from "@translate";

export default function ServiceSwitch() {
  const classes = useLandingHomeStyles();
  const { currentViewController } = useContext(LandingHomeScreenContext);

  return (
    <Flex container className={clsx(classes.service_switch)}>
      {["research-note", "data-room"].map((item) => (
        <ButtonBase
          key={item}
          className={clsx("switch-button", {
            "active-button": currentViewController.currentView === item,
          })}
          onClick={() =>
            currentViewController.setCurrentView(item as GoonoService)
          }
        >
          {item === "research-note" ? <NoteIcon /> : <StrongBoxIcon />}
          {item === "research-note"
            ? translate.landing_home.function_tab.research_note
            : translate.landing_home.function_tab.data_room}
        </ButtonBase>
      ))}
    </Flex>
  );
}
