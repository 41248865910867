import React from "react";
import { Box, Container } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import TextHighlight from "@ui/goono/components/typo/TextHighlight";
import useLandingHomeStyles from "../styles";
import ServiceSwitch from "./ServiceSwitch";
import translate from "@translate";

export default function ServiceTabTitleSection() {
  const classes = useLandingHomeStyles();

  return (
    <Box className={classes.tab_title_section}>
      <Container className={classes.container}>
        <GoonoTypography className="tab-title" keepAll>
          <TextHighlight
            value={translate.landing_home.function_tab.title}
            highlightStyleMap={
              new Map([
                [
                  translate.landing_home.function_tab.title_highlight,
                  { textcolor: "primary" },
                ],
              ])
            }
          />
        </GoonoTypography>
        <GoonoTypography
          type="b1_r"
          textcolor="text"
          keepAll
          children={translate.landing_home.function_tab.desc}
        />
        <ServiceSwitch />
      </Container>
    </Box>
  );
}
