import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  DiaLogActionKind,
  DiaLogAction,
  DiaLogStateStatus,
  DiaLogStateMachine,
  DiaLogStateMachineType,
  DiaLogState,
} from "../store/reducers/dialog";
import { omit } from "lodash";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.dialog,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type DialogContainerProps = PropsFromRedux & {
  stateMachine: DiaLogStateMachineType;
};

export const handleDialogAction = (
  _prevState: DiaLogState,
  action: DiaLogAction
): DiaLogState => {
  switch (action.kind) {
    case DiaLogActionKind.TRY_OPEN: {
      return {
        status: DiaLogStateStatus.OPENED,
        ...omit(action, "kind"),
      };
    }
    case DiaLogActionKind.TRY_CLOSE: {
      return {
        status: DiaLogStateStatus.INIT,
      };
    }
  }
};

class DialogContainer extends ReduxStateComponent3<DialogContainerProps> {
  static defaultProps = { stateMachine: DiaLogStateMachine };
  constructor(props: DialogContainerProps) {
    super(props);
  }

  protected onAction = handleDialogAction;
}

export default connector(DialogContainer);
