import { useEffect, useState } from "react";
import { BaseMobileGuide } from "./styles/BaseGrid";

// callback function을 인자로 받습니다 (BaseGrid에서 사용할 때 width, height를 screen 단에 넘겨주기 위함)
export const WindowSizeHandler = (
  callback?: (w: number, h: number, m?: boolean) => void
) => {
  const mobileWidth = BaseMobileGuide; // mobile 기준 width, 모바일 가이드를 바꾸고 싶으면 여기를 수정하세요
  let unmounted = false;
  let WindowWidth: number = window.innerWidth; // 현재 스크린의 가로 길이
  let WindowHeight: number = window.innerHeight; // 현재 스크린의 세로 길이
  let MobileMode: boolean = window.innerWidth < mobileWidth ? true : false; // mobileMode에서의 view 상태

  const [timer, setTimer] = useState(Date.now());
  const windowResizeHandler = (_evt: UIEvent) => {
    if (unmounted === true) return;
    const currWidth = window.innerWidth;
    const currHeight = window.innerHeight;
    const currMobileMode = window.innerWidth < mobileWidth ? true : false;

    if (currWidth !== WindowWidth) {
      WindowWidth = currWidth;
    }

    if (currHeight !== WindowHeight) {
      WindowHeight = currHeight;
    }

    if (currMobileMode !== MobileMode) {
      MobileMode = currMobileMode;
    }
    if (callback !== undefined) {
      callback(currWidth, currHeight, MobileMode);
    }
    setTimeout(() => {
      setTimer(Date.now());
    }, 300);
    return;
  };

  useEffect(() => {
    window.addEventListener("resize", (evt: UIEvent) =>
      windowResizeHandler(evt)
    );
    return () => {
      window.removeEventListener(
        "resize",
        (evt: UIEvent) => windowResizeHandler(evt),
        true
      );
      unmounted = true;
    };
  }, [timer]);

  const isMobile = () => MobileMode;
  const getWindowWidth = () => WindowWidth;
  const getWindowHeight = () => WindowHeight;

  return {
    isMobile,
    getWindowWidth,
    getWindowHeight,
  };
};
