import React from "react";
import { Box, Container } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useLandingHomeStyles from "../styles";
import PartnerRolling from "src/components/public/lists/SliderList";
import TextHighlight from "@ui/goono/components/typo/TextHighlight";
import translate from "@translate";

export default function MainPartnerSection() {
  const classes = useLandingHomeStyles();

  return (
    <Box className={classes.partners_section} component="section">
      <Container className={classes.container}>
        <GoonoTypography
          type="h2"
          keepAll
          className={classes.partners_section_title}
        >
          {translate.landing_home.partner.title}
        </GoonoTypography>
        <GoonoTypography keepAll className={classes.partners_section_sub_title}>
          <TextHighlight
            value={translate.landing_home.partner.sub_title("1,000")}
            highlightStyleMap={new Map([["1,000", { textcolor: "primary" }]])}
          />
        </GoonoTypography>
      </Container>
      <Box className={classes.partners_section_slide}>
        <PartnerRolling />
      </Box>
    </Box>
  );
}
