import { Dispatch } from "redux";
import { DEFAULT_LANG } from "@translate";
import {
  StateMachine3,
  StateMachineAction,
  mkReducer,
  transition,
} from "@redwit-react-commons/reducers/state3";
import { InternalError } from "@redwit-commons/utils/exception2";
import { Moment } from "moment";

export type UTMArguments = {
  location?: string;
  history?: string;
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
};

export enum PersistStateStatus {
  SUCCESS = "PersistStateStates::SUCCESS",
}

export enum PersistActionKind {
  TOGGLE_LANG = "PersistAction::TOGGLE_LANG",
  UPDATE_UTM_LOGS = "PersistAction::UPDATE_UTM_LOGS",
  SET_LANDING_TOP_BANNER_LAST_CLOSED = "PersistAction::SET_LANDING_TOP_BANNER_LAST_CLOSED",
  UPDATE_LAST_PASSWORD_CHANGE_INFO = "PersistAction::UPDATE_LAST_PASSWORD_CHANGE_INFO",
  UPDATE_DO_NOT_NEED_ONBOARDING_USER_LIST = "PersistAction::UPDATE_DO_NOT_NEED_ONBOARDING_USER_LIST",
  UPDATE_SESSION_ID = "PersistActionKind::UPDATE_SESSION_ID",
  UPDATE_HAS_DATAROOM_SURVEY = "PersistActionKind::UPDATE_HAS_DATAROOM_SURVEY",
}

export type PersistError = never;

export type LastPasswordChangeInfo = {
  userId: string;
  /** 비밀번호 최근 업데이트 날짜 */
  updatedAt: Moment;
  /**
   * 비밀번호 변경 다음에 하기 선택한 경우 : 30일 이후에 다시 알림
   * undefined인 경우 아직 비밀번호 변경 무시하지 않은 사람
   *  */
  ignoreInfo:
    | undefined
    /** 비밀번호 변경 무시한 날짜 기록 */
    | {
        createdAt: Moment;
      };
};

export type PersistState = {
  readonly status: PersistStateStatus.SUCCESS;
  readonly lang: string;
  readonly utm_args?: UTMArguments;
  readonly landding_top_banner_last_closed?: string;
  readonly last_password_change_infos?: LastPasswordChangeInfo[];
  readonly do_not_need_onboarding_user_list: { email: string }[];
  /** 컴퓨터 별로 하나씩 박아넣는 session id */
  readonly sessionId: string | undefined;
  /** 데이터룸 관련 설문 참여 여부 */
  readonly hasDataroomSurvey: boolean;
};

export type PersistAction =
  | {
      readonly kind: PersistActionKind.TOGGLE_LANG;
    }
  | {
      readonly kind: PersistActionKind.UPDATE_UTM_LOGS;
      readonly utm_args: UTMArguments;
    }
  | {
      readonly kind: PersistActionKind.SET_LANDING_TOP_BANNER_LAST_CLOSED;
    }
  | {
      readonly kind: PersistActionKind.UPDATE_LAST_PASSWORD_CHANGE_INFO;
      readonly info: LastPasswordChangeInfo;
    }
  | {
      readonly kind: PersistActionKind.UPDATE_DO_NOT_NEED_ONBOARDING_USER_LIST;
      readonly user_email: string;
    }
  | {
      readonly kind: PersistActionKind.UPDATE_SESSION_ID;
      readonly sesionId: string;
    }
  | {
      readonly kind: PersistActionKind.UPDATE_HAS_DATAROOM_SURVEY;
      readonly value: boolean;
    };

const smid = "PERSIST_STATE_MACHINE3";

export type PersistStateMachineType = StateMachine3<
  PersistStateStatus,
  PersistState,
  PersistActionKind,
  PersistAction,
  PersistError
>;

export const persistStateMachine: PersistStateMachineType = new StateMachine3<
  PersistStateStatus,
  PersistState,
  PersistActionKind,
  PersistAction,
  PersistError
>(
  smid,
  {
    status: PersistStateStatus.SUCCESS,
    lang: DEFAULT_LANG,
    utm_args: undefined,
    sessionId: undefined,
    landding_top_banner_last_closed: undefined,
    last_password_change_infos: undefined,
    do_not_need_onboarding_user_list: [],
    hasDataroomSurvey: false,
  },
  [
    transition(
      PersistStateStatus.SUCCESS,
      PersistStateStatus.SUCCESS,
      PersistActionKind.SET_LANDING_TOP_BANNER_LAST_CLOSED
    ),
    transition(
      PersistStateStatus.SUCCESS,
      PersistStateStatus.SUCCESS,
      PersistActionKind.TOGGLE_LANG
    ),
    transition(
      PersistStateStatus.SUCCESS,
      PersistStateStatus.SUCCESS,
      PersistActionKind.UPDATE_UTM_LOGS
    ),
    transition(
      PersistStateStatus.SUCCESS,
      PersistStateStatus.SUCCESS,
      PersistActionKind.UPDATE_LAST_PASSWORD_CHANGE_INFO
    ),
    transition(
      PersistStateStatus.SUCCESS,
      PersistStateStatus.SUCCESS,
      PersistActionKind.UPDATE_DO_NOT_NEED_ONBOARDING_USER_LIST
    ),
    transition(
      PersistStateStatus.SUCCESS,
      PersistStateStatus.SUCCESS,
      PersistActionKind.UPDATE_SESSION_ID
    ),
    transition(
      PersistStateStatus.SUCCESS,
      PersistStateStatus.SUCCESS,
      PersistActionKind.UPDATE_HAS_DATAROOM_SURVEY
    ),
  ]
);

export type DispatchPersistAction = Dispatch<
  StateMachineAction<
    PersistStateStatus,
    PersistState,
    PersistActionKind,
    PersistAction,
    PersistError
  >
>;

export default mkReducer<
  PersistStateStatus,
  PersistState,
  PersistActionKind,
  PersistAction,
  PersistError
>(persistStateMachine);

export const doPersistAction = (
  dispatch: DispatchPersistAction,
  nextAction: PersistAction,
  onResolve: () => void = () => {},
  onReject: (err: PersistError | InternalError) => void = () => {}
) => {
  dispatch(persistStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doPersistActionAsync = (
  dispatch: DispatchPersistAction,
  nextAction: PersistAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(persistStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetPersistStore = (dispatch: DispatchPersistAction) => {
  dispatch(persistStateMachine.newResetAction());
};
