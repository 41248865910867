import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { DocumentBox } from "@goono-commons/dataRoom/documentBox";

export enum SidebarStateStatus {
  INIT = "SidebarStateStatus::INIT",
}

export enum SidebarActionKind {
  UPDATE_DATAROOM_SIDEBAR_STATE = "SidebarActionKind::UPDATE_DATAROOM_SIDEBAR_STATE",
}

type DataRoomSidebarState = {
  readonly isOpenPinProjectList: boolean;
  readonly isOpenRecentFileList: boolean;
  readonly opendDocumentBoxList: DocumentBox[];
};

export type SidebarState = {
  readonly status: SidebarStateStatus.INIT;
  readonly dataRoomSidebar: DataRoomSidebarState;
};

export type SidebarAction = {
  readonly kind: SidebarActionKind.UPDATE_DATAROOM_SIDEBAR_STATE;
  readonly newState: Partial<DataRoomSidebarState>;
};

export type FileError = never;

const smid = "SIDEBAR_STATE_MACHINE3";

export type SidebarStateMachineType = StateMachine3<
  SidebarStateStatus,
  SidebarState,
  SidebarActionKind,
  SidebarAction,
  FileError
>;

export const sidebarStateMachine: SidebarStateMachineType = new StateMachine3<
  SidebarStateStatus,
  SidebarState,
  SidebarActionKind,
  SidebarAction,
  FileError
>(
  smid,
  {
    status: SidebarStateStatus.INIT,
    dataRoomSidebar: {
      isOpenPinProjectList: false,
      isOpenRecentFileList: false,
      opendDocumentBoxList: [],
    },
  },
  [
    transition(
      SidebarStateStatus.INIT,
      SidebarStateStatus.INIT,
      SidebarActionKind.UPDATE_DATAROOM_SIDEBAR_STATE
    ),
  ]
);

export type DispatchSidebarAction = Dispatch<
  StateMachineAction<
    SidebarStateStatus,
    SidebarState,
    SidebarActionKind,
    SidebarAction,
    FileError
  >
>;

export default mkReducer<
  SidebarStateStatus,
  SidebarState,
  SidebarActionKind,
  SidebarAction,
  FileError
>(sidebarStateMachine);

export const doSidebarAction = (
  dispatch: DispatchSidebarAction,
  nextAction: SidebarAction,
  onResolve: () => void = () => {},
  onReject: (err: FileError | InternalError) => void = () => {}
) => {
  dispatch(sidebarStateMachine.newTryAction(nextAction, onResolve, onReject));
};

export const doSidebarActionAsync = (
  dispatch: DispatchSidebarAction,
  nextAction: SidebarAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(sidebarStateMachine.newTryAction(nextAction, resolve, reject));
  });
};

export const resetFile = (dispatch: DispatchSidebarAction) => {
  dispatch(sidebarStateMachine.newResetAction());
};
