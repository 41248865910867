import React from "react";
import { Box, Container, List, ListItem } from "@material-ui/core";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useLandingHomeStyles from "../styles";
import Iso9001Img from "@assets/images/public/screen/landingHomeScreen/certified-mark/img-iso9001.png";
import Iso27001Img from "@assets/images/public/screen/landingHomeScreen/certified-mark/img-iso27001.png";
import CrosscertImg from "@assets/images/public/screen/landingHomeScreen/certified-mark/img-crosscert.png";
import GoodswImg from "@assets/images/public/screen/landingHomeScreen/certified-mark/img-goodsoftware.png";
import FdaImg from "@assets/images/public/screen/landingHomeScreen/certified-mark/img-fda.png";

import translate from "@translate";

type CertificationMarkItem = {
  imgSrc: string;
  name: string;
  desc: string;
};

export default function MainCertificationSection() {
  const classes = useLandingHomeStyles();

  const CertificationMarkList: CertificationMarkItem[] = [
    {
      imgSrc: Iso9001Img,
      name: translate.landing_home.certification.mark_list.iso9001,
      desc: translate.landing_home.certification.mark_list.iso9001_desc,
    },
    {
      imgSrc: Iso27001Img,
      name: translate.landing_home.certification.mark_list.iso27001,
      desc: translate.landing_home.certification.mark_list.iso27001_desc,
    },
    {
      imgSrc: CrosscertImg,
      name: translate.landing_home.certification.mark_list.crosscert,
      desc: translate.landing_home.certification.mark_list.crosscert_desc,
    },
    {
      imgSrc: GoodswImg,
      name: translate.landing_home.certification.mark_list.good_sw,
      desc: translate.landing_home.certification.mark_list.good_sw_desc,
    },
    {
      imgSrc: FdaImg,
      name: translate.landing_home.certification.mark_list.fda,
      desc: translate.landing_home.certification.mark_list.fda_desc,
    },
  ];

  const renderTitle = () => {
    return (
      <GoonoTypography
        component="h3"
        keepAll
        className={classes.certification_section_title}
      >
        <span>{translate.landing_home.certification.title_highlight}</span>{" "}
        {translate.landing_home.certification.title}
      </GoonoTypography>
    );
  };

  const renderMakList = () => {
    return (
      <List className={classes.certification_mark_list}>
        {CertificationMarkList.map((item) => {
          return <CertificationMarkListItem key={item.name} {...item} />;
        })}
      </List>
    );
  };
  return (
    <Box component="section" className={classes.certification_section}>
      <Container className={classes.container}>
        {renderTitle()}
        {renderMakList()}
      </Container>
    </Box>
  );
}

function CertificationMarkListItem({
  imgSrc,
  name,
  desc,
}: CertificationMarkItem) {
  const classes = useLandingHomeStyles();
  return (
    <ListItem className={classes.certification_mark_list_item}>
      <img src={imgSrc} alt={name} />
      <GoonoTypography className="mark-name" keepAll>
        {name}
      </GoonoTypography>
      <GoonoTypography className="mark-desc" keepAll>
        {desc}
      </GoonoTypography>
    </ListItem>
  );
}
