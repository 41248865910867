import React from "react";
import GoonoModalManager from "./GoonoModalManager";
import GoonoDialogManager from "./GoonoDialogManager";
import GoonoSnackbarManager from "./GoonoSnackbarManager";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";
import { DataRoomTutorialStateStatus } from "src/redux/store/reducers/dataRoomTutorial";
import loadable from "@loadable/component";

const DataRoomTutorialManagerLazy = loadable(
  () => import(`./DataRoomTutorialManager`)
);

export default function GlobalComponentManager() {
  const dataRoomTutorialState = useSelector(
    (state: RootState) => state.dataRoomTutorial
  ).state;

  const isActiveDataroomTutorial =
    dataRoomTutorialState.status === DataRoomTutorialStateStatus.ON;

  React.useEffect(() => {
    document.body.style.overflowY = isActiveDataroomTutorial
      ? "hidden"
      : "unset";

    if (!isActiveDataroomTutorial) return;

    const interval = setInterval(() => {
      if (document.body.style.overflowY !== "hidden")
        document.body.style.overflowY = "hidden";
    }, 500);

    return () => clearInterval(interval);
  }, [dataRoomTutorialState.status]);

  return (
    <React.Fragment>
      <GoonoModalManager />
      <GoonoDialogManager />
      <GoonoSnackbarManager />
      {isActiveDataroomTutorial && <DataRoomTutorialManagerLazy />}
    </React.Fragment>
  );
}
