import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  tokenStateMachine,
  TokenStateMachineType,
  TokenState,
  TokenAction,
  TokenActionKind,
  TokenStateStatus,
} from "src/redux/store/reducers/token";
import { omit } from "lodash";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TokenContainerProps = PropsFromRedux & {
  stateMachine: TokenStateMachineType;
};

export const handleTokenAction = (
  prevState: TokenState,
  action: TokenAction
): TokenState => {
  switch (action.kind) {
    case TokenActionKind.TRY_SAVE_USER_INFO: {
      return {
        status: TokenStateStatus.SUCCESS,
        ...omit(action, "kind"),
      };
    }

    case TokenActionKind.TRY_UPDATE_PROFILE_INFO: {
      if (prevState.status !== TokenStateStatus.SUCCESS) return prevState;
      return {
        ...prevState,
        ...action.userInfo,
      };
    }

    case TokenActionKind.TRY_SAVE_SNS_SIGN_UP_INFO: {
      return {
        status: TokenStateStatus.SUCCESS_SNS_SIGN_UP,
        args: action.args,
      };
    }

    case TokenActionKind.TRY_SAVE_PW_TOKEN: {
      const token = action.pwToken;
      return {
        status: TokenStateStatus.READY_UPDATE_PW,
        pwToken: token,
      };
    }

    case TokenActionKind.TRY_UPDATE_PW: {
      return { status: TokenStateStatus.INIT };
    }

    case TokenActionKind.TRY_INIT_TOKEN: {
      return { status: TokenStateStatus.INIT };
    }

    default:
      return prevState;
  }
};

class TokenContainer extends ReduxStateComponent3<TokenContainerProps> {
  static defaultProps = {
    stateMachine: tokenStateMachine,
  };

  constructor(props: TokenContainerProps) {
    super(props);
  }

  protected onAction = handleTokenAction;
}

export default connector(TokenContainer);
