import TagManager from "react-gtm-module";
import { SNSPlatform } from "@goono-commons/api/object/user";

type EventNameType =
  | "click_cta"
  | "join_group"
  | "select_content"
  | "sign_up"
  | "tutorial_begin";

type BannerArgumentsType = "upper_line" | "mobile_upper_line";

type ButtonArgumentsType =
  | "home_trial"
  | "mobile_home_trial"
  | "home_ask"
  | "mobile_home_ask"
  | "service_download"
  | "mobile_service_download"
  | "price_team"
  | "mobile_price_team"
  | "price_education"
  | "mobile_price_education"
  | "category_ask"
  | "mobile_category_ask"
  | "category_login"
  | "mobile_category_login"
  | "category_signup"
  | "mobile_category_signup"
  | "home_sample"
  | "mobile_home_sample"
  | "note_sample"
  | "mobile_note_sample"
  | "profile_ask"
  | "dot_ask";

interface UseDataLayerProps {
  event: EventNameType;
  banner?: BannerArgumentsType;
  button?: ButtonArgumentsType;
  method?: SNSPlatform;
}

export default function EventCreator(params: UseDataLayerProps) {
  const isTestServer = process.env.REACT_APP_TEST_ENABLED === "1";

  if (!isTestServer) {
    // 필드가 정의되어 있을 때만 해당 필드를 추가하도록 설정 (banner || button || method)
    const dataLayerObject = {
      event: params.event,
      ...(params.button ? { button: params.button } : {}),
      ...(params.banner ? { banner: params.banner } : {}),
      ...(params.method ? { method: params.method } : {}),
    };

    TagManager.dataLayer({
      dataLayer: dataLayerObject,
    });
  }
}
