export default {
  ko: {
    form: {
      agree_all: "모두 동의합니다",
      agreementList: {
        title1: "구노 서비스 이용약관 동의 (필수)",
        title2: "개인정보 수집 및 이용에 관한 동의 (필수)",
        title3: "개인정보 제3자 제공에 관한 동의 (필수)",
        title4: "홍보 및 마케팅을 위한 개인정보 동의 (선택)",
      },
      view: "보기",
      pricing: {
        person: "인원수",
        count: "명",
        period: "기간",
        month: "개월",
      },
      purchase_info: {
        common: {
          title: "공통",
          sub: "취소 / 반품 신청 및 자세한 사항은 문의를 통해 확인하실 수 있습니다.",
        },
        credit_card: {
          title: "신용카드",
          sub1: "결제는 구노의 결제 및 환불 기준을 바탕으로 처리되며 카드 정보는 서버에\n저장되지 않습니다.",
          sub2: "갱신일마다 자동으로 결제가 진행되며 플랜은 언제든지 해지가\n가능합니다.",
        },
        bankbook: {
          title: "무통장 입금",
          sub1: "주문 후 영업일 7일 이내 입금 확인이 되지 않으면 주문이 자동 취소될 수\n있습니다.",
          sub2: "해외은행 계좌에서 입금 시에는 반드시 원화로 입금하셔야 합니다.",
        },
      },
    },
  },
  en: {
    form: {
      agree_all: "Accept All",
      agreementList: {
        title1: "Accept Terms of Service (Required)",
        title2: "Personal Data Collection and Usage Consent (Required)",
        title3: "Accept Terms of Personal Information Collection (Required)",
        title4:
          "Accept Terms of Personal Information Use for Advertisement and Marketing (Optional)",
      },
      view: "View",
      pricing: {
        person: "per",
        count: "per",
        period: "period",
        month: "mon",
      },
      purchase_info: {
        common: {
          title: "Common",
          sub: "You can check the details such as cancellation / request for return through request.",
        },
        credit_card: {
          title: "Credit Card",
          sub1: "Payment will be processed based on GOONO's payment and refund policy and the card information will not be stored in server.",
          sub2: "Payment will proceed automatically every update date and the plan can be unlocked always.",
        },
        bankbook: {
          title: "Deposit without Passbook",
          sub1: "Your order can be automatically cancelled if the payment is not confirmed within 7 operation days after order.",
          sub2: "If you are using abroad bank account you have to deposit in Korean won.",
        },
      },
    },
  },
};
