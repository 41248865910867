import { SortingQueryParams } from "@goono-commons/v3/sortingFilter";

/**
 * 정렬 필터 옵션에 대한 enum 타입을 정의
 */
export enum SortingFilterOptions {
  CREATED_AT_ASC = "createdAt_asc",
  CREATED_AT_DESC = "createdAt_desc",
  NAME_ASC = "name_asc",
  NAME_DESC = "name_desc",
}

/**
 * value[0]: sort & value[1]: order
 */
export const parseSortingOption = (
  value?: SortingFilterOptions
): SortingQueryParams => {
  if (value === undefined) return { sort: "createdAt", order: "DESC" };
  switch (value) {
    case SortingFilterOptions.CREATED_AT_ASC:
      return { sort: "createdAt", order: "ASC" };
    case SortingFilterOptions.CREATED_AT_DESC:
      return { sort: "createdAt", order: "DESC" };
    case SortingFilterOptions.NAME_ASC:
      return { sort: "name", order: "ASC" };
    case SortingFilterOptions.NAME_DESC:
      return { sort: "name", order: "DESC" };
  }
};
