import React from "react";
import {
  typoType,
  textColor,
  textColorValues,
} from "@ui/goono/components/typo/GoonoTypography";
import useStyles from "@ui/goono/components/typo/styles/GoonoTypography";
import clsx from "clsx";
import { gds_theme } from "@ui/goono/styles/theme";

export type TypoStyle = {
  type?: typoType;
  textcolor?: string;
  background?: string;
};

export type KeywordStyleMap = Map<string, TypoStyle>;

type TextHighlightProps = {
  value: string;
  /**
   * @param keywordStyleMap [highlightText, {type: typoType, textcolor: textColor}]
   * @param highlightText string
   * @param TypoStyle GoonoTypography typoType props
   * @param textcolor GoonoTypography textColor props
   */
  highlightStyleMap: KeywordStyleMap;
};

export default function TextHighlight(props: TextHighlightProps) {
  const { value, highlightStyleMap } = props;
  const classes = useStyles();

  const getTypeClass = (type?: typoType) => {
    switch (type) {
      case "h1":
        return [classes.h1];
      case "h2":
        return [classes.h2];
      case "h3":
        return [classes.h3];
      case "h4":
        return [classes.h4];
      case "h5":
        return [classes.h5];
      case "b1_r":
        return [classes.b1_r];
      case "b1_m":
        return [classes.b1_m];
      case "b1_b":
        return [classes.b1_b];
      case "b2_r":
        return [classes.b2_r];
      case "b2_m":
        return [classes.b2_m];
      case "b2_b":
        return [classes.b2_b];
      case "b3_r":
        return [classes.b3_r];
      case "b3_m":
        return [classes.b3_m];
      case "b3_b":
        return [classes.b3_b];
      case "b4_r":
        return [classes.b4_r];
      case "b4_m":
        return [classes.b4_m];
      case "b4_b":
        return [classes.b4_b];
      case "ele1_m":
        return [classes.ele1_m];
      case "ele1_b":
        return [classes.ele1_b];
      case "ele2_m":
        return [classes.ele2_m];
      case "ele2_b":
        return [classes.ele2_b];
      case "ele3_m":
        return [classes.ele3_m];
      case "ele3_b":
        return [classes.ele3_b];
      case "caption1_r":
        return [classes.caption1_r];
      case "caption1_m":
        return [classes.caption1_m];
      case "caption1_b":
        return [classes.caption1_b];
      case "caption2_r":
        return [classes.caption2_r];
      case "caption2_m":
        return [classes.caption2_m];
      case "caption2_b":
        return [classes.caption2_b];
      default:
        return [];
    }
  };

  const getColorClass = (textcolor?: string) => {
    if (
      typeof textcolor === "string" &&
      !textColorValues.includes(textcolor as textColor)
    ) {
      return null; // string이면서 textColor 타입에 없는 경우 null 반환
    }

    switch (textcolor) {
      case "primary":
        return [classes.primary];
      case "secondary":
        return [classes.secondary];
      case "text":
        return [classes.text];
      case "cool_text":
        return [classes.cool_text];
      case "title":
        return [classes.title];
      case "error":
        return [classes.error];
      case "disabled":
        return [classes.disabled];
      case "white":
        return [classes.white];
      case "primary_light":
        return [classes.primary_light];
      default:
        return [];
    }
  };

  const escapeRegExp = (string: string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $&는 일치하는 전체 문자열을 나타냅니다.
  };

  const keywordList = [...highlightStyleMap.keys()].map(escapeRegExp).join("|");
  const parts = value.split(new RegExp(`(${keywordList})`, "gi"));

  return (
    <>
      {parts.map((part, index) => {
        const highlightStyle = highlightStyleMap.get(part);
        const typeClass = getTypeClass(highlightStyle?.type);
        const colorClass = getColorClass(highlightStyle?.textcolor);

        return highlightStyle ? (
          <span
            key={index}
            className={clsx(typeClass, colorClass)}
            style={{
              background: highlightStyle?.background,
              borderRadius: gds_theme.round_2,
              color: colorClass === null ? highlightStyle.textcolor : undefined,
            }}
          >
            {part}
          </span>
        ) : (
          part
        );
      })}
    </>
  );
}
