export default {
  ko: {
    tutorial: {
      alert: {
        end: `튜토리얼을 종료하고 대시보드로 \n 돌아갈까요?`,
        complete: "튜토리얼 완료!",
        complete_text:
          "튜토리얼은 대시보드 화면을 통해 언제든지 다시 볼 수 있어요.",
      },
      buttons: {
        next: "다음",
        skip: "건너뛰기",
        prev: "이전",
        cancel: "취소",
        end: "종료",
        confirm: "완료",
        complete: "튜토리얼 완료",
      },
      data_room: {
        purpose: {
          title: "스트롱박스 사용목적을 선택해주세요.",
          placeholder: "사용목적을 입력해주세요.",
          options: {
            option1: "투자유치",
            option2: "기업간 협력",
            option3: "아이디어 및 기술보호",
            option4: "기타",
          },
        },
        job: {
          title: "직무를 선택해주세요.",
          placeholder: "직무를 입력해주세요.",
          options: {
            option1: `연구원 - 책임급`,
            option2: `연구원 - 일반`,
            option3: `인사 및 재무관리`,
            option4: `프로젝트 매니저`,
            option5: `C - Level 임원진`,
            option6: `기타`,
          },
        },
        index1: "워크스페이스 내 여러 개의 \n 스트롱박스를 생성할 수 있어요.",
        index2: `스트롱박스 내 여러 개의 폴더를\n 생성할 수 있어요.`,
        index3: `한 폴더 안에  여러 개의 파일을 \n 올릴 수 있어요.`,
        index4: `파일 별로 링크를 만들고 공유하면 \n 누구나 파일을 열람할 수 있어요.`,
        index5: {
          step1: {
            title: "Step1 : 링크이름",
            desc: "공유 목적과 대상에 따라 링크의 이름을 \n 지정할 수 있어요.",
          },
          step2: {
            title: "Step2 : 유효기간",
            desc: "기간 이후 링크는 자동으로 만료되어\n 접근이 불가능해요.",
          },
          step3: {
            title: "Step3 : 공유옵션",
            desc: "링크를 열람하는 사람의 이메일을 수집하거나, \n 다운로드 가능 여부를 설정할 수 있어요.",
          },
          step4: {
            title: "Step4 : NDA 동의",
            desc: "중요한 내용의 파일은 기밀 유지 서약 동의를\n 받은 후 열람하도록 설정할 수 있어요.",
          },
          step5: {
            title: "Step5 : 링크생성",
            desc: "선택한 설정값대로 링크를 생성해보세요.",
          },
        },
        index6: `스트롱박스 유저가 아닌 사람에게도 \n 링크를 공유할 수 있어요.`,
        index7: `방문 기록과 체류시간을 \n 확인할 수 있어요.`,
        index8: `다운로드 수, 페이지별 체류시간 \n 분석을 통해 파일 내용을 \n 개선할 수 있어요.`,
      },
    },
  },

  en: {
    tutorial: {
      alert: {
        end: `튜토리얼을 종료하고 대시보드로 \n 돌아갈까요?`,
        complete: "튜토리얼 완료!",
        complete_text:
          "튜토리얼은 대시보드 화면을 통해 언제든지 다시 볼 수 있어요.",
      },
      buttons: {
        next: "다음",
        skip: "건너뛰기",
        prev: "이전",
        cancel: "취소",
        end: "종료",
        confirm: "완료",
        complete: "튜토리얼 완료",
      },
      data_room: {
        purpose: {
          title: "스트롱박스 사용목적을 선택해주세요.",
          placeholder: "사용목적을 입력해주세요.",
          options: {
            option1: "투자유치",
            option2: "기업간 협력",
            option3: "아이디어 및 기술보호",
            option4: "기타",
          },
        },
        job: {
          title: "직무를 선택해주세요.",
          placeholder: "직무를 입력해주세요.",
          options: {
            option1: `연구원 - 책임급`,
            option2: `연구원 - 일반`,
            option3: `인사 및 재무관리`,
            option4: `프로젝트 매니저`,
            option5: `C - Level 임원진`,
            option6: `기타`,
          },
        },
        index1: "워크스페이스 내 여러 개의 \n 스트롱박스를 생성할 수 있어요.",
        index2: `스트롱박스 내 여러 개의 폴더를\n 생성할 수 있어요.`,
        index3: `한 폴더 안에  여러 개의 파일을 \n 올릴 수 있어요.`,
        index4: `파일 별로 링크를 만들고 공유하면 \n 누구나 파일을 열람할 수 있어요.`,
        index5: {
          step1: {
            title: "Step1 : 링크이름",
            desc: "공유 목적과 대상에 따라 링크의 이름을 \n 지정할 수 있어요.",
          },
          step2: {
            title: "Step2 : 유효기간",
            desc: "기간 이후 링크는 자동으로 만료되어\n 접근이 불가능해요.",
          },
          step3: {
            title: "Step3 : 공유옵션",
            desc: "링크를 열람하는 사람의 이메일을 수집하거나, \n 다운로드 가능 여부를 설정할 수 있어요.",
          },
          step4: {
            title: "Step4 : NDA 동의",
            desc: "중요한 내용의 파일은 기밀 유지 서약 동의를\n 받은 후 열람하도록 설정할 수 있어요.",
          },
          step5: {
            title: "Step5 : 링크생성",
            desc: "선택한 설정값대로 링크를 생성해보세요.",
          },
        },
        index6: `스트롱박스 유저가 아닌 사람에게도 \n 링크를 공유할 수 있어요.`,
        index7: `방문 기록과 체류시간을 \n 확인할 수 있어요.`,
        index8: `다운로드 수, 페이지별 체류시간 \n 분석을 통해 파일 내용을 \n 개선할 수 있어요.`,
      },
    },
  },
};
