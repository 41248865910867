import React, { useState } from "react";
import LocalizedStrings from "react-localization";
import { mkEventEmitter, mkEventListener, OverflowPolicy } from "./event";
import { getLoggers } from "@redwit-commons/utils/log";

const { WARN } = getLoggers("hooks/translate");

const all_updater_list: Array<(lang: string) => void> = [];
const all_lang_list: Array<string> = [];

/**
 * 1개의 프로젝트에서라도 지원하는 언어를 전부 리턴한다.
 *
 * @returns
 */
export const getGlobalTranslateList = () => {
  return all_lang_list;
};

/**
 * 모든 하위 프로젝트의 언어를 한번에 바꿀 수 있는 updater 리턴.  디버그
 * 용도로만 사용됨.
 *
 * @param lang
 */
export const useGlobalTranslateUpdater = (lang: string) => {
  for (const updater of all_updater_list) {
    updater(lang);
  }
};

/**
 * 주어진 번역 내용을 기반으로 다음 네 가지의 함수를 만든다.
 *
 * * useTranslate: 현재 선택된 translation 을 사용하는 hook
 * * mkTranslate(this): useTranslate 를 Class component 에서도 사용할 수 있도록
 *   하는 함수.  첫 번째 파라메터로 클래스 this 를 받는다 (translation 변경 시
 *   업데이트 위함).
 * * useTranslateUpdater(lang: string): 현재 선택된 translation 을 바꾸는 함수
 *   (hook)
 * * mkTranslateUpdater(lang: string): useTranslate 의 class 버전
 *
 * @param input {ko: ..., en: ...} 형식으로 작성된 번역 자료
 * @param default_lang
 * @param projectName
 * @returns
 */
export function mkTranslateExport<T>(
  input: { [language: string]: T },
  default_lang: string,
  projectName: string
) {
  const EVENT_KIND = `__TRANSLATE_UPDATE_${projectName}`;
  const localString = new LocalizedStrings(input);
  for (const l of Object.keys(input)) {
    if (all_lang_list.some((x) => x === l)) {
      continue;
    }
    all_lang_list.push(l);
  }
  const useTranslate = () => {
    const [_, setLang] = useState<string>(default_lang);
    mkEventListener(EVENT_KIND, () => {
      setLang(localString.getLanguage());
      return false;
    });
    return localString;
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const mkTranslate = (
    componentThis: React.Component<any, any, any>
  ): [typeof localString, () => void, () => void] => {
    const [init, deinit] = mkEventListener(EVENT_KIND, () => {
      componentThis.forceUpdate();
      return false;
    });
    return [localString, init, deinit];
  };
  /* eslint-disable @typescript-eslint/no-explicit-any */

  const emitter = mkEventEmitter(EVENT_KIND, OverflowPolicy.PARALLEL);
  const updater = (lang: string) => {
    if (!(lang in input)) {
      WARN(
        `${lang} is not part of project(${projectName})'s language list, ignore.`
      );
      return;
    }
    localString.setLanguage(lang);
    emitter(undefined);
  };
  all_updater_list.push(updater);

  const mkTranslateUpdater = () => {
    return updater;
  };

  const useTranslateUpdater = () => {
    return mkTranslateUpdater();
  };
  return {
    useTranslate,
    mkTranslate,
    mkTranslateUpdater,
    useTranslateUpdater,
  };
}
