export default {
  ko: {
    button: {
      links: "링크",
      copy: "복사",
      cta: {
        cancel: "취소",
        all: "전체 선택",
        delete: "연구파일 삭제",
        download: "연구노트 다운로드",
        move: "연구파일 이동",
        transfer_owner: "소유권 이전",
      },
      add: "추가",
      pdf_download: "PDF 다운로드",
      delete: {
        note: "연구 파일 삭제",
        project_auth: "삭제",
        sign: "전자서명 지우기",
      },
      auth: {
        email: "이메일 인증하기",
      },
      load: {
        more: "더 불러오기",
        done: "모든 노트를 불러왔습니다.",
      },
      upload: {
        file: "파일 추가",
        option: "파일 추가 옵션",
        google_drive: "구글 드라이브에서 추가",
        note: "연구파일 추가",
        tag: "태그 추가하기",
        no_tag: "태그가 없습니다.",
      },
      create: {
        sign: "클릭하여 전자서명을 만드세요!",
        note: "새 노트 만들기",
        folder: "연구노트 생성",
      },
      back: {
        home: "홈으로 돌아가기",
        prev: "이전 페이지",
      },
      info: {
        change_file_name: "파일명 수정",
        folder: "폴더 정보",
        note: "파일정보",
        move: "파일이동",
        tag: "태그 확인",
        research_note: "연구노트 정보",
      },
      share: {
        folder: "폴더 공유",
      },
      download: {
        auth_note: "연구노트 다운로드",
      },
      edit: {
        tag: "태그 편집",
        pw: "비밀번호 변경",
      },
      setting: {
        folder: "폴더 설정",
      },
      search: `확인`,
      cancel: "취소",
      all_select: "전체선택",
      all_deselect: "전체해제",
      purchase: "플랜 구매하기",
      register: "회원가입 하기",
      login_link: "로그인 하기",
      link: "연동하기",
      request: "견적 문의",
      start: "시작하기",
      free_start: "무료로 시작하기",
      payment: "결제하기",
      login_platform: {
        kako: "카카오로 시작하기",
        google: "구글로 시작하기",
        apple: "애플로 시작하기",
      },
      login: "로그인",
      logout: "로그아웃",
      edit_profile: "프로필 수정",
      check_email: "중복확인",
      start_goono: "구노 시작하기",
      confirm: "확인",
      copy_invite_code: "초대코드 복사",
      move: "파일 이동",
      auth_status: "인증 현황",
      unlink: "해제하기",
      read_only: "읽기권한",
      read_and_write: "작성권한",
      save: "저장",
      submit: "제출하기",
      contact: "문의하기",
      copy_link: "복사",
      remove: "삭제",

      filter: {
        advanced_search: "상세 검색",
        all_member: "전체 멤버",
        search_member: "멤버 선택",
        all_file: "전체 형식 선택",
        search_file_type: "파일 형식 선택",
        clear: "전체 선택 해제",
      },
    },
  },
  en: {
    button: {
      links: "Link",
      copy: "Copy",
      cta: {
        cancel: "Cancel",
        all: "Select all",
        delete: "Delete",
        download: "Download note",
        move: "Move",
        transfer_owner: "Transfer ownership",
      },
      add: "추가",
      submit: "Submit",
      pdf_download: "PDF Download",
      delete: {
        note: "Delete",
        project_auth: "Delete",
        sign: "All Clear",
      },
      auth: {
        email: "Authenticate your email",
      },
      load: {
        more: "Load More",
        done: "All notes have been loaded.",
      },
      upload: {
        file: "Add research files",
        option: "Upload options",
        google_drive: "From Google Drive",
        note: "Add reasearch files",
        tag: "Add tags",
        no_tag: "No tags",
      },
      create: {
        sign: "Click and create electronic signature!",
        note: "Create a new note.",
        folder: "New note",
      },
      back: {
        home: "Go Home",
        prev: "Previous Page",
      },
      info: {
        change_file_name: "Change File name",
        folder: "Folder Info",
        note: "File information",
        move: "Move a File",
        tag: "Tag info",
        research_note: "Research Note Info",
      },
      share: {
        folder: "Share",
      },
      download: {
        auth_note: "Download note",
      },
      edit: {
        tag: "Edit tag",
        pw: "Change Password",
      },
      setting: {
        folder: "Setting",
      },
      search: `Search`,
      cancel: "Cancel",
      all_deselect: "All Deselect",
      all_select: "All Select",
      purchase: "Buy a plan",
      register: "Sign Up",
      login_link: "Sign In",
      link: "Link",
      request: "Contact Us",
      start: "Buy Now",
      free_start: "Sign Up for Free",
      payment: "Purchase",
      login_platform: {
        kako: "Sign Up with Kakao",
        google: "Sign Up with Google",
        apple: "Sign Up with Apple",
      },
      login: "Sign In",
      logout: "Sign out",
      edit_profile: "Edit profile",
      check_email: "Duplicate Check",
      start_goono: "Get Started with GOONO",
      confirm: "Done",
      copy_invite_code: "Copy the Code",
      move: "Move",
      auth_status: "History",
      unlink: "Unlink",
      read_only: "Read only",
      read_and_write: "Write only",
      save: "Save",
      contact: "Contact",
      copy_link: "Copy",
      remove: "Delete",
      filter: {
        advanced_search: "Advanced search",
        all_member: "Select all members",
        search_member: "Select",
        all_file: "Select all file types",
        search_file_type: "Select",
        clear: "Clear",
      },
    },
  },
};
