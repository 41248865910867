import React from "react";
import { Switch, Route } from "react-router-dom";
import { routes_landing, routes_service, routes_common } from "./path";
import { Grid } from "@material-ui/core";
import loadable from "@loadable/component";
import { LandingRoute } from "./RouteByType/LandingRoute";
import { PrivateRoute } from "./RouteByType/PrivateRoute";
import { CommonRoute } from "./RouteByType/CommonRoute";

const ForbiddenScreenLazy = loadable(
  () => import("src/screens/common/ForbiddenScreen")
);

export default function RoutingContents() {
  return (
    <Grid direction={"column"} container>
      <Grid
        item
        container
        xs={12}
        style={{ display: "flex" }}
        direction={"row"}
      >
        <Switch>
          {routes_landing.map((route, id) => (
            <LandingRoute {...route} key={`landingRoute${id}`} />
          ))}

          {routes_service.map((route, id) => (
            <PrivateRoute {...route} key={`privateRoute${id}`} />
          ))}

          {routes_common.map((route, id) => (
            <CommonRoute {...route} key={`commonRoute${id}`} />
          ))}
          <Route path="/*" component={ForbiddenScreenLazy} />
        </Switch>
      </Grid>
    </Grid>
  );
}
