export const faqs = {
  payment: [
    {
      q: "무료 플랜은 유료 플랜과 어떤 차이가 있나요?",
      a: [
        "무료 플랜과 유료 플랜은 제공하는 기능에 차이가 있습니다.",
        "자세한 사항은 아래와 같습니다.",
        `게스트 등급 샘플연구노트 보기`,
        `무료 체험 ① 유료 기능 모두 사용 가능 (PDF 추출 시 미검증 시점인증 마크)`,
        "유료  ① 폴더 생성 무제한 ② 사용자간 폴더공유 ③ PDF 추출 시 검증된 시점인증 마크 ④ 깃헙, 구글드라이브 연동 ⑤ OCR 검색 지원",
      ],
    },
    {
      q: "유료 플랜을 결제하려면 어떻게 해야 하나요?",
      a: [
        "유료 구매 시 별도의 견적 협의가 필요한 부분으로[문의하기, 견적문의] 혹은 contact@redwit.io으로 <기관명/담당자명/연락처/도입인원>을 기재해 문의주시면 빠르게 답변 드리겠습니다.",
      ],
    },
    {
      q: "남은 플랜 기간은 어디서 확인하나요?",
      a: ["남은 플랜 기간은 로그인 후 메인 대시보드에서 확인 가능합니다."],
    },
  ],
  payment_highlight: {
    general: "게스트 등급",
    free: "무료 체험",
    paid: "유료 ",
  },
  function: [
    {
      q: "업로드한 컨텐츠의 자료 유출이 걱정돼요.",
      a: "현재 연구노트 데이터의 경우 암호화 되어 세계 최고 보안과 안전성을 갖춘 아마존 클라우드 S3에 보안되며, 구노 자체 서버에는 연구노트의 해시값만 프라이빗 블록체인 네트워크에 저장됩니다. 2중 보안으로 자사에서도 업로드한 컨텐츠가 암호화 보관되니 자료 유출은 걱정하지 않으셔도 됩니다.",
    },
    {
      q: "해시태그 지정을 안했는데 검색할 수 없나요?",
      a: "해시태그 지정을 하지 않으신 경우 업로드한 파일 개별적으로 해시태그 추가가 가능합니다. 또한 유료 플랜 기능인 OCR(광학문자판독)기능으로 문서 내의 수기 단어도 검색 가능합니다.",
    },
    {
      q: "연구노트나 업로드한 연구파일이 제대로 보이지 않아요!",
      a: "서버 업데이트 혹은 일정기간 접속 기록이 없을 시 로그가 초기화 되어 일시적으로 연구파일 및 노트가 보이지 않을 수 있습니다. 이때에는 재로그인을 후 정상적으로 파일 확인이 가능합니다.",
    },
    {
      q: "삭제한 연구파일은 복구할 수 없나요?",
      a: "사용자 본인 계정(혹은 관리자 계정)의 복구 요청 시에는 블록체인 로그에서 해당 파일을 백업처리하여 복구할 수 있습니다. 단, 개인정보보호처리 방침에 의거하여 삭제 후 1년 경과시에는 불가합니다.",
    },
    {
      q: "외부 서비스 연동, 앱에서는 안되나요?",
      a: "외부 서비스는 앱과, 웹 모든 버전에서 연동 및 확인이 가능합니다.",
    },
    {
      q: "서명이나 인증 등의 효력은 확실한가요?",
      a: "구노는 '한국특허전략원'과 함께 기획하여 만들어진 서비스입니다. 시점인증은 블록체인과 한국전자인증의 시간을 모두 받아오며, 제3자 서버는 기존 전자연구노트와 동일하게 네트워크를 통해 보장되어 전자연구노트로 효력을 갖습니다.",
    },
    {
      q: "플랜 기간이 종료되면 업로드한 파일이 사라지나요?",
      a: "플랜 유효 기간 동안 업로드한 게시물은 플랜이 종료되어도 그대로 유지됩니다. ",
    },
    {
      q: "수기노트로 작성한 것만 올릴 수 있나요?",
      a: "수기 노트뿐만 아니라 직접 작성한 모든 자료를 사진으로 촬영하거나 이미지 파일로 변환(JPG, PNG, PDF)하여 업로드 할 수 있습니다. 포스트잇, office문서, 화이트보드 형식에 제한이 없습니다.",
    },
    {
      q: "시점인증까지 시간은 얼마나 소요되나요?",
      a: "등록하신 파일은 2~3초 내로 시점인증 처리가 완료됩니다. 파일의 처리 상태는 [인증진행상황]에서 확인 가능합니다.",
    },
  ],
  account: [
    {
      q: "비밀번호를 변경하고 싶어요.",
      a: "비밀번호 변경은 로그인 화면에서 가능합니다. 우측 하단의 [비밀번호 변경] 탭을 통해, 이메일 인증 후 새롭게 비밀번호를 설정할 수 있습니다.",
    },
    {
      q: "회원정보를 변경하고 싶어요.",
      a: "회원정보 이름, 소속기관, 전공/분야/부서명, 전자서명은 마이페이지를 통해 언제든 변경 가능합니다. 단, 이메일의 경우 변경할 수 없습니다.",
    },
    {
      q: "단체 회원으로 가입하고 싶습니다.",
      a: "단체 회원의 경우 대표자(관리자)가 가입하여 다른 멤버를 초대할 수 있습니다. 또한 관리자에게는 사용자 관리 페이지가 별도로 부여되어 사용자 추가 및 삭제, 업로드 현황을 확인할 수 있습니다.",
    },
  ],
  etc: [
    {
      q: "정부과제 연구노트로 인정이 되나요?",
      a: "구노는 국가 기준 연구노트 법령에 해당하는 모든 조건을 충족하는 서비스로 전자연구노트로 인정되어, 정부과제 서류 제출 시 활용 가능합니다.",
    },
    {
      q: "블록체인 인증이 일반 인증과 무엇이 다른가요?",
      a: "프라이빗 블록체인은 퍼블릭 블록체인과 다르게 신원이 인증된 사용자만 참여할 수 있는 분산 네트워크입니다. 위변조 방지가 확실하여, 멤버님이 작성한 연구데이터를 특정 시점에 작성했다는 것을 보장하라 수 있습니다.",
    },
  ],
};

export const faqs_en = {
  payment: [
    {
      q: "What's the difference between the free and paid plan?",
      a: [
        "There are differences in features between the free plan and the paid plan. ",
        "Here are the details: ",
        `Common Features ①Unlimited Authentication ②Unlimited Note Upload ③Hashtags `,
        `Free plan ①Maximum of 3 Folder Creation ②Watermark on PDF Extraction`,
        "Paid plan ①Unlimited Folder Creation ②Folder Sharing among Users ③No Watermark on PDF Extraction ④GitHub and Google Drive Integration ⑤OCR Search Support",
      ],
    },
    {
      q: "How do I pay for a paid plan?",
      a: [
        "For paid purchases, if you require a separate quote, please reach out through [Inquire, Contact quotation] or contact@redwit.io. with <Organization /Contact Person/Contact /Number of Users>, and we'll quickly respond.",
      ],
    },
    {
      q: "Where can I check the remaining plan period?",
      a: [
        "You can check the remaining plan period on the main dashboard page after logging in.",
      ],
    },
  ],
  payment_highlight: {
    general: "Common Features",
    free: "Free Plan",
    paid: "Paid Plan",
  },
  function: [
    {
      q: "I am worried about outflow of my uploaded contents.",
      a: "The research file data is encrypted and stored at Amazon Cloud S3 with the best security and safety in the world, and the GOONO server only stores the hash values of the research file in the private block chain network. The uploaded contents are encrypted as a means for double security, so you don’t need to worry about data leakage.",
    },
    {
      q: "Can I search contents without hashtags?",
      a: "If you did not set hashtags, you can individually add hashtags for the uploaded files. Also, you can search the handwritten words in the document through OCR function available for paid plan.",
    },
    {
      q: "I cannot properly see the uploaded research notes!",
      a: "Your log will be reset after server update or in case you haven’t logged in for a certain time period, in which case the research notes and files might not be viewable temporarily. Please log in again, and you will be able to check the files.",
    },
    {
      q: "Can I restore the deleted research files?",
      a: "The block chain log can backup and restore the file when the user requests his/her account to be restored. If a year has passed since the deletion, it cannot be restored due to personal information security law.",
    },
    {
      q: "Can I integration outer services in the app?",
      a: "The outer service can be interlocked and checked in all versions of app and web.",
    },
    {
      q: "Do the certificates or signatures function properly?",
      a: "GOONO is made with the help of Korea Intellectual Property Strategy Agency. The viewpoint certification receives both block chain and Korea Electronic Certification’s time, and the third-party server is identically certified with the original electronic research note through network.",
    },
    {
      q: "Will the uploaded files be deleted if the plan expires?",
      a: "Posts uploaded during the plan's validity period remain intact after the plan terminates.",
    },
    {
      q: "Can only the handwritten contents be uploaded?",
      a: "Not only handwritten notes but all data can be uploaded by taking photos of it or by converting it to an image file(JPG, PNG, PDF). The format has no restrictions.",
    },
    {
      q: "How long does it take for viewpoint certification?",
      a: "The uploaded note will be viewpoint certified within 2~3 seconds. The file’s status can be checked in [Blockchain Collection].",
    },
  ],
  account: [
    {
      q: "I wish to change my password.",
      a: "You can change your password in the log-in page. Through the below right [change password] tab, you can reset your password after e-mail certification.",
    },
    {
      q: "I wish to change my user information.",
      a: "User information name, institute, major/field, and electronic signature can always be changed through Mypage. But, e-mail address cannot be changed.",
    },
    {
      q: "I wish to register as group membership.",
      a: "In case of group members, the representative(supervisor) can register multiple plan and grant plan to the users through specific invitation codes. Also, the supervisor will have additional user management page, where you can check the upload status. Along with this, addition and deletion of users can be done through this page.",
    },
  ],
  etc: [
    {
      q: "Can it be admitted as government assignment research note?",
      a: "GOONO is recognized as an electronic research note as a service that meets all conditions falling under the national standard research note legislation, and can be used when submitting government documents.",
    },
    {
      q: "How is block chain certification different from regular certification?",
      a: "Unlike public block chain, private block chain is a dispersive network in which only identified users can participate. Prevention of forgery is certain, so you might have to verify that you wrote specific research data at specific point.",
    },
  ],
};

export default {
  ko: {
    faq: {
      ...faqs,
    },
  },
  en: {
    faq: {
      ...faqs_en,
    },
  },
};
