import T from "@redwit-commons/utils/typecheck";
import {
  Workspace,
  WorkspaceMemberAccessLog,
  WorkspaceMemberAccessLogSchema,
  WorkspaceSchema,
} from "../workspace";

export const validateOGetWorkspace = T.mkValidator<Workspace>(WorkspaceSchema);

export const validateGetAllWorkspaces = T.mkValidator<Workspace[]>(
  T.array(WorkspaceSchema.clone())
);

export const validateOCreateWorkspace = T.mkValidator<Workspace>(
  WorkspaceSchema.clone()
);

export const validateOGetWorkspaceACLList = T.mkValidator<string[]>(
  T.array(T.string())
);

export type OGetWorkspaceMemberAccessLogs = {
  total: number;
  results: WorkspaceMemberAccessLog[];
};

export const OGetWorkspaceMemberAccessLogsSchema = T.object()
  .addField("total", T.number())
  .addField("results", T.array(WorkspaceMemberAccessLogSchema.clone()));

export const validateOGetWorkspaceMemberAccessLogs =
  T.mkValidator<OGetWorkspaceMemberAccessLogs>(
    OGetWorkspaceMemberAccessLogsSchema
  );
