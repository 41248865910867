export default {
  ko: {
    landing_home: {
      introduce: {
        sub_title: "연구노트 따로 쓰지 말고",
        title_01: "올리기만",
        title_02: "하세요",
        trial_btn: "체험하기",
        contact_btn: "도입문의",
        desc_01: "매번 일일이 작성하기 힘드셨죠?",
        desc_02: "이제 연구노트 작성부터 관리까지 구노로 쉽게 시작하세요.",
      },
      data_room_introduce: {
        sub_title: "간편하고 안전한 파일 공유 서비스",
        title_01: "기밀자료 넘길때마다 \n 불안했던 마음",
        title_02_bold: "Strong Box",
        title_02: "로 \n 지키세요!",
        desc: "남 주기 아까운 우리 회사 정보 \n 스트롱박스가 안전하게 지켜드릴게요.",
      },
      function_tab: {
        title: "전자연구노트 작성과 문서보안시스템을 \n “구노” 하나로",
        title_highlight: "“구노”",
        desc: "탭 메뉴를 클릭 해 각 서비스 별 \n 기능을 확인해보세요!",
        research_note: "연구노트",
        data_room: "데이터룸",
      },
      data_room_function: {
        func_01: {
          title: "안전한 파일 공유",
          subtitle: "공유링크 생성하고 \n 간편하고 안전하게 관리!",
          description:
            "공유링크 생성 후 이메일 수집, 다운로드 관리도 설정하고 \n 언제든 자료 접근을 제한 할 수 있어요",
        },
        func_02: {
          title: "FEEDBACK 프로그램",
          subtitle: "인사이트 모니터링을 통한 \n 문서 피드백!",
          description:
            "누가 확인했는지, 어느 페이지에 오래 체류했는지 \n 한 눈에 볼 수 있도록 준비했어요.",
        },
        func_03: {
          title: "NDA 기밀유지서약서",
          subtitle: "추가 공증이 필요없는 \n 비밀유지서약서!",
          description:
            "공유링크 생성 후 이메일 수집, 다운로드 관리도 설정하고 \n 외부에 공유 시 비밀유지협약서도 함께 보낼 수 있어요.",
        },
        func_04: {
          title: "Private Blockchain",
          subtitle: "강력한 보안 기술로 \n 문서를 가장 안전하게!",
          description:
            "허가된 사용자만이 네트워크에 참여 할 수 있으며, \n 원본 파일은 이중 암호화 기술로 안전하게 보관됩니다.",
        },
      },
      function: {
        func_01: {
          title: "구노는 국가연구개발사업 연구노트 지침법령을 충족해요.",
          list_01: "기록자의 전자 서명인증",
          list_02: "기록한 날짜와 시각의 자동기록 기능",
          list_03: "기록의 위변조 확인 가능",
        },
        func_02: {
          title: "다양한 외부 서비스의 연동으로 증대되는 업무 효율성!",
          list_01: "구글 드라이브와 연동",
          list_02: "매일 밤 12시, 깃허브 내역 자동 업로드",
        },
        func_03: {
          title: "관리자 대시보드를 통해 멤버 관리 및 데이터 취합을 편리하게!",
          list_01: "기간 내 활성 멤버 확인 가능",
          list_02: "노트 별 연구파일 현황 확인 가능",
          list_03: "기간 내 추가된 연구파일 개수 확인 가능",
        },
        func_04: {
          title: "웹 뿐만 아니라 모바일로도 어디서든 쉽게 사용할 수 있어요!",
          list_01: "실시간 촬영으로 수기노트 업로드",
          list_02: "다양한 기기 지원으로 어디서든 연구노트 관리",
        },
      },
      certification: {
        title_highlight: "국가인증 받아 안심할 수 있는",
        title: " 연구노트 구노!",

        mark_list: {
          iso9001: "ISO 9001",
          iso9001_desc:
            "모든 산업 분야 및 활동에 적용할 수 있는 품질경영시스템의 요구사항을 규정한 국제표준",
          iso27001: "ISO 27001",
          iso27001_desc:
            "국제표준화기구 및 국제전기기술위원회에서 제정한 정보보호 관리체계에 대한 국제 표준",
          crosscert: "한국전자인증 시점인증",
          crosscert_desc:
            "한국전자 인증서는 한국 전자인증의 인증서 고유상표로 최고의 전자인증 서비스",
          good_sw: "굿소프트웨어",
          good_sw_desc:
            "소프트웨어산업 진흥법 및 과학기술정보통신부 고시에 따라 시험·평가를 통해 인증",
          fda: "FDA CFR PART 11",
          fda_desc:
            "전자 기록, 전자 서명에 대한 규정 정도가 적합한지에 대해서 CFR PART 11 적합 인증",
        },
      },
      review: {
        title: "구노를 선택하는 이유는?",
        sub_title: "고객이 먼저 찾고 만족하는 연구노트 서비스 ‘구노’ 입니다.",

        item_01: {
          title: "연구노트 수기 작성이 지친다면 추천",
          text: "수기 작성보다 훨씬 빠르게 기존 문서나 사진 등을 그대로 올릴 수 있어 업무의 효율성이 많이 향상되었어요. 특히 연구노트 양식이 통일화되어, 체계적으로 관리할 수 있습니다.",
          reviewer: "김성민 연구원 ",
        },
        item_02: {
          title: "R&D 과제 수행한다면 선택이 아닌 필수",
          text: "업로드 즉시 공신력있는 전자연구노트 완성, 번거로운 플로우를 줄이고 작성자 전자 서명, 시점인증 등이 자동 적용되어 연구노트에 소요되는 시간이 단축돼서 좋았습니다.",
          reviewer: "장정희 연구원",
        },
        item_03: {
          title: "신뢰할 수 있고, 만족하는 서비스 구노",
          text: "시점인증은 연구데이터 관리에 있어 매우 중요한 요소라고 생각하는데, 구노의 자동시점인증 기능과 작성일자 입력 기능이 그런 관점에 있어서 굉장히 만족스럽습니다.",
          reviewer: "장석준 연구관리자",
        },
        item_04: {
          title: "연구 환경의 건조기와 식기세척기",
          text: "구노는 사용법을 따로 교육하지 않아도될 정도로 매우 간단하게 사용 가능합니다. 사용하기 전에는 몰랐지만 이제는 없어서 안될 건조기와 식기세척기 같아요.",
          reviewer: "나정현 교수",
        },
        item_05: {
          title: "연구노트의 진입장벽을 낮춰주는 구노",
          text: "구노는 별도의 노하우가 필요없는 연구노트입니다. 연구 내용을 별도의 엄격한 양식에 맞춰 수정하는 이중 작업 없이, 1~2주에 한번씩 그간 생성해왔던 노트들과 포스트잇 등을 등록하면 끝입니다",
          reviewer: "최준혁 연구원",
        },
        item_06: {
          title: "연구노트 작성 시간이 따로 들지 않아요",
          text: "실험 자료 촬영하고, 회의 자료들을 따로 쓰지 않고 업로드만 하면 되니 기존 수기노트에 소비했던 시간이 월등히 줄었습니다. 연구노트를 작성하는데 들던 시간에 많은 변화를 체감하고 있습니다.",
          reviewer: "조윤경 연구원",
        },
        item_07: {
          title: "오늘 도입해도 늦습니다 빨리 경험하세요",
          text: "IT 기업이다보니 사용성과 접근성이 좋은 구노를 선택하게 되었는데, Github 연동이 너무 편한데 가격도 합리적이에요. 연구노트 작성이 쉬워지니 , 자발적으로 작성하는 팀원들도 많아졌어요!",
          reviewer: "안혜정 선임연구원",
        },
      },
      partner: {
        title: "구노는 고객사와 함께 성장하고 있습니다.",
        sub_title: (count: string) =>
          `${count} 여개의 기관에서 믿고 사용 중이십니다.`,
      },
    },
    landing_service: {
      introduce: {
        title: "서비스 소개",
        sub_title: "전자 연구노트 구노에 대해 자세히 알고 안심하고 사용하세요!",
        easy: {
          tab_btn: "사용의 편의성",
          desc_01:
            "연구노트의 요구 조건을 자동으로 충족하며 노트 작성시간이 대폭 감소하여, ",
          desc_highlight: "연구과제의 업무 효율이 증대",
          desc_03: "됩니다.",
        },
        search: {
          tab_btn: "검색의 용이성",
          desc_01:
            " 정보의 보관과 관리가 편리하며 중복 실험을 방지하도록 해시태그 기능을 지원하며, ",
          desc_highlight: " 자료검색이 용이하도록 OCR · 파일명 검색 지원",
          desc_03: " 합니다.",
        },
        share: {
          tab_btn: "정보 공유의 용이성",
          desc_01:
            "시간과 공간 제약에서 자유롭고, 연구현황에 대한 파악이 가능해 업무관리가 용이하고,",
          desc_highlight: " 연구자료 공유를 통해 연구 성과가 증대",
          desc_03: "됩니다.",
        },
      },
      guide: {
        title_highlight: "GOONO",
        title: "연구노트 완성하기",
        sub_title:
          "구노와 함께하면 언제 어디서든 간단하게 연구노트를 완성할 수 있어요!",
        list_01: {
          title: "기존 파일 준비",
          desc: "기존에 작성했던 파일을 준비합니다.",
        },
        list_02: {
          title: "사진 촬영 또는 파일 업로드",
          desc_01: "수기노트는 사진촬영으로도 업로드 가능하고,",
          desc_02: "구글드라이브로 연동하여 파일업로드도 가능합니다.",
        },
        list_03: {
          title: "연구노트 자동완성",
          desc_01:
            "이렇게 작성하여 PDF로 연구노트를 다운받으면 국가연구노트 지침법령에 충족하는",
          desc_02: "연구노트로 자동완성됩니다!",
        },
        banner: {
          title: "GOONO 소개서",
          desc: "모든 기록과 문서를 구노 연구노트로 한번에! 연구노트의 요건을 충족하는 전자연구노트로 인증됩니다.",
          button: "다운로드",
        },
      },
      block_chain: {
        title: "블록체인으로 안전하게 관리",
        sub_title:
          "구노는 IPFS 분산 스토리지를 활용하여 원본 데이터 보안 및 관리해드립니다.",
      },
    },
    landing_service_data_room: {
      intro: {
        title: "이런 분들에게는 스트롱박스가 필수에요!",
        description:
          "정보 유출 피해액만 평균 16.7억원, 문서 공유의 걱정을 모두 보완했어요.",
        description_highlight: "16.7억원",
      },
      bubble: {
        item_01: {
          title: "영업 기밀이 퍼질까봐 불안해요!",
          description:
            "영업을 하면 할수록 저희의 \n 영업 기밀이 누설되는 것 같아 속상해요",
        },
        item_02: {
          title: "외부인이 보지는 않을까 의심돼요",
          description:
            "외부인이 우리가 공유한 자료를 볼까봐 \n 조마조마해서 공유가 무서워요",
        },
        item_03: {
          title: "정보 유출이 걱정돼요!",
          description:
            "우리의 소중한 정보가 \n 언제 어떻게 빠져나갈지 몰라 불안해요",
        },
        item_04: {
          title: "어떤 부분에 관심있는지 궁금해요",
          description:
            "투자자가 우리 사업 아이템을 정말 확인했나, \n 어느 부분에 관심가졌나 궁금해요",
        },
      },
      function: {
        sub_title: "문서를 지키는 노하우",
        title: "믿고쓰는 스트롱박스의 핵심기능",

        item_01: {
          title: "블록체인",
          text: "위변조 되지 않도록 \n 이중암호화로 정보 보안",
        },
        item_02: {
          title: "트래킹",
          text: "외부 유출 방지를 위한 권한 \n 설정 및 문서 열람 기록 관리",
        },
        item_03: {
          title: "모니터링",
          text: "내/외부적 기록을 자동으로 \n 수집해 모니터링 가능",
        },
        item_04: {
          title: "접근 제한",
          text: `“읽기전용" 등 권한을 \n 세분화하여 1차 유출 방지`,
        },
        item_05: {
          title: "NDA 템플릿",
          text: "기업간 신뢰관계 유지를 위한 \n NDA 템플릿 생성 가능",
        },
      },
      step: {
        sub_title: "이제, 마음 졸이지말고 기밀 자료를 공유해 보세요!",
        title: "한 눈에 보는 스트롱박스의 특장점",
        item_01: {
          title: "간편하게 파일 업로드",
          text: "다양한 확장자의 파일을 드래그앤 드랍으로 \n 간편하게 업로드해보세요.",
        },
        item_02: {
          title: "공유 링크 생성",
          text: "다운로드 가능 여부, 링크 유효 기간, NDA \n 기밀 유지 서약 등 옵션 설정이 가능해요!",
        },
        item_03: {
          title: "이메일 공유",
          text: "스트롱박스 멤버가 아니어도 이메일을 통해 \n 파일 공유가 가능해요!",
        },
        item_04: {
          title: "인사이트 피드백",
          text: "공유 링크 방문자 정보, 페이지별 체류시간 \n 등 인사이트를 확인해보세요.",
        },
      },
    },
    landing_price: {
      payment_faq: "결제 및 요금 관련 FAQ",
      title: "지금 연구노트를 시작해보세요!",
      title_highlight: "연구노트",
      sub_title: "업그레이드하여 더 많은 기능을 무제한으로 사용할 수 있습니다.",
      guide_text_1:
        "GOONO는 연구비 집행이 가능합니다. (최소 12개월부터 결제가 가능합니다.)",
      contact_title: "구노에 대해 더 알고싶다면?",
      contact_email: "이메일로 문의하기",
      contact_call: "전화로 문의하기",
      tab: {
        research_note: "연구노트",
        data_room: "데이터룸",
      },
      data_room: {
        title: "지금 데이터룸 솔루션 스트롱박스를 시작해보세요!",
        title_highlight: "스트롱박스",
      },
    },
    landing_contact: {
      title: "문의하기",
      sub_title: "문의에 대한 정확한 답변을 위해 기본 정보를 작성해주세요.",
      need: "(필수)",
      number: {
        title: "연락처",
        placeholder: "연락처를 입력해주세요.",
      },
      users: {
        title: "도입예상인원",
        placehoder: "인원을 선택해주세요.",
        selection: {
          ten: "10명 이하",
          twenty: "11 ~ 20명",
          thirty: "21 ~ 30명",
          forty: "31 ~ 40명",
          fifty: "41 ~ 50명",
          over: "51명 이상",
        },
      },
      email: {
        title: "이메일",
        placeholder: "답변 받으실 이메일을 입력해주세요.",
      },
      body: {
        title: "문의내용",
        placeholder: "기관, 담당자명을 포함한 구체적인 내용을 입력해주세요.",
      },
      demo: {
        title: "데모를 신청합니다.",
        yes: "네",
        no: "아니오",
      },
      ask_patent: "가출원 서비스를 포함한 견적서를 신청합니다.",
      ask_security:
        "접속 IP 설정 기능 (부가서비스)를 포함한 견적서를 요청합니다.",
    },
  },
  en: {
    landing_home: {
      introduce: {
        sub_title: "Don't write a research note",
        title_01: "Just Upload!",
        title_02: "",
        trial_btn: "Try for free",
        contact_btn: "Talk to sales",
        desc_01: "It was hard to write every single one, right?",
        desc_02:
          "Start easily with GOONO for seamless research note creation and management.",
      },
      data_room_introduce: {
        sub_title: "Easy and secure file sharing",
        title_01: "With",
        title_02_bold: "Strong Box ",
        title_02: "confidentiality is never compromised!",
        desc: "Safeguard your company's confidential \n data with Strong Box.",
      },
      function_tab: {
        title: "Electronic Research Note + Document Security, \n 'Goono'",
        title_highlight: "'Goono'",
        desc: "Click the tabs below to learn about each solution.",
        research_note: "Research Note",
        data_room: "DataRoom",
      },
      data_room_function: {
        func_01: {
          title: "Secured document sharing ",
          subtitle: "Create custom links \n  to manage files easy and safe.",
          description:
            "Collect email addresses, allow downloading \n and control access anytime through custom links.",
        },
        func_02: {
          title: "Feedback enablement",
          subtitle: "Get insights through \n real-time monitoring",
          description: "See visitors and their time \n spent on each page.",
        },
        func_03: {
          title: "NDA agreement",
          subtitle:
            "No notarization needed for \n the confidentiality agreement.",
          description: "Share custom links \n with a required NDA agreement",
        },
        func_04: {
          title: "Private Blockchain",
          subtitle:
            "Powerful security technology \n that secures your documents",
          description:
            "Only permitted users can join the network and \n original copies are securely stored with double encryption technology.",
        },
      },
      function: {
        func_01: {
          title:
            "GOONO is a national R&D project Meets research note guidelines.",
          list_01: "Authentication of the electronic signature",
          list_02: "Automatic recording function of recorded date and time",
          list_03: "Forgery of records can be checked",
        },
        func_02: {
          title:
            "In conjunction with various external services Increased work efficiency!",
          list_01: "Integrating with Google Drive",
          list_02: "Automatic GiHub updates every night at 12 AM",
        },
        func_03: {
          title: "Researcher via admin dashboard management!",
          list_01: "Track active researchers within the set timeframe",
          list_02: "Check research file status for each note",
          list_03: "Check the number of research files added during the period",
        },
        func_04: {
          title: "Not only on web but also on mobile Easy to use anywhere!",
          list_01: "Upload handwritten notes by real-time shooting",
          list_02: "Manage research notes anywhere with various device",
        },
      },
      certification: {
        title_highlight: "National certified research note,",
        title: " Goono!",

        mark_list: {
          iso9001: "ISO 9001",
          iso9001_desc:
            "International standard specifying requirements for all quality management systems",
          iso27001: "ISO 27001",
          iso27001_desc:
            "International standard for information security management systems established by the ISO and the IEC",
          crosscert:
            "E-certification with Korea Electronic Certification Authority",
          crosscert_desc:
            "Korea Electronic Certification is a top-tier and unique trademark of all e-certification in Korea",
          good_sw: "GOOD SOFTWARE",
          good_sw_desc: "Certified by Ministry of Science and ICT",
          fda: "FDA CFR PART 11",
          fda_desc:
            "CFR PART 11 certified on electronic records and signatures",
        },
      },
      review: {
        title: "Why choose GOONO?",
        sub_title: "Clients' top choice of research note solution, 'Goono'",

        item_01: {
          title: "Tired of hand-writing research note?",
          text: "Uploading existing notes and images has improved efficiency by far. Especially having a standardized format gives the team a system and a structure.",
          reviewer: "Researcher Kim",
        },
        item_02: {
          title: "Essential for R&D projects",
          text: "Generating certification research notes seconds after uploading, simplifying workflow, electronic signature, time-stamp certiciation is the game changer. It has shortened so much of operational task management.",
          reviewer: "Researcher Jang",
        },
        item_03: {
          title: "Worth your trust and time",
          text: "Getting a time stamp certification is critical when managing research data. Goono automates and optimizes this workflow so perfectly that I'm very satisfied. ",
          reviewer: "Manager Jang",
        },
        item_04: {
          title: "Just like having a dishwasher, it will change your life!",
          text: "Goono is very intuitive and has almost a zero learning curve. It has become irreplaceable that I didn't know I needed, just like a dishwasher and dryer.",
          reviewer: "Professor Na",
        },
        item_05: {
          title: "Lowers the hurdle of managing research notes",
          text: "Just upload your documents and sticky notes 1-2 times a week, that's it. You don't need to re-write your notes to comply with complicated and demanding guidelines.",
          reviewer: "Researcher Choi",
        },
        item_06: {
          title: "No need to spare extra time for writing notes",
          text: "It saves you from creating extra documents for the sake of recording your work. Goono has saved enormous time from days of hand-writing notes.",
          reviewer: "Researcher Cho",
        },
        item_07: {
          title: "Start using it today",
          text: "As an IT team, Goono was the most reasonable choice with its great usability and accessibility. I especially love Github integration for its easy integration and price. Now we see a lot more members getting into a habit of writing research notes.",
          reviewer: "Senior Ahn",
        },
      },
      partner: {
        title: "Goono is growing as our clients do",
        sub_title: (count: string) => `Trusted by over ${count} organizations.`,
      },
    },
    landing_service: {
      introduce: {
        title: "Service overview",
        sub_title:
          "Learn more about GOONO, the electronic research note service, and use it with confidence!",
        easy: {
          tab_btn: "Ease of use",
          desc_01:
            "Automatically fulfilling the requirements of research notes and reduce note creation time, ",
          desc_highlight:
            "leading to increased efficiency in research project tasks.",
          desc_03: "",
        },
        search: {
          tab_btn: "Ease of search",
          desc_01:
            "Enables easy information management, prevents duplication with hashtag support, and ",
          desc_highlight:
            "aids data retrieval through OCR and filename searches.",
          desc_03: "",
        },
        share: {
          tab_btn: "Ease of sharing",
          desc_01:
            "Provides flexibility in time and space, simplifies task management through insight into research progress, and",
          desc_highlight: "  improves research results through data sharing.",
          desc_03: "",
        },
      },
      guide: {
        title_highlight: "Complete",
        title: "Research note",
        sub_title:
          "With Goono, you can easily complete your research notes anytime, anywhere!",
        list_01: {
          title: "Preparing file",
          desc: "Prepare the previously created file.",
        },
        list_02: {
          title: "Take picture or upload file",
          desc_01: "Handwritten notes can be uploaded via photos, ",
          desc_02: "and you can also upload files by Google Drive.",
        },
        list_03: {
          title: "Auto-completion",
          desc_01:
            "When you download the research note as a PDF, it will automatically comply with ",
          desc_02: "national research note regulations!",
        },
        banner: {
          title: "Introduction to GOONO",
          desc: "All records and documents at once in GOONO research notebook! It will be certified as an electronic research note that meets the requirements for a research note",
          button: "Download",
        },
      },
      block_chain: {
        title: "Secured by blockchain",
        sub_title:
          "GOONO utilizes IPFS distributed storage for securing and managing original data.",
      },
    },
    landing_service_data_room: {
      intro: {
        title: "If this is you, Strong Box is for you!",
        description:
          "The cost of data breaches is over $121M. Protect your money and shared documents.",
        description_highlight: "over $121M.",
      },
      bubble: {
        item_01: {
          title: "Best practice to enusre confidentiality in sales?",
          description:
            "I'm concerned that sales expansion \n might compromise confidentialities.",
        },
        item_02: {
          title: "Worrying about public sharing?",
          description:
            "I am always worried if others view \n shared documents without permission.",
        },
        item_03: {
          title: "Nervous about data breach?",
          description: "I can never be sure \n of data security.",
        },
        item_04: {
          title: "Wondering which captivates the viewers attention?",
          description:
            "I'd like to know if and where investors \n are interested in our business.",
        },
      },
      function: {
        sub_title: "Your expertise to protect documents.",
        title: "Core features",

        item_01: {
          title: "Blockchain",
          text: "Double Encryption to \n prevent data manipulations",
        },
        item_02: {
          title: "Tracking",
          text: "Permission and visitor history settings \n to prevent data breach",
        },
        item_03: {
          title: "Monitoring",
          text: "Real-time monitoring \n and collectionon both internal and external visits",
        },
        item_04: {
          title: "Permission setting",
          text: `Multi-level permission including 'read-only' \n to prevent initial data breach`,
        },
        item_05: {
          title: "NDA templates",
          text: "Create NDA templates \n to enhance trust between businesses",
        },
      },
      step: {
        sub_title: "Share valuable information at ease",
        title: "How to use",
        item_01: {
          title: "Simple file upload",
          text: "Drag and drop files \n of all formats.",
        },
        item_02: {
          title: "Create custom links",
          text: "Allow downloading, set a expiry date \n and require NDA agreement.",
        },
        item_03: {
          title: "Share via email",
          text: "Share documents to outside \n your team or company.",
        },
        item_04: {
          title: "Get feedback and insights",
          text: "Monitor visitors' information \n and spent-time per page.",
        },
      },
    },
    landing_contact: {
      title: "Contact",
      sub_title:
        "Please provide basic information for accurate responses to your inquiries.",
      need: "(required)",
      number: {
        title: "Phone Number",
        placeholder: "Please write your phone number.",
      },
      users: {
        title: "Number of Expected Users",
        placehoder: "Select number of users.",
        selection: {
          ten: "No more than 10",
          twenty: "11 ~ 20 people",
          thirty: "21 ~ 30 people",
          forty: "31 ~ 40 people",
          fifty: "41 ~ 50 people",
          over: "More than 50",
        },
      },

      email: {
        title: "Email",
        placeholder: "Write your email to reply.",
      },
      body: {
        title: "Contact Details",
        placeholder:
          "Please enter specific details including the information of the institution and the person in charge.",
      },
      demo: {
        title: "Request a demo.",
        yes: "Yes",
        no: "No",
      },
      ask_patent:
        "Request for estimation including provisional patent application service.",
      ask_security:
        "Request for estimation including access IP setting (additional service).",
    },
    landing_price: {
      payment_faq: "Payment and Billing FAQ",
      title: "Start using Research Note now!",
      title_highlight: "Research Note",
      sub_title: "Upgrade to use full features unlimited.",
      guide_text_1:
        "GOONO는 연구비 집행이 가능합니다. (최소 12개월부터 결제가 가능합니다.)",
      contact_title: "Want to know more about GOONO?",
      contact_email: "Contact by email",
      contact_call: "Contact by call",
      tab: {
        research_note: "Research Note",
        data_room: "DataRoom",
      },
      data_room: {
        title: "Start your virtual data-roomn solution Strong Box now!",
        title_highlight: "Strong Box",
      },
    },
  },
};
