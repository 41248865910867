export default {
  ko: {
    dialog: {
      start_trial: {
        title: "구노 체험판 시작 안내",
        confirm_button: "확인",
        date_text: " 23:59",
        top_text: "까지 체험판 진행이 시작 되었습니다.",
        bottom_text_default:
          "워크스페이스 내 멤버를 초대해 연구노트 작성이 가능합니다.",
        bottom_text_over_member:
          "우선20명으로 체험판이 시작되었으며, 매니저와 연락을 통해 간단한 상담 후 체험판 \n 인원수를 필요한 만큼 추가적으로 제공해드립니다.",
        etc_msgs: {
          wait: "잠시만 기다려주세요...",
          an_error_occurs: "신청 중 오류가 발생하였습니다.",
        },
      },
    },
  },
  en: {
    dialog: {
      start_trial: {
        title: "Trial start guide",
        confirm_button: "Ok",
        date_text: " 23:59, ",
        top_text: " the trial workspace has started.",
        bottom_text_default:
          "You can invite members and start writing research notes.",
        bottom_text_over_member:
          "the trial workspace has started with an initial capacity of 20 members. \n If you wish to add more members, please request it to us.",
        etc_msgs: {
          wait: "wait a minute...",
          an_error_occurs: "An error occurred during application.",
        },
      },
    },
  },
};
