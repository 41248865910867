import React from "react";
import { Box } from "@material-ui/core";
import useLandingHomeStyles from "../styles";
import FunctionSection, { FunctionSectionItem } from "./FuctionSection";
import translate from "@translate";
import SectionImg01 from "@assets/images/public/screen/landingHomeScreen/content-image/content-section-01.png";
import SectionImg02 from "@assets/images/public/screen/landingHomeScreen/content-image/content-section-02.png";
import SectionImg03 from "@assets/images/public/screen/landingHomeScreen/content-image/content-section-03.png";
import SectionImg04 from "@assets/images/public/screen/landingHomeScreen/content-image/content-section-04.png";

import { ReactComponent as SignIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-sign.svg";
import { ReactComponent as TimeIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-time.svg";
import { ReactComponent as SaveIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-save.svg";

import { ReactComponent as DriveIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-drive.svg";
import { ReactComponent as GithubIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-github.svg";

import { ReactComponent as PersonIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-person.svg";
import { ReactComponent as NoteIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-note.svg";
import { ReactComponent as FolderIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-folder.svg";

import { ReactComponent as CameraIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-camera.svg";
import { ReactComponent as ResposiveIcon } from "@assets/images/public/screen/landingHomeScreen/content-icon/icon-responsive.svg";

export default function MainFunctionSectionList() {
  const classes = useLandingHomeStyles();
  const [currentSection, setCurrentSection] = React.useState<number>(0);

  const FunctionSectionArray: FunctionSectionItem[] = [
    {
      type: "white",
      imgSrc: SectionImg01,
      imgPos: { bottom: 0, left: 0 },
      title: translate.landing_home.function.func_01.title,
      list: [
        {
          icon: <SignIcon />,
          text: translate.landing_home.function.func_01.list_01,
        },
        {
          icon: <TimeIcon />,
          text: translate.landing_home.function.func_01.list_02,
        },
        {
          icon: <SaveIcon />,
          text: translate.landing_home.function.func_01.list_03,
        },
      ],
    },
    {
      type: "gray",
      imgSrc: SectionImg02,
      imgPos: { bottom: 0, right: 36 },
      title: translate.landing_home.function.func_02.title,
      list: [
        {
          icon: <DriveIcon />,
          text: translate.landing_home.function.func_02.list_01,
        },
        {
          icon: <GithubIcon />,
          text: translate.landing_home.function.func_02.list_02,
        },
      ],
    },
    {
      type: "white",
      imgSrc: SectionImg03,
      imgPos: { bottom: 0, right: 0 },
      title: translate.landing_home.function.func_03.title,
      list: [
        {
          icon: <PersonIcon />,
          text: translate.landing_home.function.func_03.list_01,
        },
        {
          icon: <NoteIcon />,
          text: translate.landing_home.function.func_03.list_02,
        },
        {
          icon: <FolderIcon />,
          text: translate.landing_home.function.func_03.list_03,
        },
      ],
    },
    {
      type: "gray",
      imgSrc: SectionImg04,
      imgPos: { bottom: 0, right: 0 },
      title: translate.landing_home.function.func_04.title,
      list: [
        {
          icon: <CameraIcon />,
          text: translate.landing_home.function.func_04.list_01,
        },
        {
          icon: <ResposiveIcon />,
          text: translate.landing_home.function.func_04.list_02,
        },
      ],
    },
  ];

  const handleScroll = () => {
    const scrollBottom = window.scrollY + window.innerHeight * 0.65;

    const sectionOffsets = FunctionSectionArray.map((_section, index) => {
      const el = document.getElementById(`scrollPoint-${index + 1}`);
      return el?.offsetTop ?? 0;
    });

    /** 현재 스크롤 위치를 기준으로 어떤 섹션이 보이는지 확인 */
    for (let i = 0; i < sectionOffsets.length; i++)
      if (scrollBottom >= sectionOffsets[i]) setCurrentSection(i + 1);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box className={classes.function_section_list}>
      {FunctionSectionArray.map((item, index) => {
        const sectionNumber = index + 1;
        return (
          <FunctionSection
            key={item.title}
            {...item}
            index={index}
            id={`scrollPoint-${sectionNumber}`}
            active={
              currentSection === sectionNumber || currentSection > sectionNumber
            }
          />
        );
      })}
    </Box>
  );
}
