import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  ProjectInsideScreenActionKind,
  ProjectInsideScreenAction,
  projectInsideScreenStateMachine,
  ProjectInsideScreenStateMachineType,
  ProjectInsideScreenState,
} from "../store/reducers/projectInsideScreen";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.projectInsideScreen,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ProjectInsideScreenContainerProps = PropsFromRedux & {
  stateMachine: ProjectInsideScreenStateMachineType;
};

export const handleProjectInsideScreenAction = (
  prevState: ProjectInsideScreenState,
  action: ProjectInsideScreenAction
): ProjectInsideScreenState => {
  switch (action.kind) {
    case ProjectInsideScreenActionKind.TRY_UPDATE_STATE: {
      return {
        ...prevState,
        ...action.newState,
      };
    }
  }
};

class ProjectInsideScreenContainer extends ReduxStateComponent3<ProjectInsideScreenContainerProps> {
  static defaultProps = { stateMachine: projectInsideScreenStateMachine };
  constructor(props: ProjectInsideScreenContainerProps) {
    super(props);
  }

  protected onAction = handleProjectInsideScreenAction;
}

export default connector(ProjectInsideScreenContainer);
