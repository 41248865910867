import React from "react";

export type CurrentViewController = {
  currentView: GoonoService;
  setCurrentView: React.Dispatch<React.SetStateAction<GoonoService>>;
};
export type GoonoService = "research-note" | "data-room";

export const useCurrentViewController = () => {
  const [currentView, setCurrentView] =
    React.useState<GoonoService>("research-note");

  return {
    currentView,
    setCurrentView,
  };
};
