import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  githubStateMachine,
  GithubStateMachineType,
  GithubState,
  GithubAction,
  GithubActionKind,
  GithubStateStatus,
} from "../store/reducers/github";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.github,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type GithubContainerProps = PropsFromRedux & {
  stateMachine: GithubStateMachineType;
};

export const handleGithubAction = (
  prevState: GithubState,
  action: GithubAction
): GithubState => {
  switch (action.kind) {
    case GithubActionKind.TRY_SAVE_GITHUB_TOKEN: {
      const ghToken = action.ghToken;
      return {
        ...prevState,
        status: GithubStateStatus.SUCCESS,
        ghToken,
        linkFirst: true,
      };
    }
    case GithubActionKind.TRY_CHECKED_LINK_FIRST: {
      if (prevState.status === GithubStateStatus.SUCCESS) {
        return { ...prevState, linkFirst: false };
      }
      return { ...prevState };
    }
    default:
      return prevState;
  }
};

class GithubContainer extends ReduxStateComponent3<GithubContainerProps> {
  static defaultProps = {
    stateMachine: githubStateMachine,
  };

  constructor(props: GithubContainerProps) {
    super(props);
  }

  protected onAction = handleGithubAction;
}

export default connector(GithubContainer);
