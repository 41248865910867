import { FolderObject } from "@goono-commons/api/object/folder";
import { NoteObject } from "@goono-commons/api/object/note";
import { IGetNotes } from "@goono-commons/api/request/note";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  mkReducer,
  StateMachine3,
  StateMachineAction,
  transition,
} from "@redwit-react-commons/reducers/state3";
import { SortingFilterOptions } from "@utils/functions/sortingFilter";
import { Dispatch } from "react";
import { DateFilterOptionKind } from "src/components/public/filter/DateFilter";
import { ResearchFileListViewType } from "src/components/research-note/ResearchFileList";
import { ResearchFileListSortingOption } from "src/screens/research-note/ProjectInsideScreen/ResearchFileContents/FilterRow/FileSortingSelector";

export enum ProjectInsideScreenStateStatus {
  INIT = "ProjectInsideScreenStateStatus::INIT",
}

export enum ProjectInsideScreenActionKind {
  TRY_UPDATE_STATE = "ProjectInsideScreenActionKind::TRY_UPDATE_STATE",
}

export type ProjectInsideScreenError = never;

export type ProjectInsideScreenSelectMode =
  | "none"
  | "download"
  | "move"
  | "delete";

export type ProjectInsideScreenResearchFileListFilterOption = IGetNotes & {
  dateFilterOption?: DateFilterOptionKind;
};

export const initialResearchFileListFilterOption: ProjectInsideScreenResearchFileListFilterOption =
  {
    dateFilterOption: undefined,
    afterAt: undefined,
    beforeAt: undefined,
    authors: undefined,
    extensions: undefined,
    tags: undefined,
    ocrText: undefined,
    fileName: undefined,
  };

export type ProjectInsideScreenState = {
  readonly status: ProjectInsideScreenStateStatus.INIT;

  /** 현재 project & folder 정보 관련 */
  readonly researchNoteId: string;
  readonly researchFolderId: string;

  /** research file list select 관련 */
  readonly selectedResearchFiles: NoteObject[];
  readonly selectedResearchFolders: FolderObject[];
  readonly selectMode: ProjectInsideScreenSelectMode;
  readonly isAllSelected: boolean;
  readonly excludedResearchFileList: NoteObject[]; // 전체 선택 모드일 때 제외할 파일 목록

  /** research file list 관련 */
  readonly researchFileListType: ResearchFileListViewType;
  readonly researchFileListSortingOption: ResearchFileListSortingOption;
  readonly researchFileListFilterOption: ProjectInsideScreenResearchFileListFilterOption;

  /** research folder list 관련 */
  readonly researchFolderListSortingOption: SortingFilterOptions;
};

export type ProjectInsideScreenAction = {
  readonly kind: ProjectInsideScreenActionKind.TRY_UPDATE_STATE;
  readonly newState: Partial<ProjectInsideScreenState>;
};

const smid = "PROJECT_INSIDE_SCREEN_STATE_MACHINE3";

export type ProjectInsideScreenStateMachineType = StateMachine3<
  ProjectInsideScreenStateStatus,
  ProjectInsideScreenState,
  ProjectInsideScreenActionKind,
  ProjectInsideScreenAction,
  ProjectInsideScreenError
>;

export const projectInsideScreenStateMachine: ProjectInsideScreenStateMachineType =
  new StateMachine3<
    ProjectInsideScreenStateStatus,
    ProjectInsideScreenState,
    ProjectInsideScreenActionKind,
    ProjectInsideScreenAction,
    ProjectInsideScreenError
  >(
    smid,
    {
      status: ProjectInsideScreenStateStatus.INIT,

      researchNoteId: `init`,
      researchFolderId: `init`,

      selectedResearchFiles: [],
      selectedResearchFolders: [],
      selectMode: "none",
      isAllSelected: false,
      excludedResearchFileList: [],

      researchFileListType: "table",
      researchFileListSortingOption: "lastUploaded",
      researchFileListFilterOption: initialResearchFileListFilterOption,

      researchFolderListSortingOption: SortingFilterOptions.CREATED_AT_DESC,
    },
    [
      transition(
        ProjectInsideScreenStateStatus.INIT,
        ProjectInsideScreenStateStatus.INIT,
        ProjectInsideScreenActionKind.TRY_UPDATE_STATE
      ),
    ]
  );

export type DispatchProjectInsideScreenAction = Dispatch<
  StateMachineAction<
    ProjectInsideScreenStateStatus,
    ProjectInsideScreenState,
    ProjectInsideScreenActionKind,
    ProjectInsideScreenAction,
    ProjectInsideScreenError
  >
>;

export default mkReducer<
  ProjectInsideScreenStateStatus,
  ProjectInsideScreenState,
  ProjectInsideScreenActionKind,
  ProjectInsideScreenAction,
  ProjectInsideScreenError
>(projectInsideScreenStateMachine);

export const doProjectInsideScreenAction = (
  dispatch: DispatchProjectInsideScreenAction,
  nextAction: ProjectInsideScreenAction,
  onResolve: () => void = () => {},
  onReject: (err: ProjectInsideScreenError | InternalError) => void = () => {}
) => {
  dispatch(
    projectInsideScreenStateMachine.newTryAction(
      nextAction,
      onResolve,
      onReject
    )
  );
};

export const doProjectInsideScreenActionAsync = (
  dispatch: DispatchProjectInsideScreenAction,
  nextAction: ProjectInsideScreenAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(
      projectInsideScreenStateMachine.newTryAction(nextAction, resolve, reject)
    );
  });
};

export const resetProjectInsideScreen = (
  dispatch: DispatchProjectInsideScreenAction
) => {
  dispatch(projectInsideScreenStateMachine.newResetAction());
};
