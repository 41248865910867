import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import {
  DiaLogActionKind,
  DiaLogType,
  doDiaLogAction,
} from "src/redux/store/reducers/dialog";
import { WindowSizeHandler } from "@utils/templates/handler";
import GoonoBaseGrid from "@utils/templates/GoonoBaseGrid";
import { Box } from "@material-ui/core";
import MainIntroduceSection from "./ResearchNote/MainIntroduceSection";
import DataRoomIntroSection from "./DataRoom/DataRoomIntroSection";
import ServiceTabTitleSection from "./Commons/ServiceTabTitleSection";
import MainCertificationSection from "./Commons/MainCertificationSection";
import MainReviewSection from "./Commons/MainReviewSection";
import MainPartnerSection from "./Commons/MainPartnerSection";
import EventCreator from "src/services/GoogleTagManger/EventCreator";
import {
  useCurrentViewController,
  CurrentViewController,
} from "./hooks/useCurrentViewController";
import {
  useMainSlideController,
  MainSlideController,
} from "./hooks/useMainSlideController";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainFunctionSectionList from "./ResearchNote/MainFunctionSectionList";
import DataRoomFunctionSection from "./DataRoom/DataRoomFunctionSection";

export type GoonoService = "research-note" | "data-room";

type ContextType = {
  currentViewController: CurrentViewController;
  mainSlideController: MainSlideController;
};

export const LandingHomeScreenContext = React.createContext({} as ContextType);

export default function LandingHomeScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const slider = React.createRef<Slider>();
  const currentViewController = useCurrentViewController();
  const mainSlideController = useMainSlideController({ slideRef: slider });

  const { getWindowWidth } = WindowSizeHandler();
  const isMobile = getWindowWidth() < 1081;

  const goSignUpScreen = () => {
    EventCreator({
      event: "click_cta",
      button: isMobile ? "mobile_home_trial" : "home_trial",
    });

    history.push(ScreenURL.SIGN_UP);

    doDiaLogAction(dispatch, {
      kind: DiaLogActionKind.TRY_OPEN,
      type: DiaLogType.WARNING,
      title: "구노 무료체험을 위해 회원가입이 필요합니다.",
      bodyMsg: "회원가입을 하시면 간편하게 무료체험을 신청할 수 있습니다.",
      confirmBtnMsg: "확인",
    });
  };

  const renderVisualSlider = () => {
    return (
      <Box style={{ width: "100%", overflowX: "hidden", position: "relative" }}>
        <Slider ref={slider} {...mainSlideController.slideSettings}>
          <MainIntroduceSection
            isMobile={isMobile}
            goToFreeTrial={goSignUpScreen}
          />
          <DataRoomIntroSection
            isMobile={isMobile}
            goToFreeTrial={goSignUpScreen}
          />
        </Slider>
      </Box>
    );
  };

  return (
    <LandingHomeScreenContext.Provider
      value={{
        currentViewController,
        mainSlideController,
      }}
    >
      <GoonoBaseGrid viewerOption={{ type: "landing_page" }}>
        <Box style={{ position: "relative", width: "100%" }}>
          {renderVisualSlider()}
          <ServiceTabTitleSection />
          {currentViewController.currentView === "research-note" ? (
            <MainFunctionSectionList />
          ) : (
            <DataRoomFunctionSection />
          )}
          <MainCertificationSection />
          <MainReviewSection />
          <MainPartnerSection />
        </Box>
      </GoonoBaseGrid>
    </LandingHomeScreenContext.Provider>
  );
}
